
export const enum LinkPathOrientation {
	VERTICAL = 'vertical',
	HORIZONTAL = 'horizontal',
	UNKNOWN = 'unknown'
}

export const enum VerticalMovement {
	UP = 'up',
	DOWN = 'down',
	NONE = 'none',
}

export const enum HorizontalMovement {
	LEFT = 'left',
	RIGHT = 'right',
	NONE = 'none',
}
