
import { DiagramModel, DiagramEngine } from '../diagrams-engine';
import { DefaultState } from '../state/Default/DefaultState';
import { EventNodeModel as Event } from '../models/Event/EventNodeModel';
import { GRID_SIZE } from '../diagrams-core/models/DiagramModel'
import useLocalStorage from './useLocalStorage'
const LoadDiagram = (engine: DiagramEngine, setMenuOption: Function, setModalOpen: Function) => {
  setModalOpen(true)
  
  return engine
}

export default LoadDiagram
