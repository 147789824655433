import * as React from 'react';
import { TerminusEventNodeModel, TYPE } from './TerminusEventNodeModel';
import { TerminusEvent } from './TerminusEventNodeWidget';
import { AbstractReactFactory } from '../../canvas-core';
import { DiagramEngine } from '../../diagrams-core';


export class TerminusEventNodeFactory extends AbstractReactFactory<TerminusEventNodeModel, DiagramEngine> {
	constructor() {
		super(TYPE);
	}

	generateModel(initialConfig) {
		return new TerminusEventNodeModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <TerminusEvent engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
