import * as React from 'react';
import { DiagramEngine, LinkWidget, PointModel } from '../../diagrams-core';
import { RightAngleLinkFactory } from './RightAngleLinkFactory';
import { DefaultLinkSegmentWidget, DefaultLinkPointWidget } from '../../diagrams-defaults';
import { Point } from '../../geometry';
import { MouseEvent } from 'react';
import { RightAngleLinkModel, getOrientation } from './RightAngleLinkModel';
import Arrow from './Arrow';
import MidControl from './MidControl';
import MODEL_DEFAULTS from '../../models/BaseNode/constants'
import config from './config'

export interface RightAngleLinkProps {
	color?: string;
	width?: number;
	smooth?: boolean;
	link: RightAngleLinkModel;
	diagramEngine: DiagramEngine;
	factory: RightAngleLinkFactory;
}

export interface RightAngleLinkState {
	selected: boolean;
	canDrag: boolean;
}

export class RightAngleLinkWidget extends React.Component<RightAngleLinkProps, RightAngleLinkState> {
	public static defaultProps: RightAngleLinkProps = {
		color: 'red',
		width: config.LINE_WIDTH,
		link: null,
		smooth: false,
		diagramEngine: null,
		factory: null
	};

	refPaths: React.RefObject<SVGPathElement>[];

	// DOM references to the label and paths (if label is given), used to calculate dynamic positioning
	refLabels: { [id: string]: HTMLElement };
	dragging_index: number;
	dragging_line: number;

	constructor(props: RightAngleLinkProps) {
		super(props);

		this.refPaths = [];
		this.state = {
			selected: false,
			canDrag: false
		};

		this.dragging_index = 0;
		this.dragging_line = 0;
	}

	componentDidUpdate(): void {
		this.props.link.setRenderedPaths(
			this.refPaths.map(ref => {
				return ref.current;
			})
		);
	}

	componentDidMount(): void {
		this.props.link.setRenderedPaths(
			this.refPaths.map(ref => {
				return ref.current;
			})
		);
	}

	componentWillUnmount(): void {
		this.props.link.setRenderedPaths([]);
	}

	generatePoint(point: PointModel): JSX.Element {
		return (
			<DefaultLinkPointWidget
				key={point.getID()}
				point={point as any}
				colorSelected={MODEL_DEFAULTS.selectedColor}
				color={this.props.link.getOptions().color}
			/>
		);
	}

	generateLink(path: string, extraProps: any, id: string | number): JSX.Element {
		const ref = React.createRef<SVGPathElement>();
		this.refPaths.push(ref);
		// console.log('path', path, this.state.selected)
		return (
			<DefaultLinkSegmentWidget
				key={`link-${id}`}
				path={path}
				selected={this.state.selected}
				diagramEngine={this.props.diagramEngine}
				factory={this.props.diagramEngine.getFactoryForLink(this.props.link)}
				link={this.props.link}
				forwardRef={ref}
				onSelection={selected => {
					this.setState({ selected: selected });
				}}
				extras={extraProps}
			/>
		);
	}

	generateMidControl(firstPoint: PointModel, lastPoint: PointModel): JSX.Element {
		return (
			<MidControl
				key={`${firstPoint.getID()}-${lastPoint.getID()}`}
				firstPoint={firstPoint as any}
				lastPoint={lastPoint as any}
				color={this.props.link.getOptions().color}
				colorSelected={this.props.link.getOptions().selectedColor}
			/>
		)
	}
 

	generateArrow(point: PointModel, previousPoint: PointModel): JSX.Element {
		return (
			<Arrow
				key={point.getID()}
				point={point as any}
				previousPoint={previousPoint as any}
				colorSelected={this.props.link.getOptions().selectedColor}
				color={this.props.link.getOptions().color}
			/>
		);
	}

	generateArrows = function(paths: any[], points: PointModel[]) {
		if (this.props.link.getTargetPort() !== null) {
			paths.push(this.generateArrow(points[points.length - 1], points[points.length - 2]));
		} else {
			paths.push(this.generatePoint(points[points.length - 1]));
		}
	}

	createLinkWidget = function(point: number, points: PointModel[]):[string, any] {
		return [
			LinkWidget.generateLinePath(points[point], points[point + 1]),
			{
				'data-linkid': this.props.link.getID(),
				'data-point': point,
				'data-line': point,
				onMouseDown: (event: MouseEvent) => {
					if (event.button === 0) {
						this.setState({ canDrag: true });
						this.dragging_index = point;
						this.dragging_line = point;
					}
				},
				onMouseEnter: (event: MouseEvent) => {
					this.setState({ selected: true });
					this.props.link.lastHoverIndexOfPath = point;
				}
			},
		]
	}

	getLines = function(points: PointModel[]) {
		return points.reduce((accum, item, index, items) => {
			if (index < items.length - 1) {
				return accum.concat([[item, items[index + 1]]]);
			}
			return accum;
		}, [])
	}

	addMidControls = function(lines: any[], paths: any[]) {
		lines.forEach((line: [PointModel, PointModel]) => {
			paths.push(this.generateMidControl(line[0], line[1]))
		})
		return paths
	}

	render() {
		//ensure id is present for all points on the path
		let points = this.props.link.getPoints();
		let paths = [];

		this.generateArrows(paths, points)
		for (let j = 0; j < points.length - 1; j++) {
			const [path, extraProps] = this.createLinkWidget(j, points)
			paths.push(
				this.generateLink(path, extraProps, j)
			)
		}
		const lines = this.getLines(points)
		paths = this.addMidControls(lines, paths)

		return <g data-default-link-test={this.props.link.getOptions().testName}>{paths}</g>;
	}
}
