import * as React from 'react';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '../../../canvas-core';
import { DiagramEngine } from '../../../diagrams-core';
import { GridLayerWidget } from './GridLayerWidget';
import { GridLayerModel } from './GridLayerModel';

export class GridLayerFactory extends AbstractReactFactory<GridLayerModel, DiagramEngine> {
	constructor() {
		super('diagram-grid');
	}

	generateModel(event: GenerateModelEvent): GridLayerModel {
		return new GridLayerModel({size: 10});
	}

	generateReactWidget(event: GenerateWidgetEvent<GridLayerModel>): JSX.Element {
		return <GridLayerWidget layer={event.model} engine={this.engine} />;
	}
}
