
const baseUrl = `https://api.trailblazer.to/api/v2`;
const resourceUrl = `diagrams`;
const token = `?token=pedroisthebest`;
const reqUrl = (id:string = '') => `${baseUrl}/${resourceUrl}/${id}${token}`;

const emptyDiagram = {
  "id": "bb607219-2796-418e-b2f2-4a965aec8609",
  "offsetX": 0,
  "offsetY": 0,
  "name": "new Diagram",
  "zoom": 100,
  "gridSize": 10,
  "layers": [
      {
          "id": "e17878a5-f46e-4880-b091-7d59ea8ec0ef",
          "type": "diagram-grid",
          "isSvg": false,
          "transformed": true,
          "models": {}
      },
      {
          "id": "c72b8312-3f4e-43fd-8ba6-08a0147451b4",
          "type": "diagram-lanes",
          "isSvg": false,
          "transformed": true,
          "models": {}
      },
      {
          "id": "b877f276-dd50-42e1-abe7-48c51be0cef6",
          "type": "diagram-links",
          "isSvg": true,
          "transformed": true,
          "models": {}
      },
      {
          "id": "0a4e7d57-7b69-4767-9c0e-28eb244cb54d",
          "type": "diagram-nodes",
          "isSvg": false,
          "transformed": true,
          "models": {
              "916c21d6-61ee-48d3-8779-11d635454c04": {
                  "id": "916c21d6-61ee-48d3-8779-11d635454c04",
                  "type": "start-event-node",
                  "x": 270,
                  "y": 370,
                  "editing": false,
                  "ports": [
                      {
                          "id": "3d390f34-aa30-4620-88ad-7153f4991704",
                          "type": "port",
                          "x": 290,
                          "y": 360,
                          "name": "top",
                          "alignment": "top",
                          "parentNode": "916c21d6-61ee-48d3-8779-11d635454c04",
                          "links": [],
                          "default": true,
                          "portRadius": 10,
                          "shapeOffsetX": 0,
                          "shapeOffsetY": 0
                      },
                      {
                          "id": "8af086a0-30ca-4fb5-aef3-4836d12f9660",
                          "type": "port",
                          "x": 290,
                          "y": 419.5,
                          "name": "bottom",
                          "alignment": "bottom",
                          "parentNode": "916c21d6-61ee-48d3-8779-11d635454c04",
                          "links": [],
                          "default": true,
                          "portRadius": 10,
                          "shapeOffsetX": 0,
                          "shapeOffsetY": 0
                      },
                      {
                          "id": "64d2f436-9fe5-42db-aada-5f251adf0d88",
                          "type": "port",
                          "x": 259.5,
                          "y": 390,
                          "name": "left",
                          "alignment": "left",
                          "parentNode": "916c21d6-61ee-48d3-8779-11d635454c04",
                          "links": [],
                          "default": true,
                          "portRadius": 10,
                          "shapeOffsetX": 0,
                          "shapeOffsetY": 0
                      },
                      {
                          "id": "19efba1e-e881-4cf2-96ac-9d47e07b18ba",
                          "type": "port",
                          "x": 320,
                          "y": 390,
                          "name": "right",
                          "alignment": "right",
                          "parentNode": "916c21d6-61ee-48d3-8779-11d635454c04",
                          "links": [],
                          "default": true,
                          "portRadius": 10,
                          "shapeOffsetX": 0,
                          "shapeOffsetY": 0
                      }
                  ],
                  "color": "red",
                  "label": "Start"
              }
          }
      },
      {
          "id": "425fe7cb-b133-4304-9637-910c14656611",
          "type": "app",
          "isSvg": false,
          "transformed": false,
          "models": {}
      }
  ]
}

function backendFormat(name: string, data: any) {
  return {
    name,
    diagram: JSON.stringify(data),
  }
}


async function load(id: string) {
  return fetch(reqUrl(id), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },

  })
    .then(response => {
      return response.json()
    }).then((data) => {
      try {
        const diagram = JSON.parse(data.diagram);
        return {
          ...data,
          diagram,
        }
      } catch (e) {
        console.log('error', e)
        return emptyDiagram
      }
    })
    .catch(err => console.log(err))
}

function create() {
  return fetch(reqUrl(), { 
    mode: 'no-cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then(response => 
      response.json()
    ).then((data) => {
      return data
    })
    .catch(err => console.log(err))

}

function prepareBody(data: any):any {
  return {
    name: data.name,
    diagram: JSON.stringify(data),
  }
}

async function save(id: string, name: string, data: any) {
  const body = JSON.stringify(prepareBody({name, ...data}));
  return fetch(reqUrl(id), { 
    // mode: 'no-cors',
    // credentials: 'include',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': '',

      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body,
  }).then(response => {
      return response.text()
    }).then((data) => {
      if (data === '') {
        console.log('empty')
        return emptyDiagram
      }
      return emptyDiagram
    })
    .catch(err => console.log(err))
}
function list() {
  return []
}

export default {
  load,
  save,
  list,
  create,
}