import * as React from 'react';
import styled from '@emotion/styled';
import { BaseNodeModel } from '../../../models/BaseNode/BaseNodeModel';
import okIcon from '../../../images/icons/ok.svg'
import cancelIcon from '../../../images/icons/cancel.svg'
import { CanvasEngine } from '../../CanvasEngine';
import { defaultActions } from '../../../components/Tools'

const BOX_HEIGHT = 300
const BOX_WIDTH = 400

export interface EditingWidgetProps {
	model?: BaseNodeModel
  engine: CanvasEngine
}

export interface EditWidgetState {
  value: string
}


namespace S {
  export const Layer = styled.div`
    pointer-events: auto;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 20000;
    cursor: default;
  `;
	export const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: default;
  `;

  export const Container = styled.div`
		position: absolute;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
		background-color: #f08080e2;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
    cursor: default;
    padding: 4px;
    border: 1px;
    `;
  export const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  `;
  export const Row = styled.div<{justify?: string}>`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: ${props => props.justify ? props.justify : 'center'};
  `;
  export const Title = styled.h1`
    display: flex;
    font-family: 'Exo 2';
    font-weight: light;
    font-size: 1.6rem;
    color: #fafafa;
    align-items: center;
  `;

  //     background-color: rgba(251, 171, 56, 0.644);

  export const Description = styled.div`
    display: flex;
    padding: 1rem;
    color: #fafafa;
    background-color: rgba(185, 41, 41, 0.356);
    align-items: center;
    border-radius: 5px;
    margin: 5px;
    width: 100%;
    `;
  export const DescriptionText = styled.p`
    display: flex;
    font-family: 'Exo 2';
    font-weight: light;
    font-size: 1rem;
    color: #fafafa;
  `;


  export const Info = styled.div`
    display: flex;
  `;
  export const Input = styled.input`
    font-family: 'Exo 2';
    margin: 10px;
    padding: 4px;
    display: flex;
    font-size: 1.5rem;
    border-radius: 5px;
    border: 0;
    width: 100%;
    &:focus {
      outline: none;
    }
  `;
  export const Buttons = styled.div`
    width: 96px;
    height: 48px;
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  `;

  export const Button = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
  `;
  export const Ok = styled.img`
    width: 32px;
    border: 0;
    &:hover {
      background-color: rgba(185, 41, 41, 0.356);
      border-radius: 4px;
    }
  `
  export const Cancel = styled.img`
    width: 32px;
    border: 0;
    &:hover {
      background-color: #fafafa;
      border-radius: 4px;
    }
  `
  export const Icon = styled.i`
    padding-top: 10px;
    margin-right: 10px;
    color: #fafafa;
    font-family: "bpmn";
    font-style: normal;
    font-weight: normal;
    font-size: 4rem;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 4rem;
    margin-right: 10px;
    margin-bottom: 4px;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 4rem;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `
}

const findNodeForAction = (actions: any, type:string) => {
  const key = Object.keys(actions).find((entry: any) => 
    (actions[entry].id === type)
  )
  return actions[key];
}

export class EditingWidget extends React.Component<EditingWidgetProps, EditWidgetState> {
  input: any
  constructor(props: EditingWidgetProps) {
    super(props);
    this.state = {
			value: props.model.label,
		};
    this.input = React.createRef();
  }
  handleChange(ev:any) {
    const newVal = ev.target.value
    this.setState({...this.state, value: newVal })
    // this.props.model.label = newVal
    // this.props.model.fireEvent({}, 'stateChanged');

    // this.props.model.getBoundingBox()
  }

  componentDidMount() {
    this.input.current.focus();
  }

	render() {
		const { engine } = this.props;
    const type = this.props.model.getOptions().type; 
    const action = findNodeForAction(defaultActions, type)
		return (
      <S.Layer>
        <S.Container
          style={{
            top: (engine.getCanvas().clientHeight / 2) - (BOX_HEIGHT / 2),
            left: (engine.getCanvas().clientWidth / 2) - (BOX_WIDTH / 2),
            width: BOX_WIDTH,
            height: BOX_HEIGHT
          }}
        >
          <S.Column>
            <S.Row justify="start">
              <S.Info>
                <S.Icon data-menuid={action.id} dangerouslySetInnerHTML={{ __html: action.code }} color="#fafafa" />

              </S.Info>
              <S.Title>
                {action.name}
              </S.Title>
            </S.Row>
            <S.Row>
              <S.Description>
                <S.DescriptionText>
                {action.description}

                </S.DescriptionText>
              </S.Description>

            </S.Row>
          <S.FieldContainer>
            <S.Input name={this.props.model.getID()} ref={this.input} type="text" value={this.state.value} onChange={(ev) => this.handleChange(ev)} />

          </S.FieldContainer>
          <S.Row justify="end">
            <S.Buttons >
              <S.Button>
                <S.Ok src={okIcon} width="32" height="32" data-button="ok" data-id={this.props.model.getID()} />
              </S.Button>
              <S.Button>
                <S.Cancel src={cancelIcon} data-button="cancel" data-id={this.props.model.getID()} />
              </S.Button>

            </S.Buttons>

          </S.Row>

          </S.Column>

        </S.Container>

      </S.Layer>
		);
	}
}
