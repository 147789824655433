import { BaseModelOptions } from '../../canvas-core';
import { PortModelAlignment } from '../../diagrams-core';
import { BaseNodeModel } from '../BaseNode/BaseNodeModel'
import { BaseNodePortModel } from '../BaseNode/BaseNodePortModel';

export const TYPE = 'terminus-event-node'

export interface TerminusEventNodeModelGenerics {
}
export interface TerminusEventNodeModelOptions extends BaseModelOptions {
	color?: string;
	label?: string;
	width?: number;
	height?: number;
}

export class TerminusEventNodeModel extends BaseNodeModel {
	color: string;
	label: string;

	constructor(options: TerminusEventNodeModelOptions) {
		super({
			type: TYPE,
			width: 40,
			height: 40,
			...options
		});
		this.label = options.label;
	}
	linkSuplement(port: BaseNodePortModel, dragCoordinate: 'x'|'y', currentDelta:number):number {
		const opposite = (dragCoordinate === 'x') ? 'y' : 'x'
		const alignment = port.getOptions().alignment
		const radius = this.width / 2
		const suplement = radius - Math.ceil(Math.sqrt(Math.abs((radius * radius) - (Math.abs(currentDelta) * Math.abs(currentDelta)))))
		const sign = (alignment === PortModelAlignment.RIGHT || alignment === PortModelAlignment.BOTTOM) ? -1 : 1
		// console.log('->', this.label, dragCoordinate, currentDelta, suplement)
		port.setOffset(opposite, suplement * sign)			
		return suplement * sign 
	}

}
