import * as React from 'react';
import styled from '@emotion/styled';
import { WHITE_CONTRAST } from './Control';
import { DiagramEngine, DiagramModel } from '../diagrams-core';

const DEFAULT_WIDTH = 200;

namespace S {
	export const List = styled.div<{}>`
    display: flex;
    flex-direction: column;
  `;
	export const Scrollable = styled.div<{}>`
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: scroll;
  `;
	export const HeadRow = styled.div<{}>`
    display: flex;
    flex-direction: row;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

  `;
	export const Row = styled.div<{}>`
    display: flex;
    flex-direction: row;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

  `;
  export const Head = styled.div<{width?: number, align?: string}>`
    display: flex;
    flex-direction: column;
    font-weight: bold;
    width: ${p => p.width || DEFAULT_WIDTH}px;
    text-align: ${p => p.align || ''};
  `
  export const Cell = styled.div<{width?: number, pointer?: string}>`
    display: flex;
    flex-direction: column;
    width: ${p => p.width || DEFAULT_WIDTH}px;
    cursor: ${p => p.pointer || 'default'}
  `
  export const Title = styled.h1<{}>`
    font-size: 2rem;
    margin-bottom: 1rem;
  `
  export const Delete = styled.a<{}>`
    text-decoration: none;
    text-align: center;
  
  `
}

const DiagramsList = props => {
  const { fs, engine, setSelectedMenu, setModalOpen } = props
  const [ list, setList] = React.useState(fs.dirToArray(fs.dir()))
  const deleteDiagram = (id: string) => {
    fs.del(id)
    setList(fs.dirToArray(fs.dir()))
  }
  const loadDiagram = (id: string) => {
    const data = fs.read(id)
    const model2 = new DiagramModel();
    // @ts-ignore
	  model2.deserializeModel(data, engine);
  	engine.setModel(model2);
    setSelectedMenu(null)
    setModalOpen(false)

  }
  return (
    <>
      <S.Title>Load diagram...</S.Title>
      <S.List>
        <S.HeadRow>
          <S.Head>name</S.Head><S.Head width={100} align="center">action</S.Head>
        </S.HeadRow>
        <S.Scrollable>
        {list.length > 0 && list.map((diagram, index) => (
          <S.Row key={diagram.name}>
            <S.Cell pointer="pointer" onClick={() => loadDiagram(diagram.name)}>{diagram.name}</S.Cell>
            <S.Cell width={100}><S.Delete onClick={() => deleteDiagram(diagram.name)} href="#">[ X ]</S.Delete></S.Cell>
          </S.Row>
        ))}
        </S.Scrollable>
        {list.length === 0 && <div>no diagrams found</div>}
      </S.List>
    </>
  )
}

export default DiagramsList