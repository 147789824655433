import { DefaultLinkModel, DefaultLinkModelOptions } from '../../diagrams-defaults';
import { RightAngleLinkFactory } from './RightAngleLinkFactory';
import { PointModel, PortModelAlignment, PortModel, LinkModel, NodeModel, DiagramModel } from '../../diagrams-core';
import { BasePositionModel, DeserializeEvent } from '../../canvas-core';
import { LinkPathOrientation } from '../../diagrams-core/entities/link/RightAngleLinkModel';
import { BaseNodePortModel } from '../../models/BaseNode/BaseNodePortModel';
import { BaseNodeModel } from '../../models/BaseNode/BaseNodeModel';
import { Point } from '../../geometry';
import config from './config';
import MODEL_DEFAULTS from '../../models/BaseNode/constants'


export interface RightAngleLinkModelOptions extends DefaultLinkModelOptions {
	alignment?: PortModelAlignment,
	alternateColor?: string,
	containerId?: string,
	initialOrientation?: LinkPathOrientation,
}

export type Collisions = {
	source?: {
		model: BaseNodeModel,
		position: 0 | 1, 
	},
	target?: {
		model: BaseNodeModel,
		position: 0 | 1, 
	},
}

export const getOrientation = (alignment: PortModelAlignment):LinkPathOrientation => {
	switch (alignment) {
		case 'top':
			return LinkPathOrientation.VERTICAL;
		case 'bottom':
			return LinkPathOrientation.VERTICAL;
		case 'left':
			return LinkPathOrientation.HORIZONTAL;
		case 'right':
			return LinkPathOrientation.HORIZONTAL;
		default:
			return LinkPathOrientation.UNKNOWN;
	}
}

export const getPathOrientation = (startPoint: PointModel, endPoint: PointModel) => {
	if (startPoint.getPosition().x === endPoint.getPosition().x) {
		return LinkPathOrientation.VERTICAL;
	} else if (startPoint.getPosition().y === endPoint.getPosition().y) {
		return LinkPathOrientation.HORIZONTAL;
	} else {
		return LinkPathOrientation.UNKNOWN;
	}
}

export class RightAngleLinkModel extends DefaultLinkModel {
	
	lastHoverIndexOfPath: number;
	lineWidth: number;
	protected initialOrientation: LinkPathOrientation | null;
	protected options: RightAngleLinkModelOptions;
	private _lastPathXdirection: boolean;
	private _firstPathXdirection: boolean;
	private _lastPathYdirection: boolean;
	private _firstPathYdirection: boolean;
	constructor(options: RightAngleLinkModelOptions) {
		super({
			type: RightAngleLinkFactory.NAME,
			alternateColor: MODEL_DEFAULTS.alternateColor,
			...options
		});
		this.lineWidth = config.LINE_WIDTH;
		this.initialOrientation = getOrientation(options.alignment);
		this.lastHoverIndexOfPath = 0;
		this._lastPathXdirection = false;
		this._firstPathXdirection = false;
		this._lastPathYdirection = false;
		this._firstPathYdirection = false;
	}

	setFirstAndLastPathsDirection() {
		let points = this.getPoints();
		for (let i = 1; i < points.length; i += points.length - 2) {
			let dx = Math.abs(points[i].getX() - points[i - 1].getX());
			let dy = Math.abs(points[i].getY() - points[i - 1].getY());
			if (i - 1 === 0) {
				this._firstPathXdirection = dx > dy;
			} else {
				this._lastPathXdirection = dx > dy;
			}
		}
	}

	setTargetPort(model: BaseNodePortModel, isLinking = false) {
		if (model === null) {
			super.setTargetPort(model)
			return
		}
		let points = this.getPoints();
		const savedPoints = points.map((e:any) => e)
		const size = points.length;
		const startPoint = points[size - 2];
		const endPoint = points[size - 1];
		const lastPathOrientation = getPathOrientation(startPoint, endPoint);
		if (lastPathOrientation === LinkPathOrientation.VERTICAL) {
			if (isLinking) {
				points[size - 2].setPosition(model.getPosition().x + (model.getPortRadius()), points[size - 2].getY())
			} else {
				points[size - 2].setPosition(points[size - 2].getX(), points[size - 2].getY())
			}
		} else if (lastPathOrientation === LinkPathOrientation.HORIZONTAL) {
			if (isLinking) {
				points[size - 2].setPosition(points[size - 2].getX(), model.getPosition().y + (model.getPortRadius()))
			} else {
				points[size - 2].setPosition(points[size - 2].getX(), points[size - 2].getY())
			}
		} else {
		}
		super.setTargetPort(model);
	}

	// @ts-ignore
	addPoint<P extends PointModel>(pointModel: P, index: number = 1): P {
		// @ts-ignore
		super.addPoint(pointModel, index);
		this.setFirstAndLastPathsDirection();
		return pointModel;
	}

	setManuallyFirstAndLastPathsDirection(first: boolean, last: boolean) {
		this._firstPathXdirection = first;
		this._lastPathXdirection = last;
	}

	getLastPathXdirection(): boolean {
		return this._lastPathXdirection;
	}
	getFirstPathXdirection(): boolean {
		return this._firstPathXdirection;
	}

	setWidth(width: number) {
		this.options.width = width;
		this.fireEvent({ width }, 'widthChanged');
	}

	setColor(color: string) {
		this.options.color = color;
		this.fireEvent({ color }, 'colorChanged');
	}

	remove() {
		if (this.sourcePort) {
			this.sourcePort.removeLink(this);
			if (!(this.sourcePort as BaseNodePortModel).defaultLocation) {
				const parent = this.sourcePort.getParent()
				parent.removePort(this.sourcePort)
			}
		}
		if (this.targetPort) {
			this.targetPort.removeLink(this);
			if (!(this.targetPort as BaseNodePortModel).defaultLocation) {
				const parent = this.targetPort.getParent()
				parent.removePort(this.targetPort)
			}
		}
		super.remove();
	}
	lineColission(model: BaseNodeModel, line: [PointModel, PointModel]):number {
		if (model.containsPoint(line[0].getPosition())) return 0
		if (model.containsPoint(line[1].getPosition())) return 1
	}

	oppositeAxis(axis:string):string {
		if (axis === 'x') return 'y'
		return 'x'
	}

	oppositeDimension(dimension:string):string {
		if (dimension === 'width') return 'height'
		return 'width'
	}

	detectLineOverride(movementAxis: string, model: BaseNodeModel, index: number, line: [PointModel, PointModel]):PortModelAlignment {
		const opposite = this.oppositeAxis(movementAxis)
		const dimension = (opposite === 'y') ? 'height' : 'width'
		const pointIndex = this.lineColission(model, line)
		const alignments = {
			y: [PortModelAlignment.TOP, PortModelAlignment.BOTTOM],
			x: [PortModelAlignment.LEFT, PortModelAlignment.RIGHT],
		}
		let override: PortModelAlignment
		if (pointIndex === 0 && line[1].getPosition()[opposite] < model.getPosition()[opposite]) {
			override = alignments[opposite][0]
		} else if (pointIndex === 0 && line[1].getPosition()[opposite] > model.getPosition()[opposite] + model[dimension]) {
			override = alignments[opposite][1]
		} else if (pointIndex === 1 && line[0].getPosition()[opposite] < model.getPosition()[opposite]) {
			override = alignments[opposite][0]
		} else if (pointIndex === 1 && line[0].getPosition()[opposite] > model.getPosition()[opposite] + model[dimension]) {
			override = alignments[opposite][1]
		} else {
			override = undefined
		}
		return override
	}

	lineDirectionByPort(alignment: PortModelAlignment):LinkPathOrientation {
		switch (alignment) {
			case PortModelAlignment.BOTTOM:
				return LinkPathOrientation.VERTICAL
			case PortModelAlignment.TOP:
				return LinkPathOrientation.VERTICAL
			case PortModelAlignment.LEFT:
				return LinkPathOrientation.HORIZONTAL
			case PortModelAlignment.RIGHT:
				return LinkPathOrientation.HORIZONTAL
			default:
				return LinkPathOrientation.UNKNOWN
		}
	}

	getLineDirection(index: number) {
		const firstLine = this.lineDirectionByPort(this.sourcePort.getOptions().alignment)
		const odd = (index % 2)
		if (firstLine === LinkPathOrientation.HORIZONTAL) {
			if (!odd) {
				return LinkPathOrientation.HORIZONTAL
			} else {
				return LinkPathOrientation.VERTICAL
			}
		} else {
			if (!odd) {
				return LinkPathOrientation.VERTICAL
			} else {
				return LinkPathOrientation.HORIZONTAL
			}

		}
	}
	
	lineCardinalPositionToNode(model: BaseNodeModel, orientation: LinkPathOrientation, point: {x:number, y:number}) :PortModelAlignment|null {
		const axis = (orientation === LinkPathOrientation.HORIZONTAL) ? 'y' : 'x'
		const dimmension = (orientation === LinkPathOrientation.HORIZONTAL) ? 'height' : 'width'
		const position = (orientation === LinkPathOrientation.HORIZONTAL) 
			? { greater: PortModelAlignment.BOTTOM, smaller: PortModelAlignment.TOP }
			: { greater: PortModelAlignment.RIGHT, smaller: PortModelAlignment.LEFT }
		if (point[axis] < model.getPosition()[axis]) {
			return position.smaller
		}
		if (point[axis] > model.getPosition()[axis] + model[dimmension]) {
			return position.greater
		}
		return null
	}
	
	detectLineColissions(index: number, source: BaseNodeModel, target: BaseNodeModel): Collisions {
		const [lineStart, lineEnd] = this.getLine(index)
		let collisions = {
			source: null,
			target: null,
		}
		if (this.lineColission(source, [lineStart, lineEnd]) === 0) {
			collisions.source = {
				model: source,
				position: 0,
			}
		}
		if (this.lineColission(source, [lineStart, lineEnd]) === 1) {
			collisions.source = {
				model: source,
				position: 1,
			}
		}
		if (this.lineColission(target, [lineStart, lineEnd]) === 0) {
			collisions.target = {
				model: target,
				position: 0,
			}
		}
		if (this.lineColission(target, [lineStart, lineEnd]) === 1) {
			collisions.target = {
				model: target,
				position: 1,
			}
		}
		return collisions
	}

	getPointIndex = (point: PointModel) => {
		const id = point.getID()
		let position = 0
		this.getPoints().forEach((elem: PointModel, index) => {
			if (elem.getID() === id) {
				position = index
			}
		})
		return position
	}

	isContained = () => {
		
		if (!this.getSourcePort()) return false
		if (!this.getTargetPort()) return false
		if (!this.getSourcePort().getParent()) return false
		if (!this.getTargetPort().getParent()) return false
		const sourceContainedBy = this.getSourcePort().getParent().containerId
		const targetContainedBy = this.getTargetPort().getParent().containerId
		if (!sourceContainedBy || !targetContainedBy) return false
		return (sourceContainedBy === targetContainedBy)
	}

	serialize() {
		return {
			...super.serialize(),
			initialOrientation: this.options.initialOrientation,
			alignment: this.options.alignment,
			lineWidth: this.lineWidth,
			_lastPathXdirection: this._lastPathXdirection,
			_lastPathYdirection: this._lastPathYdirection,
			_firstPathXdirection: this._firstPathXdirection,
			_firstPathYdirection: this._firstPathYdirection,
			alternateColor: this.options.alternateColor,
		};
	}

	deserialize(event: DeserializeEvent<this>) {
		super.deserialize(event);
		this.setFirstAndLastPathsDirection();
		this.options.initialOrientation = getOrientation(event.data.alignment);
		this.lineWidth = event.data.lineWidth;
		this._firstPathYdirection = event.data._firstPathYdirection;
		this._firstPathYdirection = event.data._firstPathYdirection;
		this._lastPathXdirection = event.data._lastPathXdirection;
		this._lastPathYdirection = event.data._lastPathYdirection;
		this.options.alternateColor = event.data.alternateColor;
	}

	public static getMiddlePoint(axis:string) { 
		return (startPoint: Point, endPoint: Point) => {
			const startPosition = startPoint[axis];
			const delta = endPoint[axis] - startPosition;
			return (startPosition + (delta / 2));
		}	
	}
}
