import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import App from './App'

import './main.css';

function RoutedApp() {
	return (
		<BrowserRouter>
		<Routes>
			<Route path="/" element={<App />} />
			<Route index path="diagram/:id" element={<App />} />
			<Route path="sample" element={<App />} />

		</Routes>
		</BrowserRouter>
	)
}

document.addEventListener('DOMContentLoaded', () => {
	ReactDOM.render(
		<RoutedApp />
	, 
	document.querySelector('#application'));
});
