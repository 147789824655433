import { BaseModel, LayerModel, LayerModelGenerics } from '../../../canvas-core';
import { LaneNodeModel } from '../lane/LaneNodeModel';
import { DiagramEngine } from '../../DiagramEngine';
import { DiagramModel } from '../../models/DiagramModel';
import { Point } from '../../../geometry';


export interface LaneLayerModelGenerics extends LayerModelGenerics {
  ENGINE: DiagramEngine;
	CHILDREN: LaneNodeModel;
}

export class LanesLayerModel<G extends LaneLayerModelGenerics = LaneLayerModelGenerics> extends LayerModel<G> {
  constructor() {
		super({
			type: 'diagram-lanes',
			isSvg: false,
			transformed: true
    });
	}
	addModel(model: G['CHILDREN']): void {
		// if (!(model instanceof LaneNodeModel)) {
		// 	throw new Error('Can only add lane nodes to this layer');
		// }
		model.registerListener({
			entityRemoved: () => {
				(this.getParent() as DiagramModel).removeLane(model);
			}
		});
		super.addModel(model);
	}

	getChildModelFactoryBank(engine: G['ENGINE']) {
		return engine.getLaneFactories();
	}
	getNodes() {
		return this.getModels();
	}
	getModels() {
		return this.models;
	}
	removeModel(id: string | G['CHILDREN']): boolean {
		const _id = typeof id === 'string' ? id : id.getID();
		const model = this.getModel(_id)
		const diagram = (this.getParent() as DiagramModel)
		model.containedIds.forEach((contained) => {
			diagram.getNode(contained).setContainer(null)
		})
		return super.removeModel(_id);
	}
	pointContained(points: Point[]): boolean {
		const models = this.getModels()
		let contained = false
		// points.forEach((point: Point) => {
		// 	Object.entries(models).forEach(([id, model]) => {
		// 		if (!contained) {
		// 			contained = (model as LaneNodeModel).getBoundingBox().containsPoint(point) 
		// 		}
		// 	})
		// })
		return contained
	}
}
