import { MouseEvent } from 'react';
import {
	SelectingState,
	EditLabelState,
	State,
	Action,
	InputType,
	ActionEvent,
	DragCanvasState
} from '../../canvas-core';
import { PortModel, DiagramEngine } from '../../diagrams-core';
import { CreateLinkState } from '../Link/CreateLinkState';
import { CreateLinkWithEntityState } from '../Link/CreateLinkWithEntityState';
import { DragLinkState } from '../Link/DragLinkState';
import { DragDiagramItemsState } from '../Items/DragDiagramItemsState';
import { RightAngleLinkModel } from '../../routing/link/RightAngleLinkModel';

export class DefaultState extends State<DiagramEngine> {
	dragCanvas: DragCanvasState;
	createLink: CreateLinkState;
	createLinkWithEntity: CreateLinkWithEntityState;
	dragLink: DragLinkState;
	dragItems: DragDiagramItemsState;
	// interactionState: InteractionState;

	constructor() {
		super({ name: 'starting-state' });
		this.childStates = [new SelectingState(), new EditLabelState()];
		this.dragCanvas = new DragCanvasState();
		this.createLink = new CreateLinkState();
		this.createLinkWithEntity = new CreateLinkWithEntityState();
		this.dragItems = new DragDiagramItemsState();
		this.dragLink = new DragLinkState();

		// on move detect port
		this.registerAction(
			new Action({
				type: InputType.MOUSE_MOVE,
				fire: (event: ActionEvent<MouseEvent>) => {
					// const element = this.engine.getActionEventBus().getModelForEvent(event);
					if (this.createLink.isLinking) {
						this.transitionWithEvent(this.createLink, event);
						// this.transitionWithEvent(this.dragItems, event);
					} else if (this.createLinkWithEntity.isLinking) {
						this.transitionWithEvent(this.createLinkWithEntity, event);
					}
				}
			})
		)

		// determine what was clicked on
		this.registerAction(
			new Action({
				type: InputType.MOUSE_DOWN,
				fire: (event: ActionEvent<MouseEvent>) => {
					const element = this.engine.getActionEventBus().getModelForEvent(event);
					const actionIcon = this.createLinkWithEntity.clickedIcon(event)
					// the canvas was clicked on, transition to the dragging canvas state
					if (actionIcon || this.createLinkWithEntity.isLinking) {
						this.transitionWithEvent(this.createLinkWithEntity, event);
					}
					else if (!element) {
						this.transitionWithEvent(this.dragCanvas, event);
					}
					// initiate dragging a new link
					else if (element instanceof PortModel) {
						// console.log('createLink')
						this.transitionWithEvent(this.createLink, event);
					}
					// move the items (and potentially link points)
					else if (element instanceof RightAngleLinkModel) {
						// console.log('dragLink')

						this.transitionWithEvent(this.dragLink, event)
						// this.transitionWithEvent(this.dragItems, event);
					} else {
						// console.log('dragItems')
						this.transitionWithEvent(this.dragItems, event);
					}
					return
				}
			})
		);

		this.registerAction(
			new Action({
				type: InputType.MOUSE_UP,
				fire: (event: ActionEvent<MouseEvent>) => {
					const element = this.engine.getActionEventBus().getModelForEvent(event);
					if (element instanceof PortModel) {
						this.transitionWithEvent(this.createLink, event)
					};
					return
				}
			})
		);
	}
}
