import { Action, ActionEvent, InputType, State, CanvasEngine, BaseModel, AbstractDisplacementState, AbstractDisplacementStateEvent, BasePositionModel } from '../../canvas-core';
import { PortModel, LinkModel, DiagramEngine, NodeModel, PortModelAlignment, NodeModelGenerics, PointModel  } from '../../diagrams-core';
import { MouseEvent, KeyboardEvent } from 'react';
import { BaseNodeModel, BaseNodeModelGenerics } from '../../models/BaseNode/BaseNodeModel';
import { Point, TPoint } from '../../geometry';
import { BaseNodePortModel } from '../../models/BaseNode/BaseNodePortModel';
import { LinkPathOrientation } from '../../diagrams-core/entities/link/RightAngleLinkModel';
import { getOrientation, RightAngleLinkModel } from '../../routing/link/RightAngleLinkModel';
import config from '../../routing/link/config'
const MIN_LINE_SIZE = config.MIN_LINE_SIZE;

const calcPoints = (fromMid: Point, toMid: Point, from: BaseNodeModel, to: BaseNodeModel | Point) => {
	if (Math.abs(fromMid.x - toMid.x) > Math.abs(fromMid.y - toMid.y)) {
		// If horizontal distance is greater than vertical distance
		return calcDefaultPointsAccordingToMainAxis('x', from, to, fromMid, toMid);
	} else {
		return calcDefaultPointsAccordingToMainAxis('y', from, to, fromMid, toMid);
	}	
}

const calcDefaultPointsAccordingToMainAxis = (
  mainAxis: 'x' | 'y',
  from: BaseNodeModel,
  to: BaseNodeModel | Point,
  fromMid: Point,
  toMid: Point
): Array<Point> => {
  const crossAxis = mainAxis === 'x' ? 'y' : 'x';
  const mainDimension = mainAxis === 'x' ? 'width' : 'height';
  const crossDimension = mainAxis === 'x' ? 'height' : 'width';

  const fromEdge = new Point({
    ...fromMid,
    [mainAxis]: (fromMid[mainAxis] <= toMid[mainAxis]) ? fromMid[mainAxis] + (from[mainDimension] / 2) : fromMid[mainAxis] - (from[mainDimension] / 2)
  })
	let toPos: TPoint

	if (to instanceof Point) {
		toPos = {
			x: to.x,
			y: to.y,
		} 
	} else {
		toPos = {
			x: to.getPosition().x,
			y: to.getPosition().y
		}
	}
  if (
    from[crossAxis] + from[crossDimension] > toPos[crossAxis] &&
    from[crossAxis] < toPos[crossAxis] + toPos[crossDimension]
  ) {

    // If From and To are too close in the crossAxis
    const distance = (fromMid[mainAxis] + toMid[mainAxis]) / 2;
		
		const pos: TPoint = {
      [mainAxis]: distance,
      [crossAxis]: fromMid[crossAxis],
		}

    const midPntAlpha: Point = new Point(pos);

    const midPntBeta: Point = new Point({
      [mainAxis]: distance,
      [crossAxis]: toMid[crossAxis],
    });

    const lastPnt: Point = new Point({
      [mainAxis]:
        from[mainAxis] > toPos[mainAxis]
          ? toPos[mainAxis] + toPos[mainDimension]
          : toPos[mainAxis],
      [crossAxis]: toMid[crossAxis],
    });

    return [fromEdge, midPntAlpha, midPntBeta, lastPnt];
  } else {

    const midPoint: Point = new Point({
      [mainAxis]: toMid[mainAxis],
      [crossAxis]: fromMid[crossAxis],
    });

    const lastPnt: Point = new Point({
      [mainAxis]: toMid[mainAxis],
      [crossAxis]:
        from[crossAxis] > toPos[crossAxis]
          ? toPos[crossAxis] + toPos[crossDimension]
          : toPos[crossAxis],
    });

    return [fromEdge, midPoint, lastPnt];
  }
};

/**
 * This state is controlling the creation of a link.
 */
export class CreateLinkState extends AbstractDisplacementState<DiagramEngine> {
  sourcePort: PortModel;
  targetPort: PortModel;
	link: RightAngleLinkModel;
  portLocation: string;
	isLinking: boolean;
	intentOn: PortModel;
	linkingSource: PortModel;

	constructor() {
    super({ name: 'create-new-link' });
		this.registerAction(
			new Action({
				type: InputType.MOUSE_DOWN,
				fire: (event: ActionEvent<MouseEvent, PortModel>) => {
					const element = this.engine.getActionEventBus().getModelForEvent(event);
          if (element instanceof BaseNodePortModel) {
						if (!this.intentOn && element.defaultLocation) {
							this.engine.getModel().clearSelection();
							this.intentOn = element;
						} else {
							return
						}
          }
				}
			})
		);
		this.registerAction(
			new Action({
				type: InputType.MOUSE_UP,
				fire: (actionEvent: ActionEvent<MouseEvent>) => {
					const element = this.engine.getActionEventBus().getModelForEvent(actionEvent);
					if (!this.linkingSource && this.intentOn && element instanceof BaseNodePortModel && element === this.intentOn) {
						// confirm starting port
						this.linkingSource = this.intentOn
						this.isLinking = true
						this.intentOn = undefined
					}
					const {
						event: { clientX, clientY }
          } = actionEvent;
					const ox = this.engine.getModel().getOffsetX();
					const oy = this.engine.getModel().getOffsetY();
					
					if (this.linkingSource && this.linkingSource.getParent() === element) {
						return;
					}

					if (element instanceof BaseNodePortModel && !this.sourcePort) {
						const port = element as BaseNodePortModel
						if (!port.defaultLocation) return
            this.sourcePort = port;
						const link = this.sourcePort.createLinkModel();

            const portRadius = (<BaseNodePortModel> this.sourcePort).getPortRadius();
            link.setSourcePort(this.sourcePort);
						const centerPoint = this.sourcePort.getCenter()
						link.getFirstPoint().setPosition(centerPoint.x, centerPoint.y);
						link.getPoints()[1].setPosition(clientX - ox + (portRadius * 2), clientY - oy + (portRadius * 2));
            link.getLastPoint().setPosition(clientX - ox + (portRadius * 2), clientY - oy + (portRadius * 2));
            const node = (element.getParent() as BaseNodeModel);
            const newPort = node.portAlignmentFinder(clientX, clientY);
						this.portLocation = newPort;
						this.link = (this.engine.getModel().addLink(link) as RightAngleLinkModel);
					} else if (element instanceof BaseNodePortModel && this.sourcePort && element != this.sourcePort && element.getParent() !== this.sourcePort.getParent()) {
						return;
					} else if (this.isLinking && this.link && element === this.link.getLastPoint()) {
						this.link.point(clientX - ox, clientY - oy, -1);
					} else if (element instanceof BaseNodeModel && this.isLinking && element !== this.sourcePort.getParent()) {
						const newPort = element.portFinderForLink(this.link)
            this.link.setTargetPort(newPort, true)
            this.link.getLastPoint().setPosition(newPort.getX() + (newPort.portRadius), newPort.getY() + (newPort.portRadius ));

						this.engine.getModel().addLink(this.link);
						this.isLinking = false;
            this.clearState();
						this.engine.repaintCanvas();
						return
          } else if (!this.isLinking) {
						this.clearState();
						return
					} else {
						this.engine.repaintCanvas();
						return
							
					}
				}
			})
		);

		this.registerAction(
			new Action({
				type: InputType.KEY_UP,
				fire: (actionEvent: ActionEvent<KeyboardEvent>) => {
					// on esc press remove any started link and pop back to default state
					if (actionEvent.event.keyCode === 27) {
						this.link.remove();
						this.clearState();
						this.eject();
						this.engine.repaintCanvas();
					}
				}
			})
    );
  }


  hadToSwitch(points: PointModel[], currentHadToSwitch: boolean, currentStartPoint: PointModel, currentEndPoint: PointModel) {
		const alignment = currentStartPoint.getOptions().link.getSourcePort().getOptions().alignment;
		const orientation = getOrientation(alignment);
		let startPoint = currentStartPoint;
		let endPoint = currentEndPoint;
		let hadToSwitch = currentHadToSwitch;
		if (orientation === LinkPathOrientation.VERTICAL) {
			/*
				when orientation is Vertical
				and startPoint y > endPoint y
				we have to change the angle
			*/
			if (currentStartPoint.getY() > currentEndPoint.getY()) {
				startPoint = points[points.length - 1];
				endPoint = points[0];
				hadToSwitch = true;
			}	
		} else {
			/*
				when orientation is Horizontal
				and startPoint y > endPoint y
				we have to change the angle
			*/
			if (currentStartPoint.getX() > currentEndPoint.getX()) {
				startPoint = points[points.length - 1];
				endPoint = points[0];
				hadToSwitch = true;
			}	
		}
		return {
			startPoint,
			endPoint,
			hadToSwitch,
		}
	}
	updateLinkToPort(port: BaseNodePortModel) {
		const lastLine = this.link.lastLine()
		const direction = RightAngleLinkModel.lineDirection(lastLine[0], lastLine[1])
		const portAligment = port.getOptions().alignment
		const portRadius = port.getPortRadius()
		switch (portAligment) {
			case PortModelAlignment.TOP:
				if (direction === LinkPathOrientation.HORIZONTAL) {
					const targetPortPosition = port.getPosition()
					const newPointIndex = this.link.getPoints().length - 2
					
					const refPoint = this.link.getPoints()[newPointIndex]
					this.link.getPoints()[newPointIndex].setPosition(refPoint.getX(), targetPortPosition.y - MIN_LINE_SIZE)
					this.link.addPoint(
						new PointModel({
							link: this.link,
							position: new Point(targetPortPosition.x + portRadius, targetPortPosition.y - MIN_LINE_SIZE)
						}),
						newPointIndex + 1
					);

				}
				break;
			case PortModelAlignment.BOTTOM:
				if (direction === LinkPathOrientation.HORIZONTAL) {
					const targetPortPosition = port.getPosition()
					const newPointIndex = this.link.getPoints().length - 2
					const refPoint = this.link.getPoints()[newPointIndex]
					this.link.getPoints()[newPointIndex].setPosition(refPoint.getX(), targetPortPosition.y + (portRadius * 2) + MIN_LINE_SIZE)
					this.link.addPoint(
						new PointModel({
							link: this.link,
							position: new Point(targetPortPosition.x + portRadius, targetPortPosition.y + (portRadius * 2) + MIN_LINE_SIZE)
						}),
						newPointIndex + 1
					);

				}
				break;
			case PortModelAlignment.LEFT:
				if (direction === LinkPathOrientation.VERTICAL) {
					const targetPortPosition = port.getPosition()
					const newPointIndex = this.link.getPoints().length - 2
					const refPoint = this.link.getPoints()[newPointIndex]
					this.link.getPoints()[newPointIndex].setPosition(targetPortPosition.x - MIN_LINE_SIZE, refPoint.getY())
					this.link.addPoint(
						new PointModel({
							link: this.link,
							position: new Point(targetPortPosition.x - MIN_LINE_SIZE, targetPortPosition.y + portRadius)
						}),
						newPointIndex + 1
					);
				}
				break;
			case PortModelAlignment.RIGHT:
				if (direction === LinkPathOrientation.VERTICAL) {
					const targetPortPosition = port.getPosition()
					const newPointIndex = this.link.getPoints().length - 2
					const refPoint = this.link.getPoints()[newPointIndex]
					this.link.getPoints()[newPointIndex].setPosition(targetPortPosition.x + MIN_LINE_SIZE + (portRadius * 2), refPoint.getY())
					this.link.addPoint(
						new PointModel({
							link: this.link,
							position: new Point(targetPortPosition.x + MIN_LINE_SIZE + (portRadius * 2), targetPortPosition.y + portRadius)
						}),
						newPointIndex + 1
					);
				}
				break;
			default:
		}
		this.link.setTargetPort(port, true);

	}


  selfLinkFrom(node: BaseNodeModel, port: PortModelAlignment, ox: number, oy: number, clientX: number, clientY: number) {
    if (!this.targetPort || this.targetPort && (port !== this.targetPort.getOptions().alignment)) {
      const ports = node.getPorts();
      const deltaY = 50;
      const deltaX = 150;
      this.link.remove();
      const link = this.sourcePort.createLinkModel();
      link.setSourcePort(ports[port]);
      const { x:newPosX, y: newPosY } = ports[port].getPosition();
      const { x: centerX, y: centerY } = node.center();
      const portRadius = (<BaseNodePortModel> this.sourcePort).getPortRadius();
  
      if (port === PortModelAlignment.TOP || port === PortModelAlignment.BOTTOM) {
        const XSign = (clientX - ox > centerX) ? 1 : -1;
        const YSign = (port === PortModelAlignment.TOP) ? -1 : 1;
        let target: PortModelAlignment;
        if (XSign > 0) {
          target = PortModelAlignment.RIGHT;
          this.targetPort = ports[PortModelAlignment.RIGHT];
          link.setTargetPort(ports[PortModelAlignment.RIGHT]);
        } else {
          target = PortModelAlignment.LEFT;
          this.targetPort = ports[PortModelAlignment.LEFT];
          link.setTargetPort(ports[PortModelAlignment.LEFT]);
        }
        const { x:targetX, y: targetY } = this.targetPort.getPosition();
  
        const points = [
          [newPosX, newPosY],
          [newPosX, newPosY + (deltaY * YSign)],
          [newPosX + (deltaX * XSign), newPosY + (deltaY * YSign)],
          [newPosX + (deltaX * XSign), targetY],
          [targetX, targetY],
        ]
        link.setPoints([
          new PointModel({ link: this.link, position: new Point(newPosX + portRadius, newPosY + portRadius)}),
          new PointModel({ link: this.link, position: new Point(newPosX + portRadius, newPosY + portRadius + (deltaY * YSign))}),
          new PointModel({ link: this.link, position: new Point(newPosX + (deltaX * XSign), newPosY + portRadius + (deltaY * YSign))}),
          new PointModel({ link: this.link, position: new Point(newPosX + (deltaX * XSign), targetY + portRadius)}),
          new PointModel({ link: this.link, position: new Point(targetX + portRadius, targetY + portRadius)}),
        ])
        this.link = (this.engine.getModel().addLink(link) as RightAngleLinkModel);
  
      }
  
    }
  }


	clearState() {
		this.intentOn = undefined;
		this.isLinking = undefined;
		this.linkingSource = undefined;
		this.link = undefined;
		this.sourcePort = undefined;
		this.portLocation = undefined;
  }
	fireMouseMoved(event: AbstractDisplacementStateEvent): any {

		if (!this.isLinking) return
		if (!this.sourcePort) return
		const engineModel = this.engine.getModel();

		const portPos = this.sourcePort.getPosition();
		
		const zoomLevelPercentage = this.engine.getModel().getZoomLevel() / 100;
		const engineOffsetX = this.engine.getModel().getOffsetX() / zoomLevelPercentage;
		const engineOffsetY = this.engine.getModel().getOffsetY() / zoomLevelPercentage;
		const initialXRelative = this.initialXRelative / zoomLevelPercentage;
		const initialYRelative = this.initialYRelative / zoomLevelPercentage;
		const linkNextX = engineModel.getGridPosition(portPos.x - engineOffsetX + (initialXRelative - portPos.x) + event.virtualDisplacementX);
		const linkNextY = engineModel.getGridPosition(portPos.y - engineOffsetY + (initialYRelative - portPos.y) + event.virtualDisplacementY);

		const posPoint = new Point({
			x: linkNextX,
			y: linkNextY,
		})
		const model = this.sourcePort.getParent() as BaseNodeModel
		const newLink = LinkModel.calcLinkPoints(model, posPoint)
		const possiblePort = model.portAlignmentFinder(posPoint.x, posPoint.y)
		if (possiblePort !== this.sourcePort.getOptions().alignment) {
			this.link.setSourcePort(model.portByAlignment(possiblePort))
			this.sourcePort = model.portByAlignment(possiblePort)
		}
		if (newLink.length > this.link.getPoints().length) {
			const lastPoint = newLink[newLink.length - 1]
			const newPoint = new PointModel({
				link: this.link,
				position: new Point(lastPoint.x, lastPoint.y)
			})
			this.link.addPoint(newPoint, newLink.length - 1)
		}
		if (this.link.getPoints().length > newLink.length) {
			this.link.removePoint(this.link.getLastPoint())
		}
		this.link.getPoints().map((point, index) => {
			point.setPosition(newLink[index].x, newLink[index].y)
		})

		this.engine.repaintCanvas();
	}	

}
