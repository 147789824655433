import { Action, ActionEvent, InputType, State, CanvasEngine, BaseModel, AbstractDisplacementState, AbstractDisplacementStateEvent, BasePositionModel } from '../../canvas-core';
import { PortModel, LinkModel, DiagramEngine, NodeModel, PortModelAlignment, NodeModelGenerics, PointModel, DiagramModel  } from '../../diagrams-core';
import { MouseEvent, KeyboardEvent, DOMElement, ReactElement, SyntheticEvent } from 'react';
import { BaseNodeModel, BaseNodeModelGenerics } from '../../models/BaseNode/BaseNodeModel';
import { Point } from '../../geometry';
import { BaseNodePortModel } from '../../models/BaseNode/BaseNodePortModel';
import { HorizontalMovement, LinkPathOrientation, VerticalMovement } from '../../diagrams-core/entities/link/RightAngleLinkModel';
import { Collisions, getOrientation, RightAngleLinkModel } from '../../routing/link/RightAngleLinkModel';
import { capitalize } from 'lodash';
import MODEL_DEFAULTS from '../../models/BaseNode/constants'

/**
 * This state is controlling the creation of a link.
 */
const enum Position {
	startPoint = 'startPoint',
	endPoint = 'endPoint',
}

const enum LineTypes {
	first = 'first',
	last = 'last',
	middle = 'middle',
}

type Override = {
	model: BaseNodeModel,
	position: PortModelAlignment,
}

export class DragLinkState extends AbstractDisplacementState<DiagramEngine> {
	// export class DragLinkState <E extends CanvasEngine = CanvasEngine> extends AbstractDisplacementState<E> {
  sourcePort: BaseNodePortModel;
	targetPort: BaseNodePortModel;
	affectedPort: BaseNodePortModel;
	affectedPortLocation: 'sourcePort' | 'targetPort';
	affectedPortAlignment: PortModelAlignment;
	draggingPort: BaseNodePortModel;
	draggingPortLocation: 'sourcePort' | 'targetPort';
	draggingPortAlignment: PortModelAlignment;
	source: BaseNodeModel;
	target: BaseNodeModel;
	link: RightAngleLinkModel;
  portLocation: string;
	isLinking: boolean;
	intentOn: BaseNodePortModel;
  linkingSource: BaseNodePortModel;
	dragging_index: number;
	newPort: BaseNodePortModel;
	dragging_line: LineTypes;
	draggingCoordinate: 'x' | 'y' | null
	draggingOppositeCoordinate: 'x' | 'y' | null
	lineOrientation: LinkPathOrientation
	overflowElement: BaseNodeModel
	canOverflow: boolean
	canOverride: boolean
	overflow: PortModelAlignment
	override: PortModelAlignment
	overrides: [Override]
	initialPositions: {
		[id: string]: {
			[Position.startPoint]: Point;
			[Position.endPoint]: Point;
		};
	};
	onDragPositions: {
		[id: number]: {
			[Position.startPoint]: Point;
			[Position.endPoint]: Point;
		};
	}
	verticalMovement: {
		initialPositions: VerticalMovement;
		onDragPositions: VerticalMovement;
	}
	horizontalMovement: {
		initialPositions: HorizontalMovement;
		onDragPositions: HorizontalMovement;
	}

	setDraggingIndex(htmlElement: HTMLElement) {
		const index = htmlElement.getAttribute('data-point')
		this.dragging_index = parseInt(index, 10)
	}

	stopDraggingPorts() {
		this.affectedPort = undefined
		this.draggingPort.getParent().removePort(this.draggingPort)
		this.draggingPort = undefined
	}

	getColisionedModel(collisions: Collisions){
		let models = []
		if (collisions.source) models.push('source')
		if (collisions.target) models.push('target')
		return models
	}


	updateContextFromOverride(collisions: Collisions) {
		const rightLine = this.link.getLine(this.dragging_index)
		this.lineOrientation = LinkModel.lineDirection(rightLine[0], rightLine[1])
		const sourceOrTarget = this.getColisionedModel(collisions)[0]

		const rightPoints = this.link.getPoints()
		const firstOrLast = (sourceOrTarget === 'source') ? 'getFirstPoint' : 'getLastPoint'
		this.link.removePoint(this.link[firstOrLast]())
		let rightUpdatedPoint = {
			x: this.link[firstOrLast]().getX(),
			y: this.link[firstOrLast]().getY(),
		}

		let pointIndex = (sourceOrTarget === 'source') ? 0 : rightPoints.length - 1
		this.dragging_index = (sourceOrTarget === 'source') ? 0 : this.dragging_index
		const newPoint: any = {
			x: this[sourceOrTarget].getX() + this[sourceOrTarget].width,
			y:rightUpdatedPoint.y,
		}
		this.link.getPoints()[pointIndex].setPosition(newPoint.x, newPoint.y)

		this.affectedPortAlignment = this.override;
		// console.log('set this.affectedPortAlignment', this.override)
		this.affectedPort = this[sourceOrTarget].portByAlignment(this.affectedPortAlignment);
		this.draggingPort = (!this.draggingPort) ? new BaseNodePortModel(this.affectedPortAlignment, false) : this.draggingPort
		this.draggingPort.setPosition(newPoint.x, newPoint.y)
		if (sourceOrTarget === 'source') {
			this.link.setSourcePort(this.draggingPort)
			this.sourcePort = this.draggingPort
			this.draggingPortLocation = 'sourcePort'
			this.draggingPortAlignment = this.affectedPortAlignment
			this.source.addPort(this.draggingPort)
		} else {
			this.link.setTargetPort(this.draggingPort)
			this.sourcePort = this.draggingPort
			this.draggingPortLocation = 'targetPort'
			this.draggingPortAlignment = this.affectedPortAlignment
			this.target.addPort(this.draggingPort)	
		}
		const newLine = this.link.getLine(this.dragging_index)
		this.lineOrientation = LinkModel.lineDirection(newLine[0], newLine[1])
		this.draggingCoordinate = (this.lineOrientation === LinkPathOrientation.HORIZONTAL) ? 'y' : 'x'
		this.draggingOppositeCoordinate = (this.draggingCoordinate === 'x') ? 'y' : 'x'
		this.override = undefined
		this.dragging_line = this.getDraggingLineType(this.dragging_index)
	}

	setDraggingContext(link: RightAngleLinkModel, event: ActionEvent<MouseEvent, PortModel>) {
		this.link = link
		this.setDraggingIndex(((event.event).target) as HTMLElement)
		const item = this.link.getLine(this.dragging_index);
		this.lineOrientation = LinkModel.lineDirection(item[0], item[1])
		this.dragging_line = this.getDraggingLineType(this.dragging_index)
		if (!this.source) {
			if (!this.link.getSourcePort()){
				console.log('link has no source port', this.link)
			}
			this.source = (this.link.getSourcePort().getParent() as BaseNodeModel)
			this.sourcePort = (this.link.getSourcePort() as BaseNodePortModel)
		}
		if (!this.target) {
			this.target = (this.link.getTargetPort().getParent() as BaseNodeModel)						
			this.targetPort = (this.link.getTargetPort()  as BaseNodePortModel)
		}

		const sourcePort = this.dragging_index === 0
		if (!this.affectedPort && sourcePort) {
			this.affectedPortLocation = 'sourcePort';
			this.affectedPortAlignment = this.sourcePort.getOptions().alignment;
			this.affectedPort = this.source.portByAlignment(this.affectedPortAlignment);
			this.draggingPort = (this.sourcePort.defaultLocation) ? new BaseNodePortModel(this.affectedPortAlignment, false) : this.sourcePort 
			if (this.sourcePort.defaultLocation) {
				this.draggingPort.setPosition(this.affectedPort.getPosition())
			}
			this.draggingPortLocation = 'sourcePort';
			this.draggingPortAlignment = this.affectedPortAlignment
			if (this.sourcePort.defaultLocation) {
				this.source.addPort(this.draggingPort)
				this.link.setSourcePort(this.draggingPort)
				this.sourcePort = this.draggingPort
			}
		}
		const targetPort = this.dragging_index === this.link.getPoints().length - 2
		if (!this.affectedPort && targetPort) {
			this.affectedPortLocation = 'targetPort';
			this.affectedPortAlignment = this.targetPort.getOptions().alignment;	
			this.affectedPort = this.target.portByAlignment(this.affectedPortAlignment);
			this.draggingPort = (this.targetPort.defaultLocation) ? new BaseNodePortModel(this.affectedPortAlignment, false) : this.targetPort
			if (this.targetPort.defaultLocation) {
				this.draggingPort.setPosition(this.affectedPort.getPosition())
			}

			this.draggingPortLocation = 'targetPort';
			this.draggingPortAlignment = this.affectedPortAlignment
					
			if (this.targetPort.defaultLocation) {
				this.target.addPort(this.draggingPort)
				this.link.setTargetPort(this.draggingPort)
				this.targetPort = this.draggingPort
			}
		}
		if (this.draggingPort) {
			// console.log('dragging port in context set', this.link, this.draggingPort, (this.draggingPort.getParent() as BaseNodeModel).label)
		}
		this.setOverrideAndOverflow()
		this.draggingCoordinate = (this.lineOrientation === LinkPathOrientation.HORIZONTAL) ? 'y' : 'x'
		this.draggingOppositeCoordinate = (this.draggingCoordinate === 'x') ? 'y' : 'x'

	}
	getDraggingLineType(index: number):LineTypes {
		if (index === this.link.getPoints().length - 2) {
			return LineTypes.last
		} else if (this.dragging_index === 0) {
			return LineTypes.first
		} else {
			return  LineTypes.middle
		}
	}

	setOverrideAndOverflow() {
		// console.log('this.dragging_line', this.dragging_line)
		// console.log('this.dragging_index', this.dragging_index)
		// console.log('this.link.getPoints().length - 4', this.link.getPoints().length - 4)
		// console.log('this.link.getPoints().length - 3', this.link.getPoints().length - 3)
		if (this.dragging_line === LineTypes.last || this.dragging_line === LineTypes.first) {
			this.canOverflow = true
			this.canOverride = true
		} else if (
			this.dragging_line === LineTypes.middle && 
			(this.dragging_index === this.link.getPoints().length - 3)
		) {
			this.canOverflow = false
			this.canOverride = true
		} else if (
			this.dragging_line === LineTypes.middle && 
			(this.dragging_index === this.link.getPoints().length - 4 || this.dragging_index === 1)
		) {
			this.canOverflow = false
			this.canOverride = true
		} else if (
			this.dragging_line === LineTypes.middle && (this.dragging_index >= 2 && this.dragging_index <= this.link.getPoints().length - 4) 
		) {
			this.canOverflow = false
			this.canOverride = false
		}
		this.overflowElement = (this.dragging_line === LineTypes.first) 
			? this.source as BaseNodeModel
			: this.target as BaseNodeModel
	}

	constructor() {
		super({ name: 'drag-link' });
		this.horizontalMovement = {
			initialPositions: HorizontalMovement.NONE,
			onDragPositions: HorizontalMovement.NONE,
		}
		this.verticalMovement = {
			initialPositions: VerticalMovement.NONE,
			onDragPositions: VerticalMovement.NONE,
		}
		this.overflow = undefined
		this.override = undefined

		this.registerAction(
			new Action({
				type: InputType.MOUSE_DOWN,
				fire: (event: ActionEvent<MouseEvent, PortModel>) => {
          const element = this.engine.getActionEventBus().getModelForEvent(event);
					if (element instanceof RightAngleLinkModel && this.dragging_index === undefined) {
						if (!element.isSelected()) {
							this.engine.getModel().clearSelection();
						}
						element.setSelected(true);
						this.setDraggingContext(element, event)
          }
					return
				}
			})
		);
		this.registerAction(
			new Action({
				type: InputType.MOUSE_UP,
				fire: (actionEvent: ActionEvent<MouseEvent>) => {
					const element = this.engine.getActionEventBus().getModelForEvent(actionEvent);
					if (element instanceof RightAngleLinkModel) {
						let defaultPort: BaseNodePortModel
						let portPosition
						let line
						let pointIndex
						let sourceOrTarget : string
						const points = this.link.getPoints()
						if (this.dragging_line === LineTypes.last) {
							sourceOrTarget = 'target'
							line = this.link.lastLine()
							pointIndex = points.length - 2
						} else if (this.dragging_line === LineTypes.first) {
							sourceOrTarget = 'source'
							line = this.link.firstLine()
							pointIndex = 0
						} else if (this.dragging_index === 1) {
							this.engine.repaintCanvas();
							this.clearState()	
							return
						} else if (points.length > 3 && this.dragging_index === points.length - 2) {
							sourceOrTarget = 'target'
							pointIndex = this.dragging_index
							line = this.link.getLine(this.dragging_index)
						} else {
							this.engine.repaintCanvas();
							this.clearState()	
							return
						}
										
						const st = capitalize(sourceOrTarget)
						const getPoint = (this.dragging_line === LineTypes.last) ? 'getLastPoint' : 'getFirstPoint'
						if (this.lineOrientation === LinkPathOrientation.VERTICAL) {
							if (sourceOrTarget === 'target') {
								portPosition = (line[1].getY() < line[0].getY()) ? PortModelAlignment.BOTTOM : PortModelAlignment.TOP
							} else {
								portPosition = (line[0].getY() < line[1].getY()) ? PortModelAlignment.BOTTOM : PortModelAlignment.TOP
							}
							const dx = Math.abs((this.link[getPoint]().getX() - this.affectedPort.getPortRadius()) - this.affectedPort.getX());

							if (dx > this.affectedPort.getPortRadius() * MODEL_DEFAULTS.MIN_PORT_RANGE_RATIO) {
								let suplement = 0
								if (this.dragging_index === 0) {
									suplement = this.source.linkSuplement(this.draggingPort, this.draggingCoordinate, dx )
								} else if (this.dragging_index === this.link.getPoints().length - 2) {
									suplement = this.target.linkSuplement(this.draggingPort, this.draggingCoordinate, dx )
								}
			
								this.draggingPort.setPosition(this.link[getPoint]().getX() - this.draggingPort.getPortRadius(), (this.link[getPoint]().getY()) - this.draggingPort.getPortRadius())
								this.draggingPort.reportPosition()
							} else {
								// console.log('target', this.link.getTargetPort().getParent().getID())
								// console.log('affected', this.affectedPort.getID())
								const points = this.link.getPoints()
								const prevLastPoint = points[pointIndex]
								const lastPoint = points[pointIndex + 1]
								this.draggingPort.getParent().removePort(this.draggingPort)
								prevLastPoint.setPosition(this.affectedPort.getX() + this.affectedPort.getPortRadius(), prevLastPoint.getY())
								lastPoint.setPosition(this.affectedPort.getX() + this.affectedPort.getPortRadius(), lastPoint.getY())
								if (sourceOrTarget === 'source') {
									this.link.setSourcePort(this.affectedPort)
								} else {
									this.link.setTargetPort(this.affectedPort)
								}
							}
						} else {
							if (sourceOrTarget === 'target') {
								portPosition = (line[0].getX() < line[1].getX()) ? PortModelAlignment.LEFT : PortModelAlignment.RIGHT
							} else {
								portPosition = (line[1].getX() < line[0].getX()) ? PortModelAlignment.LEFT : PortModelAlignment.RIGHT
							}
							if (sourceOrTarget) {
								defaultPort = this[sourceOrTarget][`${portPosition}Port`]
							}
							const dy = Math.abs(this.link[getPoint]().getY() - (defaultPort.getY() + defaultPort.getPortRadius()));
							if (dy > this.affectedPort.getPortRadius() * MODEL_DEFAULTS.MIN_PORT_RANGE_RATIO) {
								let suplement = 0
								if (this.dragging_index === 0) {
									suplement = this.source.linkSuplement(this.draggingPort, this.draggingCoordinate, dy )
								} else if (this.dragging_index === this.link.getPoints().length - 2) {
									suplement = this.target.linkSuplement(this.draggingPort, this.draggingCoordinate, dy )
								}

								this.draggingPort.setPosition(this.link[getPoint]().getX() - this.draggingPort.getPortRadius(), this.link[getPoint]().getY() - this.draggingPort.getPortRadius())
								this.draggingPort.reportPosition()
							} else {
								const points = this.link.getPoints()
								const prevLastPoint = points[pointIndex]
								const lastPoint = points[pointIndex + 1]
								this.draggingPort.getParent().removePort(this.draggingPort)
								prevLastPoint.setPosition(prevLastPoint.getX(), this.affectedPort.getY() + this.affectedPort.getPortRadius())
								lastPoint.setPosition(lastPoint.getX(), this.affectedPort.getY() + this.affectedPort.getPortRadius())
								// console.log('y', this.affectedPort.getY() + this.affectedPort.getPortRadius())

								this.link[`set${st}Port`](this.affectedPort)
							}
						}
						this.link = (this.engine.getModel().addLink(this.link) as RightAngleLinkModel);	
						this.engine.repaintCanvas();
						this.clearState()
					
					}
				return;


				}
			})
		);

		this.registerAction(
			new Action({
				type: InputType.KEY_UP,
				fire: (actionEvent: ActionEvent<KeyboardEvent>) => {
					// on esc press remove any started link and pop back to default state
					if (actionEvent.event.keyCode === 27) {
						if (!(this.link.getTargetPort() as BaseNodePortModel).defaultLocation) {
							this.target.removePort(this.link.getTargetPort())
						}
						if (!(this.link.getSourcePort() as BaseNodePortModel).defaultLocation) {
							this.source.removePort(this.link.getTargetPort())
						}
						this.link.remove();

						this.clearState();
						this.eject();
						this.engine.repaintCanvas();
					}
				}
			})
    );
	}
	activated(previous: State) {
		super.activated(previous);
		this.initialPositions = {};
		this.onDragPositions = {};
	}
	deactivated(next: State) {
		super.deactivated(next);
	}

	clearState() {
		this.link = undefined;
		this.dragging_index = undefined;
		this.linkingSource = undefined;
		this.newPort = undefined;
		this.targetPort = undefined;
		this.sourcePort = undefined;
		this.source = undefined;
		this.target = undefined;
		this.overflow = undefined;
		this.override = undefined;
		this.canOverride = undefined;
		this.canOverflow = undefined;
		this.draggingCoordinate = undefined;
		this.draggingOppositeCoordinate = undefined;
		this.initialPositions = undefined;
		this.lineOrientation = undefined;
		this.overflowElement = undefined;
		this.affectedPort = undefined;
		this.affectedPortLocation = undefined;
		this.affectedPortAlignment = undefined;	
		this.draggingPort = undefined;
		this.draggingPortLocation = undefined;
		this.draggingPortAlignment = undefined;

	}
	
	detectChanges(index: number, position: Position, x:number, y: number, state = 'initialPositions') {
		if (this[state][index][position].x > x) {
			this.horizontalMovement[state] = HorizontalMovement.LEFT;
		} else if (this[state][index][position].x < x) {
			this.horizontalMovement[state] = HorizontalMovement.RIGHT;
		} else {
			this.horizontalMovement[state] = HorizontalMovement.NONE;
		}
		if (this[state][index][position].y > y) {
			this.verticalMovement[state] = VerticalMovement.UP;
		} else if (this[state][index][position].y < y) {
			this.verticalMovement[state] = VerticalMovement.DOWN;
		} else {
			this.verticalMovement[state] = VerticalMovement.NONE;
		}
	}

	setInitiaPositionsOnDragging() {
			// set initial positions
			const points = this.link.getPoints()
			if (!this.initialPositions[this.dragging_index]) {
				this.initialPositions[this.dragging_index] = {
					startPoint: points[this.dragging_index].getPosition(),
					endPoint: points[this.dragging_index + 1].getPosition(),
				};
			}
			if (!this.onDragPositions[this.dragging_index]) {
				this.onDragPositions[this.dragging_index] = {
					startPoint: points[this.dragging_index].getPosition(),
					endPoint: points[this.dragging_index + 1].getPosition(),
				};
			}
	}

	detectChangesOnDragging(event: AbstractDisplacementStateEvent, model:DiagramModel) {
			// start and end position
			const startPos = this.initialPositions[this.dragging_index].startPoint;
			const endPos = this.initialPositions[this.dragging_index].endPoint;
		
			// grid position
			const startX = model.getGridPosition(startPos.x + event.virtualDisplacementX);
			const startY = model.getGridPosition(startPos.y + event.virtualDisplacementY);
			const endX = model.getGridPosition(endPos.x + event.virtualDisplacementX);
			const endY = model.getGridPosition(endPos.y + event.virtualDisplacementY);
		
			// detect changes
			this.detectChanges(this.dragging_index, Position.startPoint, startX, startY, 'onDragPositions')
			this.detectChanges(this.dragging_index, Position.startPoint, startX, startY)
			this.detectChanges(this.dragging_index, Position.endPoint, endX, endY, 'onDragPositions')
			this.detectChanges(this.dragging_index, Position.endPoint, endX, endY)
	}

	fireMouseMoved(event: AbstractDisplacementStateEvent): any {
		const model = this.engine.getModel();
		const engineModel = this.engine.getModel();

		if (this.link) {
			const points = this.link.getPoints()

			// check vertical or horizontal
			const item = this.link.getLine(this.dragging_index);
			const collisions = this.link.detectLineColissions(this.dragging_index, this.source, this.target)

			// set position marks
			this.setInitiaPositionsOnDragging()

			// detect changes
			this.detectChangesOnDragging(event, model)

			// const linkNextX = engineModel.getGridPosition(portPos.x - engineOffsetX + (initialXRelative - portPos.x) + event.virtualDisplacementX);
			// const linkNextY = engineModel.getGridPosition(portPos.y - engineOffsetY + (initialYRelative - portPos.y) + event.virtualDisplacementY);
	
	

			// future points
			let _points = {
				[this.dragging_index]: this.link.getPoints()[this.dragging_index].getPosition(),
				[this.dragging_index + 1]: this.link.getPoints()[this.dragging_index + 1].getPosition()
			};

			// event coordinates
			// e contains the point for the coordinate that handles the drag
			const e = {}
			e[this.draggingCoordinate] = engineModel.getGridPosition(this.engine.getRelativeMousePoint(event.event)[this.draggingCoordinate])
			// const ePoint = {
			// 	x: this.engine.getRelativeMousePoint(event.event).x, 
			// 	y: this.engine.getRelativeMousePoint(event.event).y
			// }

			const ePoint = {
				x: engineModel.getGridPosition(this.engine.getRelativeMousePoint(event.event).x), 
				y: engineModel.getGridPosition(this.engine.getRelativeMousePoint(event.event).y)
			}


			// detect collisions
			const getColisionedModel = (collisions: Collisions) => {
				let models = []
				if (collisions.source) models.push('source')
				if (collisions.target) models.push('target')
				return models
			}
			
			if (this.canOverride) {
				if (this.dragging_line === LineTypes.middle && (this.dragging_index === 1 || this.dragging_index === points.length - 3)) {
					const theLine = this.link.getLine(this.dragging_index)
					const collisionedModel = getColisionedModel(collisions)
					if (collisionedModel.length > 0) {
						// console.log('collisionedModel.length', collisionedModel.length)
						const model = (this[collisionedModel[0]] as BaseNodeModel)
						this.override = this.link.detectLineOverride(this.draggingCoordinate, model, this.dragging_index, theLine)
					}
				}
			}
			
			if (this.canOverflow) {
				if (this.dragging_line !== LineTypes.middle) {
					this.overflow = this.link.lineCardinalPositionToNode(this.overflowElement, this.lineOrientation, ePoint)
				}
			}

			let sourceOrTarget = (this.dragging_line === LineTypes.first) ? 'source' : (this.dragging_line === LineTypes.last) ? 'target' : undefined 
			if (this.override) {
				// console.log('override', this.override)
			}


			if (this.override) {
				let firstOrLast: string, pointIndex: number
				sourceOrTarget = getColisionedModel(collisions)[0]
				firstOrLast = (sourceOrTarget === 'source') ? 'getFirstPoint' : 'getLastPoint'
				const model = (collisions[sourceOrTarget] as BaseNodeModel)
				const line = this.link.getLine(this.dragging_index)
				this.lineOrientation = LinkModel.lineDirection(line[0], line[1])
				const points = this.link.getPoints()
				switch (this.override) {
					case PortModelAlignment.LEFT:
						this.updateContextFromOverride(collisions)
						_points = {
							[this.dragging_index]: this.link.getPoints()[this.dragging_index].getPosition(),
							[this.dragging_index + 1]: this.link.getPoints()[this.dragging_index + 1].getPosition()
						};
						this.setOverrideAndOverflow()
	
						break;
					case PortModelAlignment.RIGHT:
						this.updateContextFromOverride(collisions)
						_points = {
							[this.dragging_index]: this.link.getPoints()[this.dragging_index].getPosition(),
							[this.dragging_index + 1]: this.link.getPoints()[this.dragging_index + 1].getPosition()
						};
						this.setOverrideAndOverflow()	
						break;
					case PortModelAlignment.BOTTOM:
						this.updateContextFromOverride(collisions)
						_points = {
							[this.dragging_index]: this.link.getPoints()[this.dragging_index].getPosition(),
							[this.dragging_index + 1]: this.link.getPoints()[this.dragging_index + 1].getPosition()
						};
						// this.dragging_line = this.getDraggingLineType(this.dragging_index)
						this.setOverrideAndOverflow()
						break;

					case PortModelAlignment.TOP:
						// console.log('here?', this.link.getPoints(),this.dragging_index + 1)
						this.updateContextFromOverride(collisions)
						_points = {
							[this.dragging_index]: this.link.getPoints()[this.dragging_index].getPosition(),
							[this.dragging_index + 1]: this.link.getPoints()[this.dragging_index + 1].getPosition()
						};
						// console.log('points', _points)
						// this.dragging_line = this.getDraggingLineType(this.dragging_index)
						this.setOverrideAndOverflow()
						break;
					default:
	
				}
	
			}
			if (this.overflow) {
				// console.log('overflow', this.overflow)
			} else {
				// console.log('no overflow', this.canOverflow)
				
			}

			switch (this.overflow) {
				case PortModelAlignment.TOP:
					// switch dragging port		
					this.stopDraggingPorts()

					const topPort = (this[sourceOrTarget] as BaseNodeModel).portByAlignment(this.overflow)
					this[`${sourceOrTarget}Port`].targetPort = topPort;
							this.dragging_line = LineTypes.middle
					const topPoint = new PointModel({
						link: this.link,
						position: new Point(topPort.getX() + topPort.getPortRadius(), topPort.getY())
					});
					const topPosition = (sourceOrTarget === 'source') ? 0 : this.link.getPoints().length

					this.link.addPoint(topPoint, topPosition)
					if (sourceOrTarget === 'source') {
						let _points = {
							[this.dragging_index]: this.link.getPoints()[this.dragging_index].getPosition(),
							[this.dragging_index + 1]: this.link.getPoints()[this.dragging_index + 1].getPosition()
						};
						_points[this.dragging_index][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1]['x'] = topPort.getPosition().x + topPort.getPortRadius();
						this.link.getPoints()[this.dragging_index].setPosition(_points[this.dragging_index]);
						this.link.getPoints()[this.dragging_index + 1].setPosition(_points[this.dragging_index + 1]);
				
					} else if (sourceOrTarget === 'target') {
						_points[this.dragging_index][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1]['x'] = topPort.getPosition().x + topPort.getPortRadius();
						this.link.getPoints()[this.dragging_index].setPosition(_points[this.dragging_index]);
						this.link.getPoints()[this.dragging_index + 1].setPosition(_points[this.dragging_index + 1]);
	
					}
					this.dragging_index = (sourceOrTarget === 'source') ? 1 : this.dragging_index

					if (sourceOrTarget === 'target') {
						this.link.setTargetPort(topPort)
					} else {
						this.link.setSourcePort(topPort)
					}
					topPort.reportPosition()
					this.overflow = undefined
					break;

				case PortModelAlignment.BOTTOM:
					// switch dragging port
					// console.log('switch dragging port', this.overflow)			
					this.stopDraggingPorts()

					const bottomPort = (this[sourceOrTarget] as BaseNodeModel).portByAlignment(this.overflow)
					this[`${sourceOrTarget}Port`].targetPort = bottomPort;
							this.dragging_line = LineTypes.middle
					const bottomPoint = new PointModel({
						link: this.link,
						position: new Point(bottomPort.getX() + bottomPort.getPortRadius(), bottomPort.getY())
					});
					const bottomPosition = (sourceOrTarget === 'source') ? 0 : this.link.getPoints().length

					this.link.addPoint(bottomPoint, bottomPosition)
					if (sourceOrTarget === 'source') {
						let _points = {
							[this.dragging_index]: this.link.getPoints()[this.dragging_index].getPosition(),
							[this.dragging_index + 1]: this.link.getPoints()[this.dragging_index + 1].getPosition()
						};
						_points[this.dragging_index][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1]['x'] = bottomPort.getPosition().x + bottomPort.getPortRadius();
						this.link.getPoints()[this.dragging_index].setPosition(_points[this.dragging_index]);
						this.link.getPoints()[this.dragging_index + 1].setPosition(_points[this.dragging_index + 1]);
				
					} else if (sourceOrTarget === 'target') {
						_points[this.dragging_index][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1]['x'] = bottomPort.getPosition().x + bottomPort.getPortRadius();
						this.link.getPoints()[this.dragging_index].setPosition(_points[this.dragging_index]);
						this.link.getPoints()[this.dragging_index + 1].setPosition(_points[this.dragging_index + 1]);
	
					}
					this.dragging_index = (sourceOrTarget === 'source') ? 1 : this.dragging_index

					if (sourceOrTarget === 'target') {
						this.link.setTargetPort(bottomPort)
					} else {
						this.link.setSourcePort(bottomPort)
					}
					bottomPort.reportPosition()
					this.overflow = undefined

					break;
					
				case PortModelAlignment.LEFT:
					// switch dragging port					
					// console.log('switch dragging port', this.overflow)			
					this.stopDraggingPorts()

					const leftPort = (this[sourceOrTarget] as BaseNodeModel).portByAlignment(this.overflow)
					this[`${sourceOrTarget}Port`].targetPort = leftPort;
					this.dragging_line = LineTypes.middle
					const leftPoint = new PointModel({
						link: this.link,
						position: new Point(leftPort.getX() , leftPort.getY() + leftPort.getPortRadius())
					});
					const leftPosition = (sourceOrTarget === 'source') ? 0 : this.link.getPoints().length

					this.link.addPoint(leftPoint, leftPosition)
					if (sourceOrTarget === 'source') {
						let _points = {
							[this.dragging_index]: this.link.getPoints()[this.dragging_index].getPosition(),
							[this.dragging_index + 1]: this.link.getPoints()[this.dragging_index + 1].getPosition()
						};
						_points[this.dragging_index][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1]['y'] = leftPort.getPosition().y + leftPort.getPortRadius();
						this.link.getPoints()[this.dragging_index].setPosition(_points[this.dragging_index]);
						this.link.getPoints()[this.dragging_index + 1].setPosition(_points[this.dragging_index + 1]);
				
					} else if (sourceOrTarget === 'target') {
						_points[this.dragging_index][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1]['y'] = leftPort.getPosition().y + leftPort.getPortRadius();
						this.link.getPoints()[this.dragging_index].setPosition(_points[this.dragging_index]);
						this.link.getPoints()[this.dragging_index + 1].setPosition(_points[this.dragging_index + 1]);
	
					}
					this.dragging_index = (sourceOrTarget === 'source') ? 1 : this.dragging_index

					if (sourceOrTarget === 'target') {
						this.link.setTargetPort(leftPort)
					} else {
						this.link.setSourcePort(leftPort)
					}
					leftPort.reportPosition()
					this.overflow = undefined

					break;


				case PortModelAlignment.RIGHT:
					// switch dragging port					
					// console.log('switch dragging port', this.overflow)			
					this.stopDraggingPorts()

					const rightPort = (this[sourceOrTarget] as BaseNodeModel).portByAlignment(this.overflow)
					this[`${sourceOrTarget}Port`].targetPort = rightPort;
							this.dragging_line = LineTypes.middle
					const rightPoint = new PointModel({
						link: this.link,
						position: new Point(rightPort.getX() , rightPort.getY() + rightPort.getPortRadius())
					});
					const rightPosition = (sourceOrTarget === 'source') ? 0 : this.link.getPoints().length

					this.link.addPoint(rightPoint, rightPosition)
					if (sourceOrTarget === 'source') {
						let _points = {
							[this.dragging_index]: this.link.getPoints()[this.dragging_index].getPosition(),
							[this.dragging_index + 1]: this.link.getPoints()[this.dragging_index + 1].getPosition()
						};
						_points[this.dragging_index][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1]['y'] = rightPort.getPosition().y + rightPort.getPortRadius();
						this.link.getPoints()[this.dragging_index].setPosition(_points[this.dragging_index]);
						this.link.getPoints()[this.dragging_index + 1].setPosition(_points[this.dragging_index + 1]);
				
					} else if (sourceOrTarget === 'target') {
						_points[this.dragging_index][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1][this.draggingCoordinate] = e[this.draggingCoordinate];
						_points[this.dragging_index + 1]['y'] = rightPort.getPosition().y + rightPort.getPortRadius();
						this.link.getPoints()[this.dragging_index].setPosition(_points[this.dragging_index]);
						this.link.getPoints()[this.dragging_index + 1].setPosition(_points[this.dragging_index + 1]);
	
					}
					this.dragging_index = (sourceOrTarget === 'source') ? 1 : this.dragging_index
					if (sourceOrTarget === 'target') {
						this.link.setTargetPort(rightPort)
					} else {
						this.link.setSourcePort(rightPort)
					}
					rightPort.reportPosition()
					this.overflow = undefined

					break;


				default: 
					this.dragging_line = this.getDraggingLineType(this.dragging_index)
					let suplement = 0
					let delta:any = {
						x: {
							position: 0,
							sign: 1,
						},
						y: {
							position: 0,
							sign: 1,
						}
					}
					// add virtual por immediately when dragging connected lines
					// port reference changes when overflow
					if (this.dragging_index === 0) {
						// console.log('this.affectedPortAlignment', this.affectedPortAlignment)
						const portReference = this.source.portByAlignment(this.affectedPortAlignment)
						const currentDelta:number = e[this.draggingCoordinate] - (portReference.getPosition()[this.draggingCoordinate] + portReference.getPortRadius())
						delta[this.draggingCoordinate].sign = (currentDelta >= 0) ? 1 : -1
						delta[this.draggingCoordinate].position = Math.abs(currentDelta)
						suplement = this.source.linkSuplement(portReference, this.draggingCoordinate, delta[this.draggingCoordinate].position )
						if (delta[this.draggingCoordinate].position <= portReference.getPortRadius() * MODEL_DEFAULTS.MIN_PORT_RANGE_RATIO) {
							suplement = 0
							this.sourcePort.setOffset(this.draggingOppositeCoordinate, 0)
						}
						if (delta[this.draggingCoordinate].position >= this.source.width / 4) {
							// console.log('switch')
						}
						_points[this.dragging_index][this.draggingOppositeCoordinate] = portReference.getPosition()[this.draggingOppositeCoordinate] + this.sourcePort.getPortRadius() + suplement
					} else if (this.dragging_index === this.link.getPoints().length - 2) {
						const portReference = this.target.portByAlignment(this.affectedPortAlignment)
						const currentDelta:number = e[this.draggingCoordinate] - (portReference.getPosition()[this.draggingCoordinate] + portReference.getPortRadius())
						delta[this.draggingCoordinate].sign = (currentDelta >= 0) ? 1 : -1
						delta[this.draggingCoordinate].position = Math.abs(currentDelta)
						if (delta[this.draggingCoordinate].position <= portReference.getPortRadius() * MODEL_DEFAULTS.MIN_PORT_RANGE_RATIO) {
							suplement = 0
							this.targetPort.setOffset(this.draggingOppositeCoordinate, 0)
						}
						if (delta[this.draggingCoordinate].position >= this.target.width / 4) {
							// if (delta[this.draggingCoordinate].sign === 1) {
							// 	console.log('clockwise')
							// } else {
							// 	console.log('counter clockwise')
							// }
						} else if (delta[this.draggingCoordinate].position > 0 && delta[this.draggingCoordinate].position < this.target.width / 4) {
							// console.log('default')
						}
						suplement = this.target.linkSuplement(portReference, this.draggingCoordinate, delta[this.draggingCoordinate].position )
						// console.log('suplement', suplement, portReference, this.draggingCoordinate, delta[this.draggingCoordinate].position)
						_points[this.dragging_index + 1][this.draggingOppositeCoordinate] = portReference.getPosition()[this.draggingOppositeCoordinate] + this.sourcePort.getPortRadius() + suplement
					}
					// console.log('this.targetPort offset', this.targetPort.shapeOffsetX, this.targetPort.shapeOffsetY)
					// console.log('this.sourcePort offset', this.sourcePort.shapeOffsetX, this.sourcePort.shapeOffsetY)
					_points[this.dragging_index][this.draggingCoordinate] = e[this.draggingCoordinate];
					_points[this.dragging_index + 1][this.draggingCoordinate] = e[this.draggingCoordinate];

					// console.log('points', this.draggingCoordinate, _points[this.dragging_index].x, _points[this.dragging_index].y)
					// console.log('points', this.draggingCoordinate, _points[this.dragging_index + 1].x, _points[this.dragging_index + 1].y)
					this.link.getPoints()[this.dragging_index].setPosition(_points[this.dragging_index]);
					this.link.getPoints()[this.dragging_index + 1].setPosition(_points[this.dragging_index + 1]);
				}
		}
		this.engine.repaintCanvas();
		return
	}	

}
