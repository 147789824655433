import { Action, ActionEvent, InputType, State, CanvasEngine, BaseModel, AbstractDisplacementState, AbstractDisplacementStateEvent, BasePositionModel } from '../../canvas-core';
import { LaneNodeModel, PortModel, LinkModel, DiagramEngine, PortModelAlignment, NodeModelGenerics, PointModel  } from '../../diagrams-core';
import { MouseEvent, KeyboardEvent, DOMElement } from 'react';
import { BaseNodeModel, BaseNodeModelGenerics } from '../../models/BaseNode/BaseNodeModel';
import { Point, TPoint } from '../../geometry';
import { BaseNodePortModel } from '../../models/BaseNode/BaseNodePortModel';
import { LinkPathOrientation } from '../../diagrams-core/entities/link/RightAngleLinkModel';
import { getOrientation, RightAngleLinkModel } from '../../routing/link/RightAngleLinkModel';
import config from '../../routing/link/config'

import { TaskNodeModel as Task, TYPE as TaskType } from '../../models/Task/TaskNodeModel';
import { HandlerTaskNodeModel as HandlerTask, TYPE as HandlerTaskType } from '../../models/HandlerTask/TaskNodeModel';
import { GatewayNodeModel as Gateway, TYPE as GatewayType } from '../../models/Gateway/GatewayNodeModel';
import { EventNodeModel as Event, TYPE as EventType } from '../../models/Event/EventNodeModel';
import { StartEventMessageModel, TYPE as StartEventMessageType } from '../../models/StartEventMessage/StartEventMessageNodeModel';
import { EndEventMessageModel, TYPE as EndEventMessageType } from '../../models/EndEventMessage/EndEventMessageNodeModel';
import { IntermediateEventCatchMessageModel, TYPE as IntermediateEventCatchMessageType } from '../../models/IntermediateEventCatchMessage/IntermediateEventCatchMessageNodeModel';
import { IntermediateEventCatchTimerModel, TYPE as IntermediateEventCatchTimerType } from '../../models/IntermediateEventCatchTimer/IntermediateEventCatchTimerNodeModel';
import { IntermediateEventThrowMessageModel, TYPE as IntermediateEventThrowMessageType } from '../../models/IntermediateEventThrowMessage/IntermediateEventThrowMessageNodeModel';
import { TerminusEventNodeModel as TerminusEvent, TYPE as TerminusEventType } from '../../models/TerminusEvent/TerminusEventNodeModel';
import { WaitingForUserNodeModel as WaitingForUser, TYPE as WaitingForUserType } from '../../models/WaitingForUser/WaitingForUserNodeModel';
import { FunctionTaskNodeModel as FunctionTask, TYPE as FunctionTaskType } from '../../models/FunctionTask/FunctionTaskNodeModel';

const MIN_LINE_SIZE = config.MIN_LINE_SIZE;

const actionIconMap = {
	[TaskType]: { 
		entity: Task
	},
	[HandlerTaskType]: {
		entity: HandlerTask
	},
	[GatewayType]: {
		entity: Gateway
	},
	[EventType]: {
		entity: Event
	},
	[StartEventMessageType]: {
		entity: StartEventMessageModel,
	},
	[EndEventMessageType]: {
		entity: EndEventMessageModel,
	},
	[IntermediateEventCatchMessageType]: {
		entity: IntermediateEventCatchMessageModel,
	},
	[IntermediateEventCatchTimerType]: {
		entity: IntermediateEventCatchTimerModel,
	},
	[IntermediateEventThrowMessageType]: {
		entity: IntermediateEventThrowMessageModel,
	},
	[TerminusEventType]: {
		entity: TerminusEvent
	},
	[WaitingForUserType]: {
		entity: WaitingForUser
	},
	[FunctionTaskType]: {
		entity: FunctionTask
	},
	'lane-node': {
		entity: LaneNodeModel,
		ignoreLink: true,
		layer: 'lanes'
	},
}

const calcPoints = (fromMid: Point, toMid: Point, from: BaseNodeModel, to: BaseNodeModel | Point) => {
	if (Math.abs(fromMid.x - toMid.x) > Math.abs(fromMid.y - toMid.y)) {
		// If horizontal distance is greater than vertical distance
		return calcDefaultPointsAccordingToMainAxis('x', from, to, fromMid, toMid);
	} else {
		return calcDefaultPointsAccordingToMainAxis('y', from, to, fromMid, toMid);
	}	
}


const calcDefaultPointsAccordingToMainAxis = (
  mainAxis: 'x' | 'y',
  from: BaseNodeModel,
  to: BaseNodeModel | Point,
  fromMid: Point,
  toMid: Point
): Array<Point> => {
  const crossAxis = mainAxis === 'x' ? 'y' : 'x';
  const mainDimension = mainAxis === 'x' ? 'width' : 'height';
  const crossDimension = mainAxis === 'x' ? 'height' : 'width';

  const fromEdge = new Point({
    ...fromMid,
    [mainAxis]: (fromMid[mainAxis] <= toMid[mainAxis]) ? fromMid[mainAxis] + (from[mainDimension] / 2) : fromMid[mainAxis] - (from[mainDimension] / 2)
  })
	let toPos: TPoint

	if (to instanceof Point) {
		toPos = {
			x: to.x,
			y: to.y,
		} 
	} else {
		toPos = {
			x: to.getPosition().x,
			y: to.getPosition().y
		}
	}
  if (
    from[crossAxis] + from[crossDimension] > toPos[crossAxis] &&
    from[crossAxis] < toPos[crossAxis] + toPos[crossDimension]
  ) {

    // If From and To are too close in the crossAxis
    const distance = (fromMid[mainAxis] + toMid[mainAxis]) / 2;
		
		const pos: TPoint = {
      [mainAxis]: distance,
      [crossAxis]: fromMid[crossAxis],
		}

    const midPntAlpha: Point = new Point(pos);

    const midPntBeta: Point = new Point({
      [mainAxis]: distance,
      [crossAxis]: toMid[crossAxis],
    });

    const lastPnt: Point = new Point({
      [mainAxis]:
        from[mainAxis] > toPos[mainAxis]
          ? toPos[mainAxis] + toPos[mainDimension]
          : toPos[mainAxis],
      [crossAxis]: toMid[crossAxis],
    });

    return [fromEdge, midPntAlpha, midPntBeta, lastPnt];
  } else {

    const midPoint: Point = new Point({
      [mainAxis]: toMid[mainAxis],
      [crossAxis]: fromMid[crossAxis],
    });

    const lastPnt: Point = new Point({
      [mainAxis]: toMid[mainAxis],
      [crossAxis]:
        from[crossAxis] > toPos[crossAxis]
          ? toPos[crossAxis] + toPos[crossDimension]
          : toPos[crossAxis],
    });

    return [fromEdge, midPoint, lastPnt];
  }
};

/**
 * This state is controlling the creation of a link.
 */
export class CreateLinkWithEntityState extends AbstractDisplacementState<DiagramEngine> {
  sourcePort: PortModel;
  targetPort: PortModel;
	link: RightAngleLinkModel;
  portLocation: string;
	isLinking: boolean;
	intentOn: PortModel;
	actionIcon: any;
	linkingSource: PortModel;
	sourceModel: BaseNodeModel;
	targetModel: BaseNodeModel | LaneNodeModel;
	ignoreLink: boolean;

	clickedIcon(event: ActionEvent<MouseEvent>):any {
		 const cdata:any = ((event.event.target) as HTMLElement).dataset
		 return (cdata.menuid) ? cdata : null
	}
	updateLinkToPort(port: BaseNodePortModel) {
		const lastLine = this.link.lastLine()
		const direction = RightAngleLinkModel.lineDirection(lastLine[0], lastLine[1])
		const portAligment = port.getOptions().alignment
		const portRadius = port.getPortRadius()
		switch (portAligment) {
			case PortModelAlignment.TOP:
				if (direction === LinkPathOrientation.HORIZONTAL) {
					const targetPortPosition = port.getPosition()
					const newPointIndex = this.link.getPoints().length - 2
					
					const refPoint = this.link.getPoints()[newPointIndex]
					this.link.getPoints()[newPointIndex].setPosition(refPoint.getX(), targetPortPosition.y - MIN_LINE_SIZE)
					this.link.addPoint(
						new PointModel({
							link: this.link,
							position: new Point(targetPortPosition.x + portRadius, targetPortPosition.y - MIN_LINE_SIZE)
						}),
						newPointIndex + 1
					);

				}
				break;
			case PortModelAlignment.BOTTOM:
				if (direction === LinkPathOrientation.HORIZONTAL) {
					const targetPortPosition = port.getPosition()
					const newPointIndex = this.link.getPoints().length - 2
					const refPoint = this.link.getPoints()[newPointIndex]
					this.link.getPoints()[newPointIndex].setPosition(refPoint.getX(), targetPortPosition.y + (portRadius * 2) + MIN_LINE_SIZE)
					this.link.addPoint(
						new PointModel({
							link: this.link,
							position: new Point(targetPortPosition.x + portRadius, targetPortPosition.y + (portRadius * 2) + MIN_LINE_SIZE)
						}),
						newPointIndex + 1
					);

				}
				break;
			case PortModelAlignment.LEFT:
				if (direction === LinkPathOrientation.VERTICAL) {
					const targetPortPosition = port.getPosition()
					const newPointIndex = this.link.getPoints().length - 2
					const refPoint = this.link.getPoints()[newPointIndex]
					this.link.getPoints()[newPointIndex].setPosition(targetPortPosition.x - MIN_LINE_SIZE, refPoint.getY())
					this.link.addPoint(
						new PointModel({
							link: this.link,
							position: new Point(targetPortPosition.x - MIN_LINE_SIZE, targetPortPosition.y + portRadius)
						}),
						newPointIndex + 1
					);
				}
				break;
			case PortModelAlignment.RIGHT:
				if (direction === LinkPathOrientation.VERTICAL) {
					const targetPortPosition = port.getPosition()
					const newPointIndex = this.link.getPoints().length - 2
					const refPoint = this.link.getPoints()[newPointIndex]
					this.link.getPoints()[newPointIndex].setPosition(targetPortPosition.x + MIN_LINE_SIZE + (portRadius * 2), refPoint.getY())
					this.link.addPoint(
						new PointModel({
							link: this.link,
							position: new Point(targetPortPosition.x + MIN_LINE_SIZE + (portRadius * 2), targetPortPosition.y + portRadius)
						}),
						newPointIndex + 1
					);
				}
				break;
			default:
		}
		this.link.setTargetPort(port, true);

	}


	constructor() {
    super({ name: 'create-new-link-with-entity' });
		this.registerAction(
			new Action({
				type: InputType.MOUSE_DOWN,
				fire: (event: ActionEvent<MouseEvent, PortModel>) => {
					const actionIcon = this.clickedIcon(event)
          if (actionIcon) {

						if (!this.actionIcon) {
							this.engine.getModel().clearSelection();

							const {
								event: { clientX, clientY }
							} = event;
							const ox = this.engine.getModel().getOffsetX();
							const oy = this.engine.getModel().getOffsetY();
		
							this.actionIcon = actionIcon.menuid;
							this.sourceModel = this.engine.getModel().getNode(actionIcon.modelid) as BaseNodeModel
							this.intentOn = this.sourceModel.rightPort
							const entityEntry = actionIconMap[this.actionIcon]
							if (!entityEntry) {
								const message = `${this.actionIcon} is not defined on actionMap or tools`
								console.log(message)
								throw message;
							}
							try {
								this.targetModel = new entityEntry.entity({ label: '' })
							} catch (err) {
								const message = `class ${entityEntry.entity} does not exists or was not imported`;
								console.log(message)
								throw message;
							}
							this.ignoreLink = Boolean(actionIconMap[this.actionIcon].ignoreLink)
							// this.engine.getModel().addNode(this.targetModel)
							if (actionIconMap[this.actionIcon].layer && actionIconMap[this.actionIcon].layer === 'lanes') {
								this.engine.getModel().addLane((this.targetModel as LaneNodeModel))
							} else {
								this.engine.getModel().addNode(this.targetModel as BaseNodeModel)
							}
							const point = this.engine.getRelativeMousePoint(event.event)
							this.targetModel.placeFromActionIcon(point.x, point.y)

							if (!this.ignoreLink) {
								this.sourcePort = this.sourceModel.leftPort
								const link = this.sourcePort.createLinkModel();
								const portRadius = (<BaseNodePortModel> this.sourcePort).getPortRadius();
								link.setSourcePort(this.sourcePort);
								const centerPoint = this.sourcePort.getCenter()
								link.getFirstPoint().setPosition(centerPoint.x, centerPoint.y);
								link.getPoints()[1].setPosition(clientX - ox + (portRadius * 2), clientY - oy + (portRadius * 2));
								link.getLastPoint().setPosition(clientX - ox + (portRadius * 2), clientY - oy + (portRadius * 2));
								const node = (this.targetModel);
								this.link = (this.engine.getModel().addLink(link) as RightAngleLinkModel);
							} else {
								this.clearState()
								return
							}

						}
						// return
          }
				}
			})
		);
		this.registerAction(
			new Action({
				type: InputType.MOUSE_UP,
				fire: (actionEvent: ActionEvent<MouseEvent>) => {
					// console.log('InputType.MOUSE_UP actionEvent', this)
					const element = this.engine.getActionEventBus().getModelForEvent(actionEvent);
					if (this.actionIcon && !this.isLinking) {
						this.isLinking = true
					} else if (this.actionIcon && this.isLinking && this.targetModel instanceof BaseNodeModel) {
						if (!this.ignoreLink) {
							const finalPort = this.targetModel.portFinderForLink(this.link)
							const portPosition = finalPort.getPosition()
							const points = this.link.getPoints()
							const lineDirection = LinkModel.lineDirection(points[points.length - 2], points[points.length -1])
							if (lineDirection === LinkPathOrientation.HORIZONTAL) {
								points[points.length - 2].setPosition(points[1].getX(), portPosition.y + finalPort.getPortRadius())
							} else {
								points[points.length - 2].setPosition(portPosition.x+ finalPort.getPortRadius(), points[1].getY())
							}
							this.link.getLastPoint().setPosition(
								portPosition.x + finalPort.getPortRadius(),
								portPosition.y + finalPort.getPortRadius(),
							)
							this.link.setTargetPort(finalPort)
							const container  = this.engine.getActionEventBus().getContainerForEvent(actionEvent) as LaneNodeModel; 
							if (container && (container instanceof LaneNodeModel)) {
								(container as LaneNodeModel).addEntity(element.getID());
								(element as BaseNodeModel).setContainer(container.getID());
							}
						}
						this.clearState()
					}
					const {
						event: { clientX, clientY }
          } = actionEvent;
					const ox = this.engine.getModel().getOffsetX();
					const oy = this.engine.getModel().getOffsetY();

					
					if (this.linkingSource && this.linkingSource === element) {
						return;
					}
					
					if (this.isLinking && this.link && element === this.link.getLastPoint()) {
						this.link.point(clientX - ox, clientY - oy, -1);
						this.targetModel.placeFromActionIcon(clientX - ox, clientY - oy)						
          } else if (!this.isLinking) {
						this.clearState();
					}
					this.engine.repaintCanvas();
				}
			})
		);

		this.registerAction(
			new Action({
				type: InputType.KEY_UP,
				fire: (actionEvent: ActionEvent<KeyboardEvent>) => {
					// on esc press remove any started link and pop back to default state
					if (actionEvent.event.keyCode === 27) {
						this.link.remove();
						this.targetModel.remove();
						this.clearState();
						this.eject();
						this.engine.repaintCanvas();
					}
				}
			})
    );


		// this.registerAction(
		// 	new Action({
		// 		type: InputType.KEY_UP,
		// 		fire: (actionEvent: ActionEvent<KeyboardEvent>) => {
		// 			// on esc press remove any started link and pop back to default state
		// 			if (actionEvent.event.keyCode === 27) {
		// 				if (this.link) {
		// 					this.link.remove();
		// 					this.targetModel.remove()
		// 				}
		// 				this.clearState();
		// 				this.eject();
		// 				this.engine.repaintCanvas();
		// 			}
		// 		}
		// 	})
    // );
  }

	clearState() {
		this.actionIcon = undefined;
		this.intentOn = undefined;
		this.isLinking = undefined;
		this.linkingSource = undefined;
		this.link = undefined;
		this.sourcePort = undefined;
		this.portLocation = undefined;
  }
	fireMouseMoved(event: AbstractDisplacementStateEvent): any {
		if (!this.isLinking) return
		if (!this.sourcePort) return

		// we need to update this.sourcePort
		const container  = this.engine.getActionEventBus().getContainerForEvent(event) as LaneNodeModel; 

		const engineModel = this.engine.getModel();
		const portPos = this.sourcePort.getPosition();
		const zoomLevelPercentage = this.engine.getModel().getZoomLevel() / 100;
		const engineOffsetX = this.engine.getModel().getOffsetX() / zoomLevelPercentage;
		const engineOffsetY = this.engine.getModel().getOffsetY() / zoomLevelPercentage;
		const initialXRelative = this.initialXRelative / zoomLevelPercentage;
		const initialYRelative = this.initialYRelative / zoomLevelPercentage;

		// const x = engineModel.getGridPosition(pos.x + event.virtualDisplacementX);
		// const y = engineModel.getGridPosition(pos.y + event.virtualDisplacementY);

		const linkNextX = engineModel.getGridPosition(portPos.x - engineOffsetX + (initialXRelative - portPos.x) + event.virtualDisplacementX);
		const linkNextY = engineModel.getGridPosition(portPos.y - engineOffsetY + (initialYRelative - portPos.y) + event.virtualDisplacementY);

		// const linkNextX = portPos.x - engineOffsetX + (initialXRelative - portPos.x) + event.virtualDisplacementX;
		// const linkNextY = portPos.y - engineOffsetY + (initialYRelative - portPos.y) + event.virtualDisplacementY;
		const posPoint = new Point({
			x: linkNextX,
			y: linkNextY,
		})
		const model = this.sourcePort.getParent() as BaseNodeModel
		if (!this.ignoreLink) {
			const newLink = LinkModel.calcLinkPoints(model, posPoint)
			if (newLink.length > this.link.getPoints().length) {
				const lastPoint = newLink[newLink.length - 1]
				const newPoint = new PointModel({
					link: this.link,
					position: new Point(lastPoint.x, lastPoint.y)
				})
				this.link.addPoint(newPoint, newLink.length - 1)
			}
			if (this.link.getPoints().length > newLink.length) {
				this.link.removePoint(this.link.getLastPoint())
			}
			this.link.getPoints().map((point, index) => {
				point.setPosition(newLink[index].x, newLink[index].y)
			})
		}
		// this.sourcePort = this.link.getPortForPoint(this.link.getPoints()[1])
		const newPortAlignment = this.sourceModel.portAlignmentFinder(this.link.getPoints()[1].getX(), this.link.getPoints()[1].getY())
		this.sourcePort.removeLink(this.link)
		this.sourcePort = this.sourceModel.portByAlignment(newPortAlignment)
		this.link.setSourcePort(this.sourceModel.portByAlignment(newPortAlignment))
		this.sourcePort.addLink(this.link)
		this.sourcePort.reportPosition()
		// check container

		this.targetModel.place(linkNextX, linkNextY)
		this.engine.repaintCanvas();
	}	

}
