import {
	DefaultDiagramState,
	DiagramEngine,
	LinkLayerFactory,
	LanesLayerFactory,
	NodeLayerFactory,
	GridLayerFactory,
	AppLayerFactory
} from '../diagrams-core';
import {
	DefaultLabelFactory,
	DefaultLinkFactory,
	DefaultNodeFactory,
	DefaultPortFactory
} from '../diagrams-defaults';
import { SelectionBoxLayerFactory, CanvasEngineOptions, EditingLabelLayerFactory } from '../canvas-core';

export * from '../diagrams-core';
export * from '../diagrams-defaults';
export * from '../diagrams-routing';

/**
 * Construct an engine with the defaults installed
 */
export default (options: CanvasEngineOptions = {}): DiagramEngine => {
	const engine = new DiagramEngine(options);

	// register model factories
	engine.getLayerFactories().registerFactory(new NodeLayerFactory());
	engine.getLayerFactories().registerFactory(new LanesLayerFactory());
	engine.getLayerFactories().registerFactory(new LinkLayerFactory());
	engine.getLayerFactories().registerFactory(new SelectionBoxLayerFactory());
	engine.getLayerFactories().registerFactory(new EditingLabelLayerFactory());
	engine.getLayerFactories().registerFactory(new GridLayerFactory());
	engine.getLayerFactories().registerFactory(new AppLayerFactory());

	engine.getLabelFactories().registerFactory(new DefaultLabelFactory());
	engine.getNodeFactories().registerFactory(new DefaultNodeFactory());
	engine.getLinkFactories().registerFactory(new DefaultLinkFactory());
	engine.getPortFactories().registerFactory(new DefaultPortFactory());

	// register the default interaction behaviours
	engine.getStateMachine().pushState(new DefaultDiagramState());
	return engine;
};
