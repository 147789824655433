import { Action, ActionEvent, InputType } from '../core-actions/Action';
import { BaseNodeModel } from '../../models/BaseNode/BaseNodeModel';
import { AbstractDisplacementState, AbstractDisplacementStateEvent } from '../core-state/AbstractDisplacementState';
import { State } from '../core-state/State';
import { EditingLayerModel } from '../entities/editing/EditingLayerModel';
import { EditLabelState } from './EditLabelState';
import { DOMElement, KeyboardEvent } from 'react';
import { BaseModel } from '../core-models/BaseModel';
import { NodeModel, PortModel } from '../../diagrams-core';

export class EditingLabelState extends State {
	layer: EditingLayerModel;

	constructor(event: any) {
		// console.log('editing!', event.virtualDisplacementX, event.virtualDisplacementY)
		super({
			name: 'editing-label'
		});
		this.registerAction(
      new Action({
        type: InputType.KEY_DOWN,
        fire: (event: ActionEvent<KeyboardEvent>) => {
					if (event.event.key === 'Enter') {
						const input = (event.event.target as Element)
						const model = (this.engine.getModel().getModels().find((model) => model.getID() === input.getAttribute('name')) as BaseNodeModel)
						model.label = input.getAttribute('value')
						this.eject()
						this.engine.repaintCanvas();
					}
					if (event.event.key === 'Escape') {
						this.eject()
						this.engine.repaintCanvas();
					}
				}
      })
    );
		this.registerAction(
      new Action({
        type: InputType.MOUSE_UP,
        fire: (event: ActionEvent<KeyboardEvent>) => {
					const element = (event.event.target as Element)
					const target = element.getAttribute('data-button')
					if (target && target === 'ok') {
						const model = (this.engine.getModel().getModels().find((model) => model.getID() === element.getAttribute('data-id')) as BaseNodeModel)
						const input = window.document.querySelector(`input[name='${element.getAttribute('data-id')}']`) as Element
						model.label = input.getAttribute('value')
						this.eject()
						this.engine.repaintCanvas();
					} else if (target && target === 'cancel') {
						const model = (this.engine.getModel().getModels().find((model) => model.getID() === element.getAttribute('data-id')) as BaseNodeModel)
						this.eject();
						this.engine.repaintCanvas();
					}
				}
      })
    );

	}

	activated(previous: State) {
		const element = (previous as EditLabelState).element
		if (element) {

			super.activated(previous);
			this.layer = new EditingLayerModel();
			this.layer.setModel(element as BaseNodeModel)
			// ugly hack here due to class inheritance + typescript + instanceof
			// if (this.layer.getModel().setEditing) {
				this.layer.getModel().setEditing(true)
				this.engine.getModel().addLayer(this.layer);			
			// }
		}
	}

	deactivated(next: State) {
		super.deactivated(next);
		this.layer.remove();
		const element = this.layer.getModel();
		if (element) {
			element.setEditing(false)
		}
		this.engine.repaintCanvas();
	}

}
