import { BaseModelOptions } from '../../canvas-core';
import { BaseNodeModel } from '../BaseNode/BaseNodeModel'

export const TYPE = 'task-node'

export interface TaskNodeModelGenerics {
}
export interface TaskNodeModelOptions extends BaseModelOptions {
	label?: string;
	width?: number;
	height?: number;
}

export class TaskNodeModel extends BaseNodeModel {
	label: string;

	constructor(options: TaskNodeModelOptions) {
		super({
			type: TYPE,
			width: 100,
			height: 60,
			...options
		});
		this.label = options.label;
	}
}
