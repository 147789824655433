import * as React from 'react';
import { AbstractReactFactory, GenerateWidgetEvent } from '../../core/AbstractReactFactory';
import { EditingLayerModel } from './EditingLayerModel';
import { GenerateModelEvent } from '../../core/AbstractModelFactory';
import { EditingWidget } from './EditingWidget';
import { BaseNodeModel } from '../../../models/BaseNode/BaseNodeModel';

export class EditingLabelLayerFactory extends AbstractReactFactory<EditingLayerModel> {
	constructor() {
		super('editing');
	}

	generateModel(event: GenerateModelEvent): EditingLayerModel {
		return new EditingLayerModel();
	}

	generateReactWidget(event: GenerateWidgetEvent<EditingLayerModel>): JSX.Element {
		return <EditingWidget model={event.model.model} engine={this.engine} />;
	}
}
