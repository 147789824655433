import { BaseModelOptions } from '../../canvas-core';
import { BaseNodeModel } from '../BaseNode/BaseNodeModel'

export const TYPE = 'function-task-node';

export interface FunctionTaskNodeModelGenerics {
}
export interface FunctionTaskNodeModelOptions extends BaseModelOptions {
	color?: string;
	label?: string;
	width?: number;
	height?: number;
}

export class FunctionTaskNodeModel extends BaseNodeModel {
	color: string;
	label: string;

	constructor(options: FunctionTaskNodeModelOptions) {
		super({
			type: TYPE,
			width: 200,
			height: 60,
		});
		this.label = options.label;
	}
}
