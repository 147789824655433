import * as React from 'react';
import { EventNodeModel, TYPE } from './EventNodeModel';
import { Event } from './EventNodeWidget';
import { AbstractReactFactory } from '../../canvas-core';
import { DiagramEngine } from '../../diagrams-core';


export class EventNodeFactory extends AbstractReactFactory<EventNodeModel, DiagramEngine> {
	constructor() {
		super(TYPE);
	}

	generateModel(initialConfig) {
		return new EventNodeModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <Event engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
