import { BaseEntityOptions, BaseModelOptions, BasePositionModel } from '../../../canvas-core';
import { NodeModel, NodeModelGenerics, NodeModelListener } from '../../../diagrams-core/entities/node/NodeModel';
import { DiagramModel } from '../../models/DiagramModel';
import { Point, Rectangle } from '../../../geometry';

import { BaseNodeModel, BaseNodeModelGenerics } from '../../../models/BaseNode/BaseNodeModel'
// import { NodeModel, LinkModel, PortModel, DefaultPortModel, NodeModelGenerics, PortModelAlignment, PointModel } from '../../diagrams-engine';

export interface LaneNodeModelGenerics {
	OPTIONS: BaseModelOptions;
	LISTENER: NodeModelListener;
	PARENT: DiagramModel;
}


export interface LaneModelOptions extends BaseModelOptions {
	label: string;
	width?: number;
	height?: number;
	minWidth?: number;
	minHeight?: number;
	editing?: boolean;
}

export class LaneNodeModel<G extends LaneNodeModelGenerics = LaneNodeModelGenerics> extends BasePositionModel<G> {
	color: string;
	label: string;
	containedIds: Set<string>;
	canLink: boolean;
	container: boolean;
	width: number;
	height: number;
	editing: boolean;

	// constructor(options: G['OPTIONS']) {
	
	constructor(options: LaneModelOptions) {
		super({
			type: 'lane-node',

			minWidth: 400,
			minHeight: 100,
			label: 'lane',	
			...options
		});
		this.width = 600
		this.height = 200
		this.container = true;
		this.containedIds = new Set();
		this.label = options.label;
		this.canLink = false;
		this.editing = options.editing;
	}

	serialize() {
		return {
			...super.serialize(),
			width: this.width,
			height: this.height,
			label: this.label,
			containedIds: Array.from(this.containedIds),
		};
	}

	deserialize(event): void {
		super.deserialize(event);
		this.width = event.data.width;
		this.height = event.data.height;
		this.containedIds = new Set(event.data.containedIds);
		this.label = event.data.label;
		this.container = true;
		this.canLink = false;
	}

	getBoundingBox(): Rectangle {
		return new Rectangle(this.getPosition(), this.width, this.height);
	}

	addEntity(id: string) {
		this.containedIds.add(id)
	}
	removeEntity(id: string) {
		this.containedIds.delete(id)
	}
	setPosition(point: Point);
	setPosition(x: number, y: number);
	setPosition(x, y?) {
		let old = this.position;
		super.setPosition(x, y);
	}

	setEditing(editing: boolean = true) {
		if (this.editing !== editing) {
			this.editing = editing;
			this.fireEvent(
				{
					isEditing: editing
				},
				'editingChanged'
			);
		}
	}

	place(x: number, y: number) {
		// super.setPosition(x , y );
		let old = this.position;
		// console.log('place', x, y, this.width, this.height)
		super.setPosition(x - (this.width / 2) , y - (this.height / 2));
	}
	updateDimensions({ width, height }: { width: number; height: number }) {
		this.width = width;
		this.height = height;
	}

	placeFromActionIcon(x: number, y: number) {
		// console.log('placeFromActionIcon', x, y)
		// super.setPosition(x , y );
		super.setPosition(x - (this.width / 4) , y - (this.height / 2));
	}
	
}
