import { LayerModel, LayerModelGenerics } from '../../../canvas-core';
import { DiagramEngine } from '../../../diagrams-core';

export interface GridLayerModelGenerics extends LayerModelGenerics {
  ENGINE: DiagramEngine;
}

export interface GridLayerModelOptions {
	size: number;
}

export class GridLayerModel<G extends GridLayerModelGenerics = GridLayerModelGenerics> extends LayerModel {
	size: number;
	offsetX: number;
	offsetY: number;
	xFactor: number;
	yFactor: number;

  constructor(options: GridLayerModelOptions) {
		super({
			type: 'diagram-grid',
			isSvg: false,
			transformed: true
    });
		this.size = options.size;
		this.offsetX = 0;
		this.offsetY = 0;
		this.xFactor = 1;
		this.yFactor = 1;
	}
	setOffset(x:number, y:number, xFactor: number, yFactor: number):void {
		this.offsetX = x;
		this.offsetY = y;
		this.xFactor = xFactor;
		this.yFactor = yFactor;
	}

	getChildModelFactoryBank(engine: G['ENGINE']) {
		return engine.getNodeFactories();
	}

}
