import * as React from 'react';
import { IntermediateEventCatchTimerModel, TYPE } from './IntermediateEventCatchTimerNodeModel';
import { StartEventMessageNodeWidget } from './IntermediateEventCatchTimerNodeWidget';
import { AbstractReactFactory } from '../../canvas-core';
import { DiagramEngine } from '../../diagrams-core';


export class IntermediateEventCatchTimerNodeFactory extends AbstractReactFactory<IntermediateEventCatchTimerModel, DiagramEngine> {
	constructor() {
		super(TYPE);
	}

	generateModel(initialConfig) {
		return new IntermediateEventCatchTimerModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <StartEventMessageNodeWidget engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
