import * as React from 'react';
import { WaitingForUserNodeModel, TYPE } from './WaitingForUserNodeModel';
import { Task } from './WaitingForUserWidget';
import { AbstractReactFactory } from '../../canvas-core';
import { DiagramEngine } from '../../diagrams-core';

export class WaitingForUserNodeFactory extends AbstractReactFactory<WaitingForUserNodeModel, DiagramEngine> {
	constructor() {
		super(TYPE);
	}

	generateModel(initialConfig) {
		return new WaitingForUserNodeModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <Task engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
