import * as React from 'react';
import styled from '@emotion/styled';
import { DiagramEngine } from '../../diagrams-core';
import BasePortWidget from '../Port/BasePortWidget'
import { GatewayNodeModel } from './GatewayNodeModel';
import { Tools, defaultActions } from '../../components/Tools';
import { CreateLinkState } from '../../state/Link/CreateLinkState';
import MODEL_DEFAULTS from '../BaseNode/constants'

export const DEFAULTS = {
  HEIGHT: 60,
  WIDTH: 60,
  PORT_RADIUS: 10,
  FONT_SIZE: 0.8,
}

namespace S {
  export const Diamond = styled.div<{ editing?: boolean; isLinking?: boolean;width?: number; height?: number; color?: string; background?: string }>`
    position: relative;
    width: ${props => props.width || DEFAULTS.WIDTH}px;
    height: ${props => props.height || DEFAULTS.HEIGHT}px;
    outline: ${props => props.editing ? '5px solid rgba(224, 0, 255, 0.5)' : ''};

    &:hover {
      outline: 5px solid rgba(255, 252, 0, 0.5);
		}
  `
    //    box-shadow: 0 0 2px #333;


  export const Gateway = styled.div<{ editing?: boolean; isLinking?: boolean;width?: number; height?: number; color?: string; background?: string }>`
    background-color: ${p => p.background || MODEL_DEFAULTS.backgoundColor};
    display: flex;
    width: ${props => props.width || DEFAULTS.WIDTH}px;
    height: ${props => props.height || DEFAULTS.HEIGHT}px;
    border-radius: 4px;
    border-color: #aaa #aaa #aaa #aaa;

    justify-content: center;
    align-items: center;
    &:hover {
      outline: 5px solid rgba(255, 252, 0, 0.5);
		}
  `;
  export const GatewayLabel = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    font-size: ${DEFAULTS.FONT_SIZE}rem;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  `;

}

export interface GatewayWidgetProps {
	node: GatewayNodeModel;
  engine: DiagramEngine;
  height?: number,
  width?: number,
}

export interface GatewayWidgetState {
  over: boolean
  editing: boolean
}

export class Gateway extends React.Component<GatewayWidgetProps, GatewayWidgetState> {
	constructor(props: GatewayWidgetProps) {
		super(props);
		this.state = {
      over: false,
      editing: false,
    };
	}

  handleEnter(th: any) {
    return (event: any) => {
      th.setState({
        ...this.state,
        over: true,
      })
    }
  }

  handleOut(th: any){ 
    return (event: any) => {
      th.setState({
        ...this.state,
        over: false,
      })
    }
  }

	render() {
    // console.log('render', this.props.node)
    const width = (this.props.width || DEFAULTS.WIDTH);
    const height = (this.props.height || DEFAULTS.HEIGHT);
    const selected = this.props.node.getOptions().selected;
    const state = this.props.engine.getStateMachine().getCurrentState();
    const ports = this.props.node.getPorts()
    // if (state instanceof CreateLinkState) {
    //   console.log('CreateLinkState')
    // } else if (state instanceof DefaultState) {
    //   console.log('DefaultState')
    // } else if (state instanceof DragLinkState) {
    //   console.log('DragLinkState')
    // }
    const isLinking = (state instanceof CreateLinkState) ? state.isLinking : false;
    const isSelected = this.props.node.isSelected()
    const editing = this.props.node.editing;

		return (

      <S.Diamond
        isLinking={isLinking}
        onMouseEnter={this.handleEnter(this)}
        onMouseLeave={this.handleOut(this)}
        editing={editing}
      >
      <S.GatewayLabel>{this.props.node.label}</S.GatewayLabel>
      <svg
        width={width}
        height={height}
        dangerouslySetInnerHTML={{
          __html:
            `
        <g id="Layer_1">
        </g>
        <g id="Layer_2">
          <polygon fill="${MODEL_DEFAULTS.backgoundColor}" stroke="${
            isSelected ? MODEL_DEFAULTS.selectedColor : '#aaa'
          }" stroke-width="2" stroke-miterlimit="10" points="0,` +
            width / 2 +
            ` ` +
            width / 2 +
            `,0 ` +
            (width - 0) +
            `,` +
            width / 2 +
            ` ` +
            width / 2 +
            `,` +
            (width - 0) +
            ` "/>
        </g>
      `
        }}
      />

      {Object.keys(ports).map((id) =>{ 
        return (<BasePortWidget
          key={id}
          id={id}
          isLinking={isLinking}
          port={ports[id]}
          engine={this.props.engine}
          node={this.props.node}
          width={width}
          height={height}
          alignment={ports[id].getOptions().alignment}
        />
      )})}
        <Tools
          visible={this.state.over && !isLinking}
          actions={defaultActions}
          backgroundColor="rgba(239,239,239,20)"
          node={this.props.node}
          top={10}
          left={10}
        />


    </S.Diamond>
  );
	}
}

