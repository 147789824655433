import { Action, ActionEvent, InputType, State, BaseModel } from '../../canvas-core';
import { PortModel, LinkModel, DiagramEngine, NodeModel, PortModelAlignment, NodeModelGenerics  } from '../../diagrams-core';
import { MouseEvent } from 'react';
import { EditingLabelState } from './EditingLabelState';
import { BaseNodeModel } from '../../models/BaseNode/BaseNodeModel';
import { BaseNodePortModel } from '../../models/BaseNode/BaseNodePortModel';

export class EditLabelState extends State {
  isEditingLabel: boolean;
  element: BaseNodeModel;
  constructor(){
    super({ name: 'edit-label' });
    this.isEditingLabel = false;
    this.keys = ['alt'];
    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event: ActionEvent<MouseEvent>) => {
          // this.element = this.engine.getActionEventBus().getModelForEvent(event);
          this.element = this.engine.getActionEventBus().getModelForEvent(event) as BaseNodeModel;
          // go into a selection box on the canvas state
          if (!this.element) {
            this.engine.repaintCanvas();
          // ugly hack here due to class inheritance + typescript + instanceof
          } else if ((this.element.setEditing)) {
            this.transitionWithEvent(new EditingLabelState(event), event);
            this.engine.repaintCanvas();
          } else {
            this.engine.repaintCanvas();
          }
        }
      })
    );
    }
}

