import * as React from 'react';
import { useParams } from 'react-router-dom'
// import {  } from "react-router";

import backend from './app/backend'
import { BodyWidget } from './BodyWidget';
import createEngine, { DiagramModel, DefaultDiagramState } from './diagrams-engine';
import { RightAngleLinkFactory } from './routing/link/RightAngleLinkFactory';
import { DefaultState } from './state/Default/DefaultState';

import Control from './app/Control'
import Global from './ui/Global';
import { GatewayNodeFactory as GatewayFactory } from './models/Gateway/GatewayNodeFactory';
import { TaskNodeFactory as TaskFactory } from './models/Task/TaskNodeFactory';
import { HandlerTaskNodeFactory as HandlerTaskFactory } from './models/HandlerTask/TaskNodeFactory';
import { EventNodeFactory as EventFactory } from './models/Event/EventNodeFactory';
import { StartEventMessageNodeFactory as StartEventMessageFactory } from './models/StartEventMessage/StartEventMessageNodeFactory';
import { EndEventMessageNodeFactory as EndEventMessageFactory } from './models/EndEventMessage/EndEventMessageNodeFactory';
import { IntermediateEventCatchMessageNodeFactory as IntermediateEventCatchMessageFactory } from './models/IntermediateEventCatchMessage/IntermediateEventCatchMessageNodeFactory';
import { IntermediateEventCatchTimerNodeFactory as IntermediateEventCatchTimerFactory } from './models/IntermediateEventCatchTimer/IntermediateEventCatchTimerNodeFactory';
import { IntermediateEventThrowNodeFactory } from './models/IntermediateEventThrowMessage/IntermediateEventThrowMessageNodeFactory';
import { GRID_SIZE } from './diagrams-core/models/DiagramModel'
import { GridLayerFactory } from './diagrams-core/entities/grid/GridLayerFactory';
import { LaneNodeFactory } from './diagrams-core/entities/lane/LaneNodeFactory';
import { TerminusEventNodeFactory as TerminusEventFactory } from './models/TerminusEvent/TerminusEventNodeFactory';
import { WaitingForUserNodeFactory as WaitingForUserFactory } from './models/WaitingForUser/WaitingForUserNodeFactory';
import { FunctionTaskNodeFactory as FunctionTaskFactory } from './models/FunctionTask/FunctionTaskNodeFactory';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


// create an instance of the engine
const engine = createEngine();

const state = engine.getStateMachine().getCurrentState();
	if (state instanceof DefaultDiagramState) {
		state.dragNewLink.config.allowLooseLinks = false;
	}

// register the two engines
engine.getLayerFactories().registerFactory(new GridLayerFactory());

engine.getNodeFactories().registerFactory(new TaskFactory());
engine.getNodeFactories().registerFactory(new HandlerTaskFactory());
engine.getNodeFactories().registerFactory(new GatewayFactory())
engine.getNodeFactories().registerFactory(new EventFactory())
engine.getNodeFactories().registerFactory(new StartEventMessageFactory())
engine.getNodeFactories().registerFactory(new IntermediateEventCatchMessageFactory())
engine.getNodeFactories().registerFactory(new IntermediateEventThrowNodeFactory())
engine.getNodeFactories().registerFactory(new IntermediateEventCatchTimerFactory())
engine.getNodeFactories().registerFactory(new EndEventMessageFactory())
engine.getNodeFactories().registerFactory(new TerminusEventFactory())
engine.getNodeFactories().registerFactory(new WaitingForUserFactory())
engine.getNodeFactories().registerFactory(new FunctionTaskFactory())


engine.getLaneFactories().registerFactory(new LaneNodeFactory())
engine.getLinkFactories().registerFactory(new RightAngleLinkFactory());

// create a diagram model
const model = new DiagramModel();
model.setGridSize(GRID_SIZE);

engine.setModel(model);
engine.getStateMachine().pushState(new DefaultState());

interface iAppProps {
	params: any
}

class App extends React.Component<iAppProps> {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
		}
	}
	componentDidMount(): void {
		const { params } = this.props
		this.setState({loading: true});
		if (params.id) {
			backend.load(params.id).then((data) => {
				// console.log('diagram', diagram)
				const model2 = new DiagramModel();
				// @ts-ignore
				model2.deserializeModel(data.diagram, engine);
				engine.setModel(model2);
				this.setState({loading: false})
			})
	
		}
	}

	render() {
		// console.log('props', this.props)
		return (
      <>
        <Global />
			  <BodyWidget engine={engine} />
				<Control engine={engine} params={this.props.params} />
      </>
		);
	}
}

export default withParams(App)