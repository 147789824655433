import * as React from 'react';
import styled from '@emotion/styled';
import * as _ from 'lodash';
import { DiagramEngine } from '../../../diagrams-core';
import { LanesLayerModel } from './LanesLayerModel';
import { LaneNodeModel } from '../lane/LaneNodeModel'
import { Lane } from '../lane/LaneNodeWidget'

export interface LaneLayerWidgetProps {
	layer: LanesLayerModel;
	engine: DiagramEngine;
}


namespace S {
	export const Container = styled.div``;
}

export class LanesLayerWidget extends React.Component<LaneLayerWidgetProps> {
	render() {
		return (
			<>
				{_.map(this.props.layer.getNodes(), (node: LaneNodeModel) => {
					return <Lane key={node.getID()} engine={this.props.engine} node={node} />;
				})}
			</>
		);
	}
}

