import * as React from 'react';
import { PointModel } from '../../diagrams-engine';
import { getPathOrientation } from './RightAngleLinkModel';
import { LinkPathOrientation } from '../../diagrams-core/entities/link/RightAngleLinkModel';
import { Point } from '../../geometry';

export interface MidControlProps {
  firstPoint: PointModel;
  lastPoint: PointModel;
  color: string;
  colorSelected: string;
}
export interface MidControlPoints {
  midControlStartPoint?: Point;
  midControlEndPoint?: Point;
}

export const getMiddlePoint = (axis:string) => (startPoint: PointModel, endPoint: PointModel) => {
  const startPosition = startPoint.getPosition()[axis];
  const delta = endPoint.getPosition()[axis] - startPosition;
  return (startPosition + (delta / 2)); 
}


const getMidControlPoints = (firstPoint: PointModel, lastPoint: PointModel): MidControlPoints => {
  const orientation = getPathOrientation(lastPoint, firstPoint);
  const width = 10;
  if (orientation === LinkPathOrientation.HORIZONTAL) {
    const middlePoint = getMiddlePoint('x')(firstPoint, lastPoint);
    return {
      midControlStartPoint: new Point(middlePoint - (width / 2), firstPoint.getPosition().y),
      midControlEndPoint: new Point(middlePoint + (width / 2), firstPoint.getPosition().y),
    }
  } else if (orientation === LinkPathOrientation.VERTICAL) {
    const middlePoint = getMiddlePoint('y')(firstPoint, lastPoint);
    return {
      midControlStartPoint: new Point(firstPoint.getPosition().x, middlePoint - (width / 2)),
      midControlEndPoint: new Point(firstPoint.getPosition().x, middlePoint + (width / 2)),
    }
  } else {
    return {
      midControlStartPoint: null,
      midControlEndPoint: null,
    }
  }
}

const generateLinePath = (firstPoint: Point, lastPoint: Point): string  => {
  if (!firstPoint) return '';
  return `M${firstPoint.x},${firstPoint.y} L ${lastPoint.x},${lastPoint.y}`;
}

const MidControl = (props: MidControlProps) => {
	const { firstPoint, lastPoint } = props;

  const { midControlStartPoint , midControlEndPoint } = getMidControlPoints(firstPoint, lastPoint)
  const middle = generateLinePath(midControlStartPoint, midControlEndPoint);

	return (
    <>
      {/* <path
        fill="none"
        strokeWidth={3}
        stroke="rgba(255,0,0,0.5)"
        d={middle}
      /> */}
    </>
	);
};

export default MidControl;

