import { CanvasEngine, Action, ActionEvent, InputType, State, BaseModel, BasePositionModel, AbstractDisplacementState, AbstractDisplacementStateEvent, CanvasModel } from '../canvas-core';
import { Point } from '../geometry';
import { VerticalMovement, HorizontalMovement, LinkPathOrientation } from '../diagrams-core/entities/link/RightAngleLinkModel'
import { BaseNodePortModel } from '../models/BaseNode/BaseNodePortModel';
import { BaseNodeModel } from '../models/BaseNode/BaseNodeModel';
import { forEach, min, max, tail, dropRight, omit } from 'lodash'
import { DiagramModel, LinkModel, PointModel, PortModelAlignment, LaneNodeModel, NodeModel } from '../diagrams-core';
import { LaneModelOptions } from '../diagrams-core/entities/lane/LaneNodeModel'
import { RightAngleLinkModel, getPathOrientation, Collisions } from '../routing/link/RightAngleLinkModel';

// contained links should be not be transformed

export type ResizePositions = 'nw' | 'ne' | 'sw' | 'se'

type SidePoint = {
	index?: number,
	lineOrientation? :LinkPathOrientation,
	selected?: boolean,
	point?: PointModel,
}

export class MoveItemsState<E extends CanvasEngine = CanvasEngine> extends AbstractDisplacementState<E> {
	initialPositions: {
		[id: string]: {
			point: Point;
			item?: BaseModel;
			dimmension?: {
				width: number,
				height: number,
			}
		};
	};
	onDragPositions: {
		[id: string]: {
			point: Point;
			item?: BaseModel;
			dimmension?: {
				width: number,
				height: number,
			}
		};
	}
	verticalMovement: {
		initialPositions: VerticalMovement;
		onDragPositions: VerticalMovement;
	}
	horizontalMovement: {
		initialPositions: HorizontalMovement;
		onDragPositions: HorizontalMovement;
	}
	resize?: {
		lane?: LaneNodeModel,
		position?: ResizePositions,
		initialdimmensions?: {
			width: number,
			height: number,
		},
		initialPosition?: {
			point: Point,
		}
	}

	constructor() {
		super({
			name: 'move-items'
		});
		this.horizontalMovement = {
			initialPositions: HorizontalMovement.NONE,
			onDragPositions: HorizontalMovement.NONE,
		}
		this.verticalMovement = {
			initialPositions: VerticalMovement.NONE,
			onDragPositions: VerticalMovement.NONE,
		}

		this.registerAction(
			new Action({
				type: InputType.MOUSE_DOWN,
				fire: (event: ActionEvent<React.MouseEvent>) => {
					const element = this.engine.getActionEventBus().getModelForEvent(event);
					const container  = this.engine.getActionEventBus().getContainerForEvent(event) as LaneNodeModel; 
					if (element && element instanceof LaneNodeModel) {
						this.engine.getModel().clearSelection();
						const resize = ((event as ActionEvent<React.MouseEvent>).event.target as Element).getAttribute('data-position') as any
						if (resize && !this.resize) {
							this.resize = {}
							this.resize.lane = element
							this.resize.position = (resize as ResizePositions)
							this.resize.initialPosition = {
								point: (element as LaneNodeModel).getPosition(),
							}
							this.resize.initialdimmensions = {
								width: (element as LaneNodeModel).width,
								height: (element as LaneNodeModel).height,
							}
						} else if (resize && this.resize) {
							this.resize = null
						}
					} else if (element && !element.isSelected()) {
						this.engine.getModel().clearSelection();
					}
					element.setSelected(true);
					this.engine.repaintCanvas();	
					// return

				}
			})
		);
		this.registerAction(
			new Action({
				type: InputType.MOUSE_UP,
				fire: (event: ActionEvent<React.MouseEvent>) => {

					const element = this.engine.getActionEventBus().getModelForEvent(event) as BaseNodeModel;
					const container  = this.engine.getActionEventBus().getContainerForEvent(event) as LaneNodeModel; 
					const model = (this.engine.getModel() as DiagramModel)
					const excludeLanes = (node: BaseNodeModel) => (!(node instanceof LaneNodeModel) && !(node instanceof RightAngleLinkModel))
					// console.log('MOUSE_UP element', element)
					// console.log('MOUSE_UP container', container)

					if (element instanceof LaneNodeModel && container instanceof LaneNodeModel && !this.resize) {
						// console.log('check for contained elements')
						const rect = element.getBoundingBox()
						const items = this.engine.getModel().getModels().filter(excludeLanes);
						for (let item of items) {
							const node = item as BaseNodeModel
							const contained = rect.containsPoint(node.getPosition())
							if (contained) {
								if (node.containerId) {
									const oldContainer = model.getLane(node.containerId) as LaneNodeModel
									oldContainer.removeEntity(node.getID())
								}
								element.addEntity(node.getID())
								node.setContainer(element.getID())
							} else {
								if (node.containerId && node.containerId === element.getID()) {
									element.removeEntity(node.getID())
									node.setContainer(null)
								}
							}
						}
						// this.clearState()

					} else if (!container && element && element instanceof BaseNodeModel) {
						if (element.containerId) {
							const oldContainer = model.getLane(element.containerId) as LaneNodeModel
							oldContainer.removeEntity(element.getID())
						}
						// this.clearState()
					} else if (container && element instanceof BaseNodeModel) {
						// console.log('here', element)
						const rect = container.getBoundingBox()
						const contained = rect.containsPoint(element.getPosition())
						if (contained) {
							if (element.containerId) {
								const oldContainer = model.getLane(element.containerId) as LaneNodeModel
								oldContainer.removeEntity(element.getID())
							}
							container.addEntity(element.getID())
							element.setContainer(container.getID())
						}
						// this.clearState()
					} else if (element && element instanceof LaneNodeModel && this.resize) {
						this.clearState()
					} else {
						this.clearState()
					}
					return 
				}
			})
		);
	}

	clearState() {
		this.resize = null
		this.initialPositions = null
		this.onDragPositions = null
		this.horizontalMovement = {
			initialPositions: HorizontalMovement.NONE,
			onDragPositions: HorizontalMovement.NONE,
		}
		this.verticalMovement = {
			initialPositions: VerticalMovement.NONE,
			onDragPositions: VerticalMovement.NONE,
		}

	}

	activated(previous: State) {
		super.activated(previous);
		this.initialPositions = {};
		this.onDragPositions = {};
		this.horizontalMovement = {
			initialPositions: HorizontalMovement.NONE,
			onDragPositions: HorizontalMovement.NONE,
		}
		this.verticalMovement = {
			initialPositions: VerticalMovement.NONE,
			onDragPositions: VerticalMovement.NONE,
		}
	}

	detectChanges(item: BasePositionModel, x:number, y: number, state = 'initialPositions') {
		const dx = Math.abs(this[state][item.getID()].point.x - x)
		const dy = Math.abs(this[state][item.getID()].point.y - y)
		if (this[state][item.getID()].point.x > x) {
			this.horizontalMovement[state] = HorizontalMovement.LEFT;
		} else if (this[state][item.getID()].point.x < x) {
			this.horizontalMovement[state] = HorizontalMovement.RIGHT;
		} else {
			this.horizontalMovement[state] = HorizontalMovement.NONE;
		}
		if (this[state][item.getID()].point.y > y) {
			this.verticalMovement[state] = VerticalMovement.UP;
		} else if (this[state][item.getID()].point.y < y) {
			this.verticalMovement[state] = VerticalMovement.DOWN;
		} else {
			this.verticalMovement[state] = VerticalMovement.NONE;
		}
		return {
			dx, dy
		}
	}

	updateLinks(item: BaseNodeModel, movingContainer = false, event: AbstractDisplacementStateEvent) {
		const links = item.links()
		const node = item as BaseNodeModel
		forEach(links, (link: RightAngleLinkModel) => {
			if (movingContainer && link.isContained()) {
				const points = link.getPoints()
				const pointsToUpdate = points.slice(1, points.length - 1)
				const model = this.engine.getModel();

				for (let point of pointsToUpdate) {
					if (!this.initialPositions[point.getID()]) {
						this.initialPositions[point.getID()] = {
							point: point.getPosition(),
							item: point
						};
					}
					if (!this.onDragPositions[point.getID()]) {
						this.onDragPositions[point.getID()] = {
							point: (point as BasePositionModel).getPosition(),
							item: point
						};
					}
	
					const pos = this.initialPositions[point.getID()].point;
					const x = model.getGridPosition(pos.x + event.virtualDisplacementX);
					const y = model.getGridPosition(pos.y + event.virtualDisplacementY);
					point.setPosition(
						x,
						y,
					);
					this.onDragPositions[point.getID()] = {
						point: point.getPosition(),
						item: point
					};
				}

			} else {
				const points = link.getPoints()
				let indexPoint: number
				const sourcePort = link.getSourcePort()
				const targetPort = link.getTargetPort()
				const sourceOrTarget = node.linkedAs(link)
				let port: BaseNodePortModel
				let otherPort: BaseNodePortModel
				if (sourceOrTarget === 'source') {
					port = sourcePort as BaseNodePortModel
					otherPort = targetPort as BaseNodePortModel
					indexPoint = 1
				} else {
					port = targetPort as BaseNodePortModel
					otherPort = targetPort as BaseNodePortModel
					otherPort = sourcePort as BaseNodePortModel
					indexPoint = points.length - 2
				}
				const opposite = (sourceOrTarget === 'source') ? 'target' : 'source'
				let lineIndex:number
				lineIndex = (sourceOrTarget === 'source') ? 1 : points.length - 3
	
				const line = link.getLine(lineIndex)
				const lineOrientation = LinkModel.lineDirection(line[0], line[1])
				const coordinate = (lineOrientation === LinkPathOrientation.VERTICAL) ? 'x' : 'y'
	
				let override = link.detectLineOverride(coordinate, node, lineIndex, line)
				let overrideModel: BaseNodeModel = node
				let newPort: BaseNodePortModel

				if (override) {
					newPort = overrideModel[`${override}Port`]
					const firstOrLast = (sourceOrTarget === 'source') ? 'getFirstPoint' : 'getLastPoint'
					const mainAxis = (override === PortModelAlignment.RIGHT || override === PortModelAlignment.LEFT) ? 'x' : 'y'
					const oppositeAxis = (mainAxis === 'x') ? 'y' : 'x'
					const oppositePort = (sourceOrTarget === 'source') ? targetPort : sourcePort
					if (points.length > 3) {
						const middlePointIndex = (sourceOrTarget === 'source') ? 1 : link.getPoints().length - 3
						const prevMiddlePoint = (sourceOrTarget === 'source') ? 2 : link.getPoints().length - 4
						const updatedPoint = new Point({
							[oppositeAxis]: newPort.getPosition()[oppositeAxis],
							[mainAxis]: link.getPoints()[prevMiddlePoint].getPosition()[mainAxis],
						})
						const horizontal = (this.horizontalMovement.initialPositions === HorizontalMovement.LEFT || this.horizontalMovement.initialPositions === HorizontalMovement.RIGHT)
						const vertical = (this.verticalMovement.initialPositions === VerticalMovement.DOWN || this.verticalMovement.initialPositions === VerticalMovement.UP)
						// check override 
						if (lineOrientation === LinkPathOrientation.HORIZONTAL && vertical) {
							link.removePoint(link[firstOrLast]())
							link[firstOrLast]().setPosition(newPort.getPosition())
						} else if (lineOrientation === LinkPathOrientation.VERTICAL && horizontal) {
							link.removePoint(link[firstOrLast]())
							link[firstOrLast]().setPosition(newPort.getPosition())
						}
						link.getPoints()[middlePointIndex].setPosition(updatedPoint)
					} else {
	
						const middlePointIndex = (sourceOrTarget === 'source') ? 1 : 1
						const newPointIndex = (sourceOrTarget === 'source') ? 1 : 2
						const middlePosition = RightAngleLinkModel.getMiddlePoint(mainAxis)(oppositePort.getPosition(), newPort.getPosition())
						const lastPointIndex = (sourceOrTarget === 'source') ? 0 : 3
						const middlePoint = new Point({[mainAxis]: middlePosition, [oppositeAxis]: oppositePort.getPosition()[oppositeAxis] + oppositePort.getPortRadius()})
						link.getPoints()[middlePointIndex].setPosition(middlePoint)
						const secondMiddle = new Point({[mainAxis]: middlePosition, [oppositeAxis]: newPort.getPosition()[oppositeAxis]})
						const newPoint = new PointModel(
							{
								link,
								position: secondMiddle,
							}
						)
						link.addPoint(newPoint, newPointIndex)									
						link.getPoints()[lastPointIndex].setPosition(newPort.getPosition())
					}
					if (sourceOrTarget === 'source') {
						link.setSourcePort(newPort)
					} else {
						link.setTargetPort(newPort)
					}
					newPort.reportPosition()
					override = undefined
				} else {
					if (port.getOptions().alignment === PortModelAlignment.RIGHT || port.getOptions().alignment === PortModelAlignment.LEFT) {
						link.getPoints()[indexPoint].setPosition(points[indexPoint].getX(), port.getY() + port.getPortRadius())
					} else {
						link.getPoints()[indexPoint].setPosition(port.getX() + port.getPortRadius() , link.getPoints()[indexPoint].getY())	
					}
				}
	
			}
		})
		return
	}
	handleResize(event: AbstractDisplacementStateEvent) {
		const model = this.engine.getModel();
		const lane = this.resize.lane
		if (!this.initialPositions[lane.getID()]) {
			this.initialPositions[lane.getID()] = {
				point: lane.getPosition(),
				dimmension: {
					width: lane.width,
					height: lane.height,
				}
			};
		}
		if (!this.onDragPositions[lane.getID()]) {
			this.onDragPositions[lane.getID()] = {
				point: lane.getPosition(),
				dimmension: {
					width: lane.width,
					height: lane.height,
				}
			};
		}
		const pos = this.initialPositions[lane.getID()].point;
		const dimmension = this.initialPositions[lane.getID()].dimmension;
		const x = model.getGridPosition(pos.x + event.virtualDisplacementX);
		const y = model.getGridPosition(pos.y + event.virtualDisplacementY);
		const dx = pos.x - x
		const dy = pos.y - y
		switch (this.resize.position) {
			case 'nw': {
				const newHeight = dimmension.height + dy - 4;
				const newWidth = dimmension.width + dx - 4;
				const canChangeHeight = (newHeight >= (lane.getOptions() as LaneModelOptions).minHeight)
				const canChangeWidth = (newWidth >= (lane.getOptions() as LaneModelOptions).minWidth)
				const height = (canChangeHeight) ? newHeight : (lane.getOptions() as LaneModelOptions).minHeight
				const width = (canChangeWidth) ? newWidth : (lane.getOptions() as LaneModelOptions).minWidth
				lane.setPosition(
					(canChangeWidth) ? x: x,
					(canChangeHeight) ? y: y
				);
				lane.updateDimensions({ height, width })
				this.resize.initialPosition = {
					point: lane.getPosition(),
					dimmension: {
						width: lane.width,
						height: lane.height,
					}
				}
				this.resize.lane = lane
				this.engine.repaintCanvas();
				return
			}
				
			case 'ne':
				{
					lane.setPosition(
						pos.x,
						y,
					);
					lane.updateDimensions({ height: dimmension.height + dy - 4, width: dimmension.width - dx - 4})
					this.resize.initialPosition = {
						point: lane.getPosition(),
						dimmension: {
							width: lane.width,
							height: lane.height,
						}
					}
					this.resize.lane = lane
					this.engine.repaintCanvas();
					return
				}
			case 'sw': {
				lane.setPosition(
					x,
					pos.y,
				);
				lane.updateDimensions({ height: dimmension.height - dy - 4, width: dimmension.width + dx - 4})
				this.resize.initialPosition = {
					point: lane.getPosition(),
					dimmension: {
						width: lane.width,
						height: lane.height,
					}
				}
				this.resize.lane = lane
				this.engine.repaintCanvas();
				return
			}
			case 'se': {
				lane.setPosition(
					pos.x,
					pos.y,
				);
				lane.updateDimensions({ height: dimmension.height - dy - 4, width: dimmension.width - dx - 4})
				this.resize.initialPosition = {
					point: lane.getPosition(),
					dimmension: {
						width: lane.width,
						height: lane.height,
					}
				}
				this.resize.lane = lane
				this.engine.repaintCanvas();
				return
			}
			default:
				return
		}

	}

	fireMouseMoved(event: AbstractDisplacementStateEvent) {
		if (this.resize) {
			this.handleResize(event)
			return
		}
		const items = this.engine.getModel().getSelectedEntities();
		const container  = this.engine.getActionEventBus().getContainerForEvent(event) as LaneNodeModel; 

		const model = this.engine.getModel();
		// console.log('firemousemoved start')
		for (let item of items) {
			if (item instanceof BasePositionModel || item instanceof LaneNodeModel) {
				if (item.isLocked()) {
					continue;
				}
				if (!this.initialPositions[item.getID()]) {
					this.initialPositions[item.getID()] = {
						point: item.getPosition(),
						item: item
					};
				}
				if (!this.onDragPositions[item.getID()]) {
					this.onDragPositions[item.getID()] = {
						point: (item as BasePositionModel).getPosition(),
						item: item
					};
				}

				const pos = this.initialPositions[item.getID()].point;
				const x = model.getGridPosition(pos.x + event.virtualDisplacementX);
				const y = model.getGridPosition(pos.y + event.virtualDisplacementY);
				this.detectChanges(item, x, y, 'onDragPositions')
				this.detectChanges(item, x, y)

/*

						const rect = container.getBoundingBox()
						const contained = rect.containsPoint(element.getPosition())
						if (contained) {
							if (element.containerId) {
								const oldContainer = model.getLane(element.containerId) as LaneNodeModel
								oldContainer.removeEntity(element.getID())
							}
							container.addEntity(element.getID())
							element.setContainer(container.getID())
						}

*/



				if (this.verticalMovement.initialPositions !== VerticalMovement.NONE || this.horizontalMovement.initialPositions !== HorizontalMovement.NONE) {
					//
					// is point
					//
					if (item instanceof PointModel) {
						const link = item.getParent() as RightAngleLinkModel
						const id = item.getID
						const index = link.getPointIndex(item)
						const totalPoints = link.getPoints().length
						const selectablePoints = dropRight(tail(link.getPoints()))
						const sourceSelected = link.getSourcePort().getParent().isSelected()
						const targetSelected = link.getTargetPort().getParent().isSelected()
						const allSelected = sourceSelected && targetSelected
						const from = (allSelected) ? 0 : 1
						const to = (allSelected) ? link.getPoints().length - 1 : link.getPoints().length - 2
						if (index >= from && index <= to) {
							const selectedPoints = selectablePoints.filter((point) => point.isSelected()).map((p) => link.getPointIndex(p));
							const unselected = selectablePoints.filter((point) => !point.isSelected());
							const minSelected = min(selectedPoints)
							const maxSelected = max(selectedPoints)
							const horizontal = Boolean(this.horizontalMovement.onDragPositions === HorizontalMovement.LEFT || this.horizontalMovement.onDragPositions === HorizontalMovement.RIGHT)
							const vertical = Boolean(this.verticalMovement.onDragPositions === VerticalMovement.DOWN || this.verticalMovement.onDragPositions === VerticalMovement.UP)

							if (unselected.length > 0) {
								if (targetSelected || sourceSelected) {
									const unselectedIndexes = unselected.map((p) => link.getPointIndex(p))
									const withoutExtremes = unselectedIndexes.filter((i) => i !== 0).filter((i) => i !== totalPoints - 1)
									const maxUnselected = max(withoutExtremes)
									const minUnselected = min(withoutExtremes)
									if (withoutExtremes.length > 0) {
										const diagonal = horizontal && vertical
										const nextPointMoved = (maxSelected < minUnselected) && index === maxSelected
										
										if (minSelected > maxUnselected) {										
											if (index === minSelected) {
												const prevPoint = link.getPoints()[index - 1]
												const lineOrientation = link.getLineDirection(index - 1)
												let prevPointPos:any = {
													x: prevPoint.getX(),
													y: prevPoint.getY(),
												}
	
												if (horizontal) {
													if (lineOrientation === LinkPathOrientation.HORIZONTAL) {
														// no movement
													} else if (lineOrientation === LinkPathOrientation.VERTICAL) {
														// move x
														prevPointPos.x = x
													}	
												}
												if (vertical) {
													if (lineOrientation === LinkPathOrientation.HORIZONTAL) {
														// move y
														prevPointPos.y = y
													} else if (lineOrientation === LinkPathOrientation.VERTICAL) {
														// no movement
													}
												}
												link.getPoints()[index - 1].setPosition(prevPointPos.x, prevPointPos.y)
											}
										} else if (maxSelected < minUnselected) {
											if (index === maxSelected) {
												const nextPoint = link.getPoints()[index + 1]
												let lineOrientation = link.getLineDirection(index)
												let nextPointPos:any = {
													x: nextPoint.getX(),
													y: nextPoint.getY(),
												}
												if (horizontal) {
													if (lineOrientation === LinkPathOrientation.HORIZONTAL) {
														// no movement
													} else if (lineOrientation === LinkPathOrientation.VERTICAL) {
														// move x
														nextPointPos.x = x
													}	
												}
												if (vertical) {
													if (lineOrientation === LinkPathOrientation.HORIZONTAL) {
														// move y
														nextPointPos.y = y
													} else if (lineOrientation === LinkPathOrientation.VERTICAL) {
														// no movement
													}
												}
												link.getPoints()[index + 1].setPosition(nextPointPos.x, nextPointPos.y)
											}					
										}					
									}
								}
	
							}
							if (sourceSelected && targetSelected) {
								item.setPosition(
									x,
									y,
								);
							} else if (sourceSelected && index === link.getPoints().length - 2) {
								let lineOrientation = link.getLineDirection(index + 1)
								let updatedPoint:any = {
									x: item.getX(),
									y: item.getY(),
								}
								if (horizontal) {
									if (lineOrientation === LinkPathOrientation.VERTICAL && link.getPoints().length > 3) {
										updatedPoint.x = x
									}
								}
								if (vertical) {
									if (lineOrientation === LinkPathOrientation.HORIZONTAL && link.getPoints().length > 3) {
										updatedPoint.y = y
									}
								}
								item.setPosition(
									updatedPoint.x,
									updatedPoint.y,
								);
							} else if (targetSelected && index === 1) {
								let lineOrientation = link.getLineDirection(index)
								let updatedPoint:any = {
									x: item.getX(),
									y: item.getY(),
								}
								if (horizontal) {
									if (lineOrientation === LinkPathOrientation.VERTICAL && link.getPoints().length > 3) {
										updatedPoint.x = x
									}
								}
								if (vertical) {
									if (lineOrientation === LinkPathOrientation.HORIZONTAL && link.getPoints().length > 3) {
										updatedPoint.y = y
									}
								}
								item.setPosition(
									updatedPoint.x,
									updatedPoint.y,
								);

							} else if (targetSelected || sourceSelected) {
								item.setPosition(
									x,
									y,
								);
							}
							this.onDragPositions[item.getID()] = {
								point: item.getPosition(),
								item: item
							};	

						}
					} else {
						if (item instanceof LaneNodeModel) {
						} else {
							const node = item as BaseNodeModel
							if (container) {
								const model = (this.engine.getModel() as DiagramModel)
								const rect = container.getBoundingBox()
								const contained = rect.containsPoint(item.getPosition())
								if (contained) {
									if (node.containerId) {
										const oldContainer = model.getLane(node.containerId) as LaneNodeModel
										oldContainer.removeEntity(item.getID())
									}
									container.addEntity(item.getID());
									node.setContainer(container.getID())
								}	
							} else {
								if (node.containerId) {
									const model = (this.engine.getModel() as DiagramModel)
									const oldContainer = model.getLane(node.containerId) as LaneNodeModel
									oldContainer.removeEntity(item.getID())
									node.setContainer(null)
								}
							}	
						}
						// is entity
						item.setPosition(
							x,
							y,
						);
						// console.log('item contained 2', (item as BaseNodeModel).isContained())
						if (item instanceof LaneNodeModel) {
							// is lane

							item.containedIds.forEach((id: string) => {
								const node = (model as DiagramModel).getNode(id) as BaseNodeModel
								if (!this.initialPositions[id]) {
									this.initialPositions[id] = {
										point: node.getPosition(),
										item: node
									};
								}
								if (!this.onDragPositions[id]) {
									this.onDragPositions[id] = {
										point: node.getPosition(),
										item: node
									};
								}				
								// const nx = model.getGridPosition(x - pos.x);
								// const ny = model.getGridPosition(y - pos.y);
								const pos = this.initialPositions[id].point;
								const x = model.getGridPosition(pos.x + event.virtualDisplacementX);
								const y = model.getGridPosition(pos.y + event.virtualDisplacementY);
								this.detectChanges(node, x, y, 'onDragPositions')
								this.detectChanges(node, x, y)

								node.setPosition(
									x,
									y,
								);
								this.updateLinks(node, true, event)
		
								// node.setPosition(nx, ny)
								// this.engine.getModel()
							})
						} else if (item instanceof BaseNodeModel) {
							
							this.updateLinks(item, false, event)
						}
					}
					this.onDragPositions[item.getID()] = {
						point: item.getPosition(),
						item: item
					};
				}
			}
		}
		this.engine.repaintCanvas();
		// console.log('firemousemoved end')

	}
}
