import * as React from 'react';
import { PortWidget } from '../../diagrams-core';
import { PortModelAlignment } from '../../diagrams-engine';
import styled from '@emotion/styled';
import { BaseNodePortModel } from '../BaseNode/BaseNodePortModel';
import { State } from '../../canvas-core/core-state/State';
import { BaseNodeModel } from '../BaseNode/BaseNodeModel';

export const DEFAULTS = {
  PORT_RADIUS: 10,
}

namespace S {
	export const Port = styled.div<{ isLinking: boolean, defaultLocation: boolean, title?: string}>`
		width: ${DEFAULTS.PORT_RADIUS * 2}px;
		height: ${DEFAULTS.PORT_RADIUS * 2}px;
		z-index: 10;
    background: rgba(0, 0, 0, 0.0);
    color: rgba(0, 0, 0, 0.2);
		border-radius: ${DEFAULTS.PORT_RADIUS}px;
    cursor: pointer;
		&:hover {
      opacity: ${p => (p.isLinking === true) ? 0 : 1}; 
      border: ${p => (p.isLinking === true || !p.defaultLocation) ? '' : 'solid 1px rgba(255, 0, 0, 0.7)'};
      background: ${p => (p.isLinking === true || !p.defaultLocation) ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 0, 0, 0.1)'};
    }
	`;
}

const getVirtualPortStyle = (port: BaseNodePortModel, width: number, height: number, state: State) => {
  const alignment = port.getOptions().alignment
  const isDefault = port.defaultLocation

  const offsetX = (port.reportedPosition) ? port.getPortRadius() : 0;
  const offsetY = (port.reportedPosition) ? port.getPortRadius() : 0;
  const x = (isDefault) ? width / 2 : port.getX() - port.getParent().getX() + offsetX 
  const y = (isDefault) ? height / 2 : port.getY() - port.getParent().getY() + offsetY
  const shapeOffsetY = ((isDefault) ? 0 : port.shapeOffsetY)
  const shapeOffsetX = ((isDefault) ? 0 : port.shapeOffsetX)
  switch(alignment) {
    case PortModelAlignment.TOP:
      const top = {
        top: - port.getPortRadius() + shapeOffsetY,
        left: x - port.getPortRadius(),
        position: 'absolute'
      }

      return top
    case PortModelAlignment.LEFT: 
      return {
        top: y - port.getPortRadius(),
        left: - port.getPortRadius() - 0.5 + shapeOffsetX,
        position: 'absolute'
      }
    case PortModelAlignment.RIGHT:
      return {
        top: y - port.getPortRadius(),
        left: width - (port.getPortRadius() / 1) + shapeOffsetX,
        position: 'absolute'
      }
    case PortModelAlignment.BOTTOM:
      return {
        top: height - ((port.getPortRadius() / 1) + 0.5) + shapeOffsetY,
        left: x - port.getPortRadius(),
        position: 'absolute'
      }
    default:
      return {}
  }
}


const BasePortWidget = (props:any) => {
  const { engine, port, width, height, isLinking } = props
  const state = engine.getStateMachine().getCurrentState();

  const style = getVirtualPortStyle(port, width, height, state)
  return (
    <PortWidget  
      engine={engine}
      port={port}
      style={style}
      >
      <S.Port isLinking={isLinking} defaultLocation={port.defaultLocation} title={`${port.getID()} ${port.getX()}, ${port.getY()}`} />
    </PortWidget>
  )
}
export default BasePortWidget

