import React, {useState} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import NewDiagram from './NewDiagram';
import LoadDiagram from './LoadDiagram';
import { DiagramEngine } from '../diagrams-core';
import Noop from './Noop';
import DiagramsList from './DiagramsList';
import SaveDialog from './SaveDialog';
import SaveDiagram from './SaveDiagram';
import localFS from './localFS'
import backend from './backend';

export const MAGENTA = `#B228B6`
export const MAGENTA_DARK = `#821886`
const MAGENTA_CONTRAST = `#FFF`
export const WHITE_CONTRAST = `#333`

namespace S {
	const open = css`
	transform: scale(1.1);
  transform-origin: center;
	transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
`

	export const Container = styled.div<{open?: boolean}>`
    z-index: 1000;
    cursor: pointer;
    background-color: ${MAGENTA};
    width: 48px;
    height: 48px;
    border-radius: 100%;
    border: none;
    outline: none;
    background: ${MAGENTA};
    color: ${MAGENTA_CONTRAST};
    font-size: 36px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: absolute;
    bottom: 48px;
    right: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    `;

  export const Plus = styled.div<{open?: boolean}>`
  ${(p) => p.open && open};
  display:flex;
  flex-direction: column;
  justify-content: center;
  transition: .3s;
  cursor: pointer;
  background: transparent;
  color: ${MAGENTA_CONTRAST};
  font-family: none;
  border: none;
  outline: none;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  `;

  export const Menu = styled.div<{}>`
    z-index: 1001;

    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: #EDEDED99;
    color: ${WHITE_CONTRAST};
    position: absolute;
    bottom: 92px;
    right: 92px;
    padding: 0.5rem;
    border-radius: 5px;
  `;

  export const MenuItem = styled.button<{}>`
    border-radius: 5px;
    background-color: #EDEDED99;
    border: 0px;
    width: 100%;
    display: flex;
    font-family: Exo 2;
    padding: 0.5rem;
    &: hover {
      background-color: #FFD700;
    }
  `;

  export const DialogButton = styled.button<{backgroundColor?: string, color?:string }>`
    border-radius: 5px;
    border: 0px;
    width: 100%;
    display: flex;
    font-family: Exo 2;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: ${(p) => p.color || WHITE_CONTRAST};
    background-color: ${(p) => p.backgroundColor || '#ddd'};
    &: hover {
      background-color: #FFD700;
      color: ${WHITE_CONTRAST};
    }
    &: disabled {
      background-color: #EEE;
      color: #DDD;
    }
  `;

  
  export const Backdrop = styled.div<{}>`
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 1201;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
  `
  export const DialogContainer = styled.div<{}>`
    height: 100%;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `
  export const Dialog = styled.div<{}>`
    max-width: 600px;
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;
    z-index: 1301;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    border-radius: 4px;
    background-color: #ffffff;
    margin: 32px;
    position: relative;
    overflow-y: auto;
    `
  export const DialogContent = styled.div<{}>`
    padding: 16px 24px;
  `

}

type MenuOption = {
  id: string,
  label: string,
  action: (engine: DiagramEngine, setMenuOption: Function, setModalOpen: Function, name: string, setName: Function) => DiagramEngine,
  modal: boolean,
}

const options: MenuOption[] = [
  {
    id: 'new',
    label: 'New Diagram',
    action: NewDiagram,
    modal: false,
  },
  {
    id: 'load',
    label: 'Load Diagram...',
    action: LoadDiagram,
    modal: true,
  },
  {
    id: 'save',
    label: 'Save Diagram...',
    action: SaveDiagram,
    modal: false,
  },
  {
    id: 'duplicate',
    label: 'Duplicate Diagram...',
    action: Noop,
    modal: false,
  },
  {
    id: 'feedback',
    label: 'Send Feedback...',
    action: Noop,
    modal: false,
  },
]

const Control = (props: any) => {
  const { engine, params } = props
  const [open, setOpen] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState()
  const fs = localFS();
  const [name, setName] = React.useState()
  const menuOption = (option:MenuOption) => () => {
    setSelectedMenu(option.id as any)
    option.action(engine, setSelectedMenu, setModalOpen, name, setName)
  }

  const closeModal = () => {
    setSelectedMenu(null)
    setModalOpen(false)
  }

  const save = () => {
    const data = engine.model.serialize();
    fs.write(name, data);
    setSelectedMenu(null)
    setModalOpen(false)
  }

  const saveRemote = async () => {
    await backend.save(params.id, name, engine.model.serialize())
    setSelectedMenu(null)
    setModalOpen(false)
  }

  return (
    <>
    {open && 
      <>
        {modalOpen && <S.Backdrop>
            <S.DialogContainer>
              <S.Dialog>
                {selectedMenu && selectedMenu === 'load' && <S.DialogContent>
                  <DiagramsList fs={fs} engine={engine} setModalOpen={setModalOpen} setSelectedMenu={setSelectedMenu}/>
                  <S.DialogButton onClick={() => closeModal()}>Close</S.DialogButton>
                </S.DialogContent>}
                {selectedMenu && selectedMenu === 'save' && <S.DialogContent>
                  <SaveDialog name={name} setName={setName} />
                  <S.DialogButton disabled={(!name || name === '')} onClick={() => save()} color={MAGENTA_CONTRAST} backgroundColor={MAGENTA}>Save</S.DialogButton>
                  <S.DialogButton disabled={(!name || name === '')} onClick={() => saveRemote()} color={MAGENTA_CONTRAST} backgroundColor={MAGENTA_DARK}>Save Remote</S.DialogButton>
                  <S.DialogButton onClick={() => closeModal()}>Cancel</S.DialogButton>
                </S.DialogContent>}
              </S.Dialog>
            </S.DialogContainer>
          </S.Backdrop>
        }
        <S.Menu>
          {options.map((op: MenuOption) => {
          return (
            <S.MenuItem key={op.id} onClick={menuOption(op)}>{op.label}</S.MenuItem>
          )
          })}
        </S.Menu>
      </>
    }
    <S.Container open={open} onClick={() => setOpen(!open as any)}><S.Plus open={open} >+</S.Plus></S.Container>
    </>
  )
}

export default Control;