import * as React from 'react';
import styled from '@emotion/styled';

namespace S {
	export const FileName = styled.input<{open?: boolean}>`
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    border: solid 1px #ddd ;
    border-radius: 4px;
    font-size: 2rem;
    width: 100%;
    box-sizing: content-box;
    &:focus {
      outline: none;    }
	`;
  export const Label = styled.label<{}>`
  `
  export const Title = styled.h1<{}>`
    font-size: 2rem;
    margin-bottom: 1rem;
  `

}

const SaveDialog = props => {
  const {name, setName} = props
  const handleChange = e => {
    setName(e.target.value)
  }
  return (
    <>
      <S.Title>Save diagram...</S.Title>
      <S.Label htmlFor="name">Diagram name:</S.Label>
      <S.FileName name="name" autoFocus placeholder="my diagram" type="text" value={name || ''} onChange={handleChange} />
    </>
  )
}

export default SaveDialog