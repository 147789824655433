import * as React from 'react';
import { LaneNodeModel } from './LaneNodeModel';
import { Lane } from './LaneNodeWidget';
import { AbstractReactFactory } from '../../../canvas-core';
import { DiagramEngine } from '../../../diagrams-core';


export class LaneNodeFactory extends AbstractReactFactory<LaneNodeModel, DiagramEngine> {
	constructor() {
		super('lane-node');
	}

	generateModel(initialConfig): LaneNodeModel {
		return new LaneNodeModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <Lane engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
