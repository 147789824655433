import * as React from 'react';
import { EndEventMessageModel, TYPE } from './EndEventMessageNodeModel';
import { EndEventMessageNodeWidget } from './EndEventMessageNodeWidget';
import { AbstractReactFactory } from '../../canvas-core';
import { DiagramEngine } from '../../diagrams-core';


export class EndEventMessageNodeFactory extends AbstractReactFactory<EndEventMessageModel, DiagramEngine> {
	constructor() {
		super(TYPE);
	}

	generateModel(initialConfig) {
		return new EndEventMessageModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <EndEventMessageNodeWidget engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
