import * as React from 'react';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '../../../canvas-core';
import { DiagramEngine } from '../..';
import { AppLayerWidget } from './AppLayerWidget';
import { AppLayerModel } from './AppLayerModel';

export class AppLayerFactory extends AbstractReactFactory<AppLayerModel, DiagramEngine> {
	constructor() {
		super('app');
	}

	generateModel(event: GenerateModelEvent): AppLayerModel {
		return new AppLayerModel({size: 10});
	}

	generateReactWidget(event: GenerateWidgetEvent<AppLayerModel>): JSX.Element {
		return <AppLayerWidget layer={event.model} engine={this.engine} />;
	}
}
