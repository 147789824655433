import * as React from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

import { RightAngleLinkWidget } from './RightAngleLinkWidget';
import { DefaultLinkFactory } from '../../diagrams-defaults';
import { RightAngleLinkModel, RightAngleLinkModelOptions } from './RightAngleLinkModel';

namespace S {
	export const Keyframes = keyframes`
		from {
			stroke-dashoffset: 24;
		}
		to {
			stroke-dashoffset: 0;
		}
	`;

	const selected = css`
		stroke-dasharray: 10, 2;
		animation: ${Keyframes} 1s linear infinite;
	`;

	const alternate = css`
		stroke-dasharray: 10, 2;
	`;


	export const Path = styled.path<{ selected: boolean, alternate?:boolean }>`
		${(p) => p.selected && selected};
		${(p) => p.alternate && alternate};
		fill: none;
		pointer-events: all;
	`;
}


export class RightAngleLinkFactory extends DefaultLinkFactory<RightAngleLinkModel> {
	static NAME = 'rightAngleLinkFactory';

	constructor() {
		super(RightAngleLinkFactory.NAME);
	}

	generateModel(event): RightAngleLinkModel {
		return new RightAngleLinkModel({});
	}

	generateReactWidget(event): JSX.Element {
		return <RightAngleLinkWidget diagramEngine={this.engine} link={event.model} factory={this} />;
	}
	generateLinkSegment(model: RightAngleLinkModel, selected: boolean, path: string) {
		const contained = model.isContained()
		// const color = (contained) ? model.getOptions().color :  (model.getOptions() as RightAngleLinkModelOptions).alternateColor
		return (
			<S.Path
				selected={selected}
				alternate={!contained}
				stroke={selected ? model.getOptions().selectedColor : model.getOptions().color}
				strokeWidth={model.getOptions().width}
				d={path}
			/>
		);
	}

}
