import { LayerModel, LayerModelGenerics } from '../../../canvas-core';
import { DiagramEngine } from '../..';

export interface AppLayerModelGenerics extends LayerModelGenerics {
  ENGINE: DiagramEngine;
}

export interface AppLayerModelOptions {
}

export class AppLayerModel<G extends AppLayerModelGenerics = AppLayerModelGenerics> extends LayerModel {
  constructor(options?: AppLayerModelOptions) {
		super({
			type: 'app',
			isSvg: false,
			transformed: false
    });
	}

	getChildModelFactoryBank(engine: G['ENGINE']) {
		return engine.getNodeFactories();
	}

}
