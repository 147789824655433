import * as React from 'react';
import { TaskNodeModel, TYPE } from './TaskNodeModel';
import { Task } from './TaskNodeWidget';
import { AbstractReactFactory } from '../../canvas-core';
import { DiagramEngine } from '../../diagrams-core';


export class TaskNodeFactory extends AbstractReactFactory<TaskNodeModel, DiagramEngine> {
	constructor() {
		super(TYPE);
	}

	generateModel(initialConfig) {
		return new TaskNodeModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <Task engine={this.engine as DiagramEngine} node={event.model} container={false} />;
	}
}
