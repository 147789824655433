import * as React from 'react';
import { DiagramEngine } from './diagrams-engine';
import { CanvasWidget } from './canvas-core';
import styled from '@emotion/styled';
import { Tools, defaultActions } from './components/Tools';

namespace S {
	export const Container = styled.div<{ color: string; background: string }>`
		height: 100%;
		background-color: ${p => p.background};
		background-size: 100px 100px;
		display: flex;

		> * {
			height: 100%;
			min-height: 100%;
			width: 100%;
		}
	`;
}


export interface BodyWidgetProps {
	engine: DiagramEngine;
}

export class BodyWidget extends React.Component<BodyWidgetProps> {
	render() {
		return (
			<S.Container color="#ddd" background="#fafafa">
				<CanvasWidget className="diagram-container" engine={this.props.engine} />
			</S.Container>
		);
	}
}
