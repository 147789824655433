import * as React from 'react';
import { IntermediateEventThrowMessageModel, TYPE } from './IntermediateEventThrowMessageNodeModel';
import { StartEventMessageNodeWidget } from './IntermediateEventThrowMessageNodeWidget';
import { AbstractReactFactory } from '../../canvas-core';
import { DiagramEngine } from '../../diagrams-core';


export class IntermediateEventThrowNodeFactory extends AbstractReactFactory<IntermediateEventThrowMessageModel, DiagramEngine> {
	constructor() {
		super(TYPE);
	}

	generateModel(initialConfig) {
		return new IntermediateEventThrowMessageModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <StartEventMessageNodeWidget engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
