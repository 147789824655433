import * as React from 'react';
import { StartEventMessageModel, TYPE } from './StartEventMessageNodeModel';
import { StartEventMessageNodeWidget } from './StartEventMessageNodeWidget';
import { AbstractReactFactory } from '../../canvas-core';
import { DiagramEngine } from '../../diagrams-core';


export class StartEventMessageNodeFactory extends AbstractReactFactory<StartEventMessageModel, DiagramEngine> {
	constructor() {
		super(TYPE);
	}

	generateModel(initialConfig) {
		return new StartEventMessageModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <StartEventMessageNodeWidget engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
