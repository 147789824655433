import * as React from 'react';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '../../../canvas-core';
import { DiagramEngine } from '../../../diagrams-core';
import { LanesLayerWidget } from './LanesLayerWidget';
import { LanesLayerModel } from './LanesLayerModel';

export class LanesLayerFactory extends AbstractReactFactory<LanesLayerModel, DiagramEngine> {
	constructor() {
		super('diagram-lanes');
	}

	generateModel(event: GenerateModelEvent): LanesLayerModel {
		return new LanesLayerModel();
	}

	generateReactWidget(event: GenerateWidgetEvent<LanesLayerModel>): JSX.Element {
		return <LanesLayerWidget layer={event.model} engine={this.engine} />;
	}
}
