import * as _ from 'lodash';
import { DiagramModel } from '../../models/DiagramModel';
import { PortModel } from '../port/PortModel';
import { LinkModel } from '../link/LinkModel';
import { Point, Rectangle } from '../../../geometry';
import {
	BaseEntityEvent,
	BaseModelListener,
	BasePositionModel,
	BasePositionModelGenerics,
	DeserializeEvent
} from '../../../canvas-core';
import { DiagramEngine } from '../../DiagramEngine';

export interface NodeModelListener extends BaseModelListener {
	positionChanged?(event: BaseEntityEvent<NodeModel>): void;
}

export interface NodeModelGenerics extends BasePositionModelGenerics {
	LISTENER: NodeModelListener;
	PARENT: DiagramModel;
	getContainerId: Function;
}

export class NodeModel<G extends NodeModelGenerics = NodeModelGenerics> extends BasePositionModel<G> {
	protected ports: { [s: string]: PortModel };

	// calculated post rendering so routing can be done correctly
	width: number;
	height: number;
	container: boolean;
	containerId?: string;
	editing: boolean;

	constructor(options: G['OPTIONS']) {
		super(options);
		this.ports = {};
		this.width = 0;
		this.height = 0;
		this.editing = false;
	}

	getBoundingBox(): Rectangle {
		return new Rectangle(this.getPosition(), this.width, this.height);
	}

	getContainerId() {
		return this.containerId
	}

	isContained() {
		return Boolean(this.containerId)
	}

	setPosition(point: Point);
	setPosition(x: number, y: number);
	setPosition(x, y?) {
		let old = this.position;
		super.setPosition(x, y);

		// //also update the port co-ordinates (for make glorious speed)
		_.forEach(this.ports, (port) => {
			port.setPosition(port.getX() + x - old.x, port.getY() + y - old.y);
		});
	}
	place(x: number, y: number) {
		// super.setPosition(x , y );
		let old = this.position;
		super.setPosition(x - (this.width / 2) , y - (this.height / 2));
		_.forEach(this.ports, (port) => {
			const newX = port.getX() + ((x - (this.width / 2)) - old.x)
			const newY = port.getY() + ((y - (this.height / 2)) - old.y)
			port.setPosition(newX, newY);
		});
	}

	placeFromActionIcon(x: number, y: number) {
		// super.setPosition(x , y );
		super.setPosition(x - (this.width / 4) , y - (this.height / 2));
		_.forEach(this.ports, (port) => {
			port.setPosition(port.getX() + (x - (this.width / 4)), port.getY() + (y - (this.height / 2)));
		});
	}


	deserialize(event: DeserializeEvent<this>) {
		super.deserialize(event);
		this.containerId = event.data.containerId
		this.editing = event.data.editing
		//deserialize ports
		// _.forEach(event.data.ports, (port: any) => {
		// 	let portOb = (event.engine as DiagramEngine).getFactoryForPort(port.type).generateModel({});
		// 	portOb.deserialize({
		// 		...event,
		// 		data: port
		// 	});
		// 	// the links need these
		// 	event.registerModel(portOb);
		// 	this.addPort(portOb);
		// });
	}

	serialize() {
		return {
			...super.serialize(),
			containerId: this.containerId,
			editing: this.editing,
			ports: _.map(this.ports, (port) => {
				return port.serialize();
			})
		};
	}

	doClone(lookupTable = {}, clone) {
		// also clone the ports
		clone.ports = {};
		_.forEach(this.ports, (port) => {
			clone.addPort(port.clone(lookupTable));
		});
	}

	remove() {
		super.remove();
		_.forEach(this.ports, (port) => {
			_.forEach(port.getLinks(), (link) => {
				link.remove();
			});
		});
	}

	getPortFromID(id): PortModel | null {
		for (var i in this.ports) {
			if (this.ports[i].getID() === id) {
				return this.ports[i];
			}
		}
		return null;
	}

	getLink(id: string): LinkModel {
		for (let portID in this.ports) {
			const links = this.ports[portID].getLinks();
			if (links[id]) {
				return links[id];
			}
		}
	}

	getPort(id: string): PortModel | null {
		return this.ports[id];
	}

	getPorts(): { [s: string]: PortModel } {
		return this.ports;
	}

	removePort(port: PortModel) {
		// clear the port from the links
		for (let link of _.values(port.getLinks())) {
			link.clearPort(port);
		}
		//clear the parent node reference
		if (this.ports[port.getOptions().id]) {
			this.ports[port.getOptions().id].setParent(null);
			delete this.ports[port.getOptions().id];
		}
	}

	addPort(port: PortModel): PortModel {
		port.setParent(this);
		this.ports[port.getOptions().id] = port;
		return port;
	}

	updateDimensions({ width, height }: { width: number; height: number }) {
		this.width = width;
		this.height = height;
	}

	setContainer(containerId:string | null) {
		this.containerId = containerId
	}

	setEditing(editing: boolean = true) {
		if (this.editing !== editing) {
			// console.log('firing!!!!', editing)
			this.editing = editing;
			this.fireEvent(
				{
					editing
				},
				'editingChanged'
			);
		}
	}



}
