import * as React from 'react';
import styled from '@emotion/styled';
import { DiagramEngine } from '../../../diagrams-core';
import { LaneNodeModel } from './LaneNodeModel';
import MODEL_DEFAULTS from '../../../models/BaseNode/constants'
import { ResizePositions } from '../../../state/MoveItemsState'

export const LANE_DEFAULTS = {
  HEIGHT: 200,
  WIDTH: 600,
  TEXT_BOX_SIZE: 20,
  FONT_SIZE: 0.8,
}

namespace S {
  export const LaneLabelBox = styled.div<{ isSelected?: boolean; isLinking?: boolean;width?: number; height?: number; color?: string; background?: string }>`
    background-color: ${p => p.background || MODEL_DEFAULTS.backgoundColor};
    display: flex;
    width: ${props => props.width || (LANE_DEFAULTS.TEXT_BOX_SIZE)}px;
    height: ${props => props.height || LANE_DEFAULTS.HEIGHT}px;
    border-right: solid 2px #ccc;
    border-color: ${props => props.isSelected ? MODEL_DEFAULTS.selectedColor : '#ccc #ccc #ccc #ccc'};

    justify-content: center;
    align-items: center;
    &:hover {
      outline: 5px solid rgba(255, 252, 0, 0.5);
    }
  `;
	export const Lane = styled.div<{ isSelected?: boolean; isLinking?: boolean;width?: number; height?: number; color?: string; background?: string }>`
    position: absolute;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    user-select: none;
    cursor: move;
    pointer-events: all;

    background-color: ${p => p.background || MODEL_DEFAULTS.laneBackgoundColor};
    display: flex;
    width: ${props => (props.width || LANE_DEFAULTS.WIDTH)}px;
    height: ${props => (props.height || LANE_DEFAULTS.HEIGHT)}px;
    border-radius: 2px;
    border: solid 2px #ccc;
    border-color: ${props => props.isSelected ? MODEL_DEFAULTS.selectedColor : '#ccc #ccc #ccc #ccc'};
    justify-content: flex-start;
    align-items: center;
    pointer-events: visiblePainted;
    &:hover {
      outline: 5px solid rgba(255, 252, 0, 0.5);
		}
  `;
  export const LaneLabel = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    font-size: ${LANE_DEFAULTS.FONT_SIZE}rem;
    writing-mode: vertical-lr;
    text-orientation: unset;
  `;
  export const TopLeftControl = styled.div`
    cursor: nw-resize;
    position: absolute;
    top: -20px;
    left: -20px;
    height: 40px;
    width: 40px;
    border: 2px solid #ffff00;
  `;
  export const TopRightControl = styled.div`
    cursor: ne-resize;
    position: absolute;
    top: -20px;
    right: -20px;
    height: 40px;
    width: 40px;
    border: 2px solid #ffff00;
  `;
  export const BottomLeftControl = styled.div`
    cursor: sw-resize;
    position: absolute;
    bottom: -20px;
    left: -20px;
    height: 40px;
    width: 40px;
    border: 2px solid #ffff00;
  `;
  export const BottomRightControl = styled.div`
    cursor: se-resize;
    position: absolute;
    bottom: -20px;
    right: -20px;
    height: 40px;
    width: 40px;
    border: 2px solid #ffff00;
  `;
  export const ResizeControl = styled.div`
    position: relative;
    padding: 0px;
    margin: 14px;
    border: 1px solid rgb(51, 51, 51);
    width: 12px;
    height: 12px;
    pointer-events: none;
  `
}

export interface LaneWidgetProps {
	node: LaneNodeModel;
  engine: DiagramEngine;
  height?: number,
  width?: number,
	children?: any;
}

export interface LaneWidgetState {
  over: boolean,
  resize?: string,
}

export class Lane extends React.Component<LaneWidgetProps, LaneWidgetState> {
	constructor(props: LaneWidgetProps) {
		super(props);
		this.state = {
      over: false,
      resize: null,      
    };
	}

  handleEnter(th: any) {
    return (event: any) => {
      th.setState({
        ...this.state,
        over: true,
      })
    }
  }

  handleOut(th: any){ 
    return (event: any) => {
      th.setState({
        ...this.state,
        over: false,
      })
    }
  }

	render() {
    const width = (this.props.node.width || LANE_DEFAULTS.WIDTH);
    const height = (this.props.node.height || LANE_DEFAULTS.HEIGHT);
    const selected = this.props.node.getOptions().selected;
    const state = this.props.engine.getStateMachine().getCurrentState();
    const isSelected = this.props.node.isSelected()
    // console.log('lane props', this.props, this.props.node.getY(), this.props.node.getX())
		return (
      <S.Lane
        className="lane"
        isSelected={isSelected}
        onMouseEnter={this.handleEnter(this)}
        onMouseLeave={this.handleOut(this)}
        data-containerid={this.props.node.getID()}
        width={width}
        height={height}
        style={{
          top: this.props.node.getY(),
          left: this.props.node.getX(),
        }}
      >
        {isSelected && <>
        <S.TopLeftControl
          data-control="resize"
          data-laneid={this.props.node.getID()}
          data-position="nw"
        >
          <S.ResizeControl />
        </S.TopLeftControl>
        <S.TopRightControl
          data-control="resize"
          data-laneid={this.props.node.getID()}
          data-position="ne"
        >
        <S.ResizeControl />
        </S.TopRightControl>
        <S.BottomLeftControl
          data-control="resize"
          data-laneid={this.props.node.getID()}
          data-position="sw"
        >
          <S.ResizeControl />
        </S.BottomLeftControl>
        <S.BottomRightControl
          data-control="resize"
          data-laneid={this.props.node.getID()}
          data-position="se"
        >
          <S.ResizeControl />
        </S.BottomRightControl>
        </>
        }
        <S.LaneLabelBox height={height}>
        <S.LaneLabel>
          {this.props.node.label}
        </S.LaneLabel>

        </S.LaneLabelBox>

    </S.Lane>);
	}
}
