import { forEach } from 'lodash'
import { NodeModel, LinkModel, PortModel, DefaultPortModel, NodeModelGenerics, PortModelAlignment, PointModel, NodeLayerModel, DiagramModel } from '../../diagrams-engine';
import { BaseModelOptions } from '../../canvas-core';
import { BaseNodePortModel } from './BaseNodePortModel';
import { throws } from 'assert';
import { isEmpty, omit, find } from 'lodash';
import { Point, Rectangle } from '../../geometry'
import { LinkPathOrientation } from '../../diagrams-core/entities/link/RightAngleLinkModel';
import _ from 'lodash';
export interface BaseNodeModelGenerics {
	hovered?: boolean;	
}
export interface BaseNodeModelOptions extends BaseModelOptions {
  type: string;
	color?: string;
	hovered?: boolean;
	width: number;
	height: number;
	label?: string;
}

export class BaseNodeModel extends NodeModel<NodeModelGenerics & BaseNodeModelGenerics> {
	color: string;
	hovered: boolean;
	topPort: BaseNodePortModel;
	bottomPort: BaseNodePortModel;
	leftPort: BaseNodePortModel;
	rightPort: BaseNodePortModel;
	label: string;
	canLink: boolean;

	constructor(options: BaseNodeModelOptions) {
		super({
			...options
		});
		this.color = options.color || 'red';
		this.label = options.label || '';
		this.topPort = new BaseNodePortModel(PortModelAlignment.TOP);
		this.bottomPort = new BaseNodePortModel(PortModelAlignment.BOTTOM);
		this.leftPort = new BaseNodePortModel(PortModelAlignment.LEFT);
		this.rightPort = new BaseNodePortModel(PortModelAlignment.RIGHT);
		this.addPort(this.topPort);
		this.addPort(this.bottomPort);
		this.addPort(this.leftPort);
		this.addPort(this.rightPort);
		this.width = options.width;
		this.height = options.height;
		this.canLink = true
	}

	serialize() {
		return {
			...super.serialize(),
			color: this.color,
			label: this.label,
		};
	}

	deserialize(event) {

		//deserialize ports
		this.ports = {}
		_.forEach(event.data.ports, (port: any) => {
			const portMethod = `${port.name}Port`
			if (port.default) {
				this[portMethod] = new BaseNodePortModel(port.name);
				this[portMethod].deserialize({
					...event,
					data: port
				});
				event.registerModel(this[portMethod]);
				this.addPort(this[portMethod]);
			} else {
				const otherPort = new BaseNodePortModel(port.name);
				otherPort.deserialize({
					...event,
					data: port
				});
				event.registerModel(otherPort);
				otherPort.setPosition(new Point(port.x + port.portRadius, port.y + port.portRadius))
				this.addPort(otherPort);
				
			}
		})
		this.label = event.data.label;
		super.deserialize(event);
	}


	links():{ [s: string]: LinkModel } {
		const ports = this.getPorts()
		return Object.keys(ports).reduce((accum, portName) => {
			const links = ports[portName].links
			if (!isEmpty(links)) {
				accum = {
					...accum,
					...links,
				}
			}
			return accum
		}, {})
	}

	setHovered(hovered: boolean = true) {
		if (this.hovered !== hovered) {
			this.hovered = hovered;
			this.fireEvent(
				{
					isHovered: hovered
				},
				'hoveredChanged'
			);
		}
	}

	linkedAs(linkToFind: LinkModel): 'source' | 'target' | null {
		const source = this.links()[linkToFind.getID()].getSourcePort().getParent()
		if (this === source) {
			return 'source'
		}
		const target = this.links()[linkToFind.getID()].getTargetPort().getParent()
		if (this === target) {
			return 'target'
		}
		return null
	}

	linkedPorts(): {[s: string]: PortModel} {
		const actualPorts = this.getPorts()
		return Object.keys(actualPorts).reduce((accum, port: string) => {
			const haveLinks = Boolean(Object.keys(actualPorts[port].getLinks()).length > 0)
			if (haveLinks) {
				accum[port] = actualPorts[port]
			}
			return accum
		}, {})
	}

	lineDirection(startPoint:PointModel, endPoint: PointModel):LinkPathOrientation {
		if (startPoint.getX() === endPoint.getX()) {
			return LinkPathOrientation.VERTICAL
		} else if (startPoint.getY() === endPoint.getY()) {
			return LinkPathOrientation.HORIZONTAL
		} else {
			return LinkPathOrientation.UNKNOWN
		}
	}

	center() {
    const x = this.getX() + (this.width / 2);
    const y = this.getY() + (this.height / 2);
    return { x, y }
  }

	portFinder(clientX: number, clientY: number): BaseNodePortModel {
		const alignment = this.portAlignmentFinder(clientX, clientY)
		return this.portByAlignment(alignment);
	}

	portByAlignment(alignment: PortModelAlignment) {
		switch (alignment) {
			case PortModelAlignment.TOP:
				return this.topPort
			case PortModelAlignment.BOTTOM:
				return this.bottomPort
			case PortModelAlignment.LEFT:
				return this.leftPort
			case PortModelAlignment.RIGHT:
				return this.rightPort
		}
		// const ports = this.getPorts() as {
		// 	[s: string]: BaseNodePortModel;
		// };
		// const found = find(ports, (port:BaseNodePortModel) => {
		// 	return (port.getOptions().alignment === alignment && port.defaultLocation)
		// })

		// return found;
	}

  portAlignmentFinder(clientX: number, clientY: number): PortModelAlignment { 
    const parent = this;
    parent.height
    parent.width

    const { x: centerX, y: centerY } = this.center(); 
    const movedClientX = clientX - centerX;
    const movedClientY = clientY - centerY;
    const ratio = parent.width / parent.height;
    if (movedClientX > 0) {
      if (movedClientY > 0) {
        if (movedClientY * ratio > movedClientX) {
          return PortModelAlignment.BOTTOM;
        } else {
          return PortModelAlignment.RIGHT;
        }
      } else if (Math.abs(movedClientY) * ratio > movedClientX) {
        return PortModelAlignment.TOP;
      } else {
        return PortModelAlignment.RIGHT;
      }
    } else if (movedClientY > 0) {
      if (movedClientY * ratio > Math.abs(movedClientX)) {
        return PortModelAlignment.BOTTOM;
      } else {
        return PortModelAlignment.LEFT;
      }
    } else {
      if (Math.abs(movedClientY) * ratio > Math.abs(movedClientX)) {
        return PortModelAlignment.TOP;
      } else {
        return PortModelAlignment.LEFT;
      }
    }
	}

	portFinderForLink(link: LinkModel):BaseNodePortModel {
		if (!link) return null
		const lastLine = link.lastLine()
		const direction = LinkModel.lineDirection(lastLine[0], lastLine[1])
		if (direction === LinkPathOrientation.HORIZONTAL) {
			const entersFrom: PortModelAlignment = (lastLine[0].getX() <= lastLine[1].getX()) ? PortModelAlignment.LEFT : PortModelAlignment.RIGHT
			return this.portByAlignment(entersFrom)
		} else if (direction === LinkPathOrientation.VERTICAL) {
			const entersFrom: PortModelAlignment = (lastLine[0].getY() <= lastLine[1].getY()) ? PortModelAlignment.TOP : PortModelAlignment.BOTTOM
			return this.portByAlignment(entersFrom)
		} else {
			return this.portFinder(lastLine[1].getX(), lastLine[1].getY())
		}
	}

	removeLink(link: LinkModel) {
		delete this.links[link.getID()];
	}
	containsPointWithPadding(point: Point, padding: number): boolean {
		const withPadding = new Rectangle(this.getX() - padding, this.getY() - padding, this.width + (padding * 2), this.height + (padding * 2))
		const rect = withPadding.getBoundingBox()
		return rect.containsPoint(point)
	}
	containsPoint(point: Point):boolean {
		const rect = this.getBoundingBox()
		return rect.containsPoint(point)
	}

	portPosition(alignment: PortModelAlignment, width: number): Point {
		switch (alignment) {
			case PortModelAlignment.TOP:
				return new Point(this.getX() + (this.width / 2) - (width / 2), this.getY())
			case PortModelAlignment.BOTTOM:
				return new Point(this.getX() + (this.width / 2) - (width / 2), this.getY() + this.height)
			case PortModelAlignment.LEFT:
				return new Point(this.getX(), this.getY() + (this.height / 2) - (width / 2))
			case PortModelAlignment.RIGHT:
				return new Point(this.getX() + this.width, this.getY() + (this.height / 2) - (width / 2))
			default:
				return new Point(this.getX(), this.getY())
		}
	}

	linkSuplement(port: BaseNodePortModel, dragCoordinate: 'x'|'y', d:number):number {
		const opposite = (dragCoordinate === 'x') ? 'y' : 'x'
		port.setOffset(opposite, 0)
		return 0
	}

	setLabel(newLabel: string) {
		this.label = newLabel
	}

	public static isSelfLinking(node: NodeModel, element?: BaseNodeModelGenerics | NodeModel | PortModel ) {
    return element && element instanceof BaseNodeModel && element === node
	}

}
