import * as React from 'react';
import styled from '@emotion/styled';
import { BaseNodeModel } from '../../models/BaseNode/BaseNodeModel';

export interface ActionItem {
  name: string;
  id: string;
  code?: string;
  svg?: string;
  size: number;
  enabled: boolean;
}

const padding = 4;
const border = 2;

const Container = styled.div<{backgroundColor: string, width: number, items: number, top: number, left: number}>`
position: absolute;
background-color: ${(p) => p.backgroundColor};
top: ${(p) => `${p.top}px`};
left: ${(p) => `${p.left}px`};
border: ${border}px solid #ddd;
padding: ${() => `${padding}px`};
width: ${(p) => `${p.width + padding * 2}px`};
min-width: ${(p) => `${p.width + padding * 2}px`};
height: ${(p) => `${(p.width * p.items) + (padding * 4) + (border * 2)}px`};
min-height: ${(p) => `${(p.width * p.items) + (padding * 4) + (border * 2)}px`};
border-radius: 5px;
cursor: pointer;
z-index: 10000;
&:hover {
  height: ${(p) => `${9 + (p.width * p.items) + (padding * 4) + (border * 2)}px`};

}
`

const Icon = styled.div`
`

const Action = styled.div<{}>`
`

const deleteActionItem = {
  name: 'delete',
  id: 'delete',
  code: '&#xe801;',
  enabled: true,
}

const taskActionItem = {
  name: 'task',
  id: 'task-node',
  code: '&#xe821;',
  enabled: true,
  description: 'Chunk of business logic',
}

const handlerTaskActionItem = {
  name: 'handler task',
  id: 'handler-task-node',
  code: '&#xe82a;',
  enabled: true,
}

const startEventActionItem = {
  name: 'event start',
  id: 'start-event-node',
  code: '&#xe845;',
  enabled: true,
  description: 'Start event'
}

const eventCancelActionItem = {
  name: 'event cancel',
  id: 'eventCancel',
  code: '&#xe811;',
  enabled: true,
}

const eventTerminateActionItem = {
  name: 'event terminate',
  id: 'eventTerminate',
  code: '&#xe836;',
  enabled: true,
}

const startEventMessageActionItem = {
  name: 'Catching start event',
  id: 'start-event-message-node',
  code: '&#xe82c;',
  enabled: true,
  description: 'Catching start event',
}


const intermediateEventCatchMessageActionItem = {
  name: 'Catch event',
  id: 'intermediate-event-catch-message-node',
  code: '&#xe83c;',
  enabled: true,
  description: 'waits for incomming message'
}


const intermediateEventThrowMessageActionItem = {
  name: 'Throw event',
  id: 'intermediate-event-throw-message-node',
  code: '&#xe850;',
  enabled: true,
  description: 'sends outgoing message',
}

const endEventMessageActionItem = {
  name: 'Throwing end event',
  id: 'end-event-message-node',
  code: '&#xe83a;',
  enabled: true,
  description: 'Throwing end event',
}

const startEventTimerActionItem = {
  name: 'start event timer',
  id: 'startEventTime',
  code: '&#xe82b;',
  enabled: true,
}

const intermediateEventCatchTimerActionItem = {
  name: 'intermediate event catch timer',
  id: 'intermediate-event-catch-timer-node',
  code: '&#xe824;',
  enabled: true,
}

const gatewayNoneActionItem = {
  name: 'Decider',
  id: 'gateway-node',
  code: '&#xe834;',
  enabled: true,
  description: 'branches out different paths',
}

const laneActionItem = {
  name: 'lane',
  id: 'lane-node',
  code: '&#xe85c;',
  enabled: true,
}

const connectionActionItem = {
  name: 'connection',
  id: 'connection',
  code: '&#xe810;',
  enabled: true,
}
const terminusEventActionItem = {
  name: 'Terminus event',
  id: 'terminus-event-node',
  code: '&#xe838;',
  enabled: true,
  description: 'here the flows end',
}

const waitingForUserActionItem = {
  name: 'Waiting for User and send Message',
  id: 'waiting-for-user-node',
  code: '&#xe844;',
  enabled: true,
  description: 'waiting for user input, then sending a message',
}
const functionTaskActionItem = {
  name: 'Function Task',
  id: 'function-task-node',
  code: '&#xe84d;',
  enabled: true,
  description: 'missing description',
}



export const defaultActions = {
  // delete: deleteActionItem,
  task: taskActionItem,
  handlerTask: handlerTaskActionItem,
  eventStart: startEventActionItem,
  waitingForUser: waitingForUserActionItem,
  functionTask: functionTaskActionItem,
  // eventCancel: eventCancelActionItem,
  // eventTerminate: eventTerminateActionItem,
  startEventMessage: startEventMessageActionItem,
  intermediateEventCatchMessage: intermediateEventCatchMessageActionItem,
  intermediateEventThrowMessage: intermediateEventThrowMessageActionItem,
  endEventMessage: endEventMessageActionItem,
  // startEventTimer: startEventTimerActionItem,
  intermediateEventCatchTimer: intermediateEventCatchTimerActionItem,
  gatewayNone: gatewayNoneActionItem,
  terminusEvent: terminusEventActionItem,
  'lane-node': laneActionItem,
  // connection: connectionActionItem,
}



const withKeys = (collection: []) => (fn: Function) => {
  return Object.keys(collection).map((key: string, index) => {
    return fn(collection[key], key, index)
  })
}

declare interface ToolsProps {
  left: number,
  top: number,
  backgroundColor: string,
  visible: boolean,
  actions: [ActionItem];
  node: BaseNodeModel;
}

const calculatePosition = (node: BaseNodeModel) => {
  const margin = 2;
  const x = node.getX();
  const y = node.getY();
  const {width, height} = node;
  const left = width + margin;
  const top = margin;
  return { left, top };
}


export const Tools = props => {
  const { actions, backgroundColor, visible, node } = props;
  if (!visible) return null;
  // console.log('node', node, calculatePosition(node))
  const { top, left } = calculatePosition(node);
  return (
    <Container
      top={top}
      left={left}
      backgroundColor={backgroundColor}
      width={18}
      items={Object.keys(actions).length}
    >
      {withKeys(actions)((action: ActionItem, key: string) =>
        <Action key={action.id}>
          <Icon>
            <i data-menuid={action.id} data-modelid={node.getOptions().id} className="demo-icon" dangerouslySetInnerHTML={{ __html: action.code }} />
          </Icon>
        </Action>
      )}
    </Container>
  )
}
