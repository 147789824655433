import * as React from 'react';
import styled from '@emotion/styled';
import { DiagramEngine } from '../../diagrams-core';
import BasePortWidget from '../Port/BasePortWidget'
import { TaskNodeModel } from './TaskNodeModel';
import { Tools, defaultActions } from '../../components/Tools';
import { CreateLinkState } from '../../state/Link/CreateLinkState';
import MODEL_DEFAULTS from '../BaseNode/constants'
import { CreateLinkWithEntityState } from '../../state/Link/CreateLinkWithEntityState';

export const DEFAULTS = {
  HEIGHT: 60,
  WIDTH: 100,
  PORT_RADIUS: 10,
  FONT_SIZE: 0.8,
}

//     box-shadow: 0 0 2px #333;


namespace S {
	export const Task = styled.div<{ editing?: boolean; isSelected?: boolean; isLinking?: boolean;width?: number; height?: number; color?: string; background?: string }>`
    background-color: ${p => p.background || MODEL_DEFAULTS.backgoundColor};
    display: flex;
    width: ${props => props.width || DEFAULTS.WIDTH}px;
    height: ${props => props.height || DEFAULTS.HEIGHT}px;
    border-radius: 4px;
    border: solid 2px #ccc;
    border-color: ${props => props.isSelected ? MODEL_DEFAULTS.selectedColor : '#ccc #ccc #ccc #ccc'};
    justify-content: center;
    align-items: center;
    outline: ${props => props.editing ? '5px solid rgba(224, 0, 255, 0.5)' : ''};
    &:hover {
      outline: 5px solid rgba(255, 252, 0, 0.5);
		}
  `;
  export const TaskLabel = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    font-size: ${DEFAULTS.FONT_SIZE}rem;
  `;

}

export interface TaskWidgetProps {
	node: TaskNodeModel;
  engine: DiagramEngine;
  height?: number,
  width?: number,
}

export interface TaskWidgetState {
  over: boolean
}

export class Task extends React.Component<TaskWidgetProps, TaskWidgetState> {
	constructor(props: TaskWidgetProps) {
		super(props);
		this.state = {
      over: false,
    };
	}

  handleEnter(th: any) {
    return (event: any) => {
      th.setState({
        over: true,
      })
    }
  }

  handleOut(th: any){ 
    return (event: any) => {
      th.setState({
        over: false,
      })
    }
  }

	render() {
    const width = (this.props.width || DEFAULTS.WIDTH);
    const height = (this.props.height || DEFAULTS.HEIGHT);
    const editing = this.props.node.editing;
    const state = this.props.engine.getStateMachine().getCurrentState();
    const ports = this.props.node.getPorts()
    const isLinking = (state instanceof CreateLinkState || state instanceof CreateLinkWithEntityState) ? state.isLinking : false;
    const isSelected = this.props.node.isSelected()
		return (
      <S.Task
        isLinking={isLinking}
        isSelected={isSelected}
        onMouseEnter={this.handleEnter(this)}
        onMouseLeave={this.handleOut(this)}
        width={this.props.node.width}
        height={this.props.node.height}
        editing={editing}
      >
      {Object.keys(ports).map((id) =>{ 
        return (<BasePortWidget
        key={id}
        id={id}
        isLinking={isLinking}
        port={ports[id]}
        engine={this.props.engine}
        node={this.props.node}
        width={width}
        height={height}
        alignment={ports[id].getOptions().alignment}
      />)})
      }
        <S.TaskLabel>
          {this.props.node.label}
        </S.TaskLabel>
        <Tools
          visible={!isLinking && this.state.over}
          actions={defaultActions}
          backgroundColor="rgba(239,239,239,20)"
          node={this.props.node}
          top={10}
          left={10}
        />

    </S.Task>);
	}
}
