import { LayerModel } from '../layer/LayerModel';
import { FactoryBank } from '../../core/FactoryBank';
import { AbstractModelFactory } from '../../core/AbstractModelFactory';
import { BaseModel } from '../../core-models/BaseModel';
import { BaseNodeModel } from '../../../models/BaseNode/BaseNodeModel';

export class EditingLayerModel extends LayerModel {
  model: BaseNodeModel
  constructor() {
		super({
			transformed: false,
			isSvg: false,
			type: 'editing'
		});
	}
	setModel(model: BaseNodeModel) {
		this.model = model;
	}
	getModel():BaseNodeModel {
		return this.model;
	}

	getChildModelFactoryBank(): FactoryBank<AbstractModelFactory<BaseModel>> {
		// is not used as it doesnt serialize
		return null;
	}
}
