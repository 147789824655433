import * as React from 'react';
import { HandlerTaskNodeModel, TYPE } from './TaskNodeModel';
import { Task } from './TaskNodeWidget';
import { AbstractReactFactory } from '../../canvas-core';
import { DiagramEngine } from '../../diagrams-core';

export class HandlerTaskNodeFactory extends AbstractReactFactory<HandlerTaskNodeModel, DiagramEngine> {
	constructor() {
		super(TYPE);
	}

	generateModel(initialConfig) {
		return new HandlerTaskNodeModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <Task engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
