import { omit } from 'lodash'

const localFS = (key = 'localfs') => {
  const readData = () => {
    const stringData = window.localStorage.getItem(key) || '{}'
    return JSON.parse(stringData);
  }
  let data = readData()

  const write = (name: string, content: any) => {    
    let updatedData = readData()
    updatedData[name] = content
    writeData(updatedData)
  }

  const dir = () => {
    return readData()
  }

  const dirToArray = (dir: any) => {
    return Object.keys(dir).map((key: string) => ({name: key, data: dir[key]}))
  }

  const read = (name: string) => {
    return readData()[name]
  }

  const del = (name: string) => {
    const updatedData = omit(readData(), [name])
    writeData(updatedData)
    return updatedData
  }

  const clean = () => {
    writeData({})
  }

  const writeData = (updated: any) => {
    window.localStorage.setItem(key, JSON.stringify(updated));
  }

  const destroy = () => {
    window.localStorage.removeItem(key)
  }
  return {
    destroy,
    dir,
    read,
    write,
    del,
    clean,
    dirToArray,
  }
}

export default localFS;
