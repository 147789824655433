import { BaseModelOptions } from '../../canvas-core';
import { PortModelAlignment } from '../../diagrams-core';
import { BaseNodeModel } from '../BaseNode/BaseNodeModel'
import { BaseNodePortModel } from '../BaseNode/BaseNodePortModel';

export const TYPE = 'gateway-node';

export interface GatewayNodeModelGenerics {
}
export interface GatewayNodeModelOptions extends BaseModelOptions {
	color?: string;
	label?: string;
	width?: number;
	height?: number;
}

export class GatewayNodeModel extends BaseNodeModel {
	color: string;
	label: string;

	constructor(options: GatewayNodeModelOptions) {
		super({
			type: TYPE,
			width: 60,
			height: 60,
			...options
		});
		this.label = options.label;
	}
	linkSuplement(port: BaseNodePortModel, dragCoordinate: 'x'|'y', currentDelta:number):number {
		const position = port.getPosition()
		const opposite = (dragCoordinate === 'x') ? 'y' : 'x'
		const alignment = port.getOptions().alignment
		const sign = (alignment === PortModelAlignment.RIGHT || alignment === PortModelAlignment.BOTTOM) ? -1 : 1
		port.setOffset(opposite, currentDelta * sign)		
		// console.log('suplement', currentDelta * sign )	
		return currentDelta * sign 
	}

}
