import { BaseModelOptions } from '../../canvas-core';
import { BaseNodeModel } from '../BaseNode/BaseNodeModel'

export const TYPE = 'handler-task-node';

export interface HandlerTaskNodeModelGenerics {
}
export interface HandlerTaskNodeModelOptions extends BaseModelOptions {
	color?: string;
	label?: string;
	width?: number;
	height?: number;
}

export class HandlerTaskNodeModel extends BaseNodeModel {
	color: string;
	label: string;

	constructor(options: HandlerTaskNodeModelOptions) {
		super({
			type: TYPE,
			width: 200,
			height: 60,
		});
		this.label = options.label;
	}
}
