import * as React from 'react';
import styled from '@emotion/styled';
import * as _ from 'lodash';
import { DiagramEngine } from '../../../diagrams-core';
import { GridLayerModel } from './GridLayerModel';

export interface GridLayerWidgetProps {
	layer: GridLayerModel;
	engine: DiagramEngine;
}

namespace S {
	export const Container = styled.div<{ size: number, top: number, left: number, x: number, y: number}>`
	position: relative;
	background-image: linear-gradient(0deg, transparent 0%, transparent 90%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(90deg, transparent 0%, transparent 90%, rgba(0, 0, 0, 0.1) 100%);
	background-size: ${p => `${p.size}px ${p.size}px;` }
	height: 100%;
	width: 100%;
	top: ${p => `${p.y}px`};
	left: ${p => `${p.x}px`};
	`;
}

export class GridLayerWidget extends React.Component<GridLayerWidgetProps> {
	render() {
		const size = this.props.layer.size * (this.props.layer.getParent().getZoomLevel() / 100)
		const left = this.props.layer.offsetX % size
		const top = this.props.layer.offsetY % size
		return (
			<S.Container size={size} top={top} left={left} x={left} y={top}/>
		);
	}
}
