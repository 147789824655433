
import { DiagramModel, DiagramEngine } from '../diagrams-engine';
import { DefaultState } from '../state/Default/DefaultState';
import { EventNodeModel as Event } from '../models/Event/EventNodeModel';
import { GRID_SIZE } from '../diagrams-core/models/DiagramModel'

const NewDiagram = (engine: DiagramEngine) => {
  // create a diagram model
  const model = new DiagramModel({name: `new diagram-${Date.now()}`});
  model.setGridSize(GRID_SIZE);

  const event = new Event({ label: 'Start' })
  event.place(300, 400);

  model.addAll(event);
  engine.setModel(model);
  engine.getStateMachine().pushState(new DefaultState());
  return engine
}

export default NewDiagram
