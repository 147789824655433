import * as React from 'react';
import styled from '@emotion/styled';

import { DiagramEngine } from '../../diagrams-core';
import BasePortWidget from '../Port/BasePortWidget'
import { FunctionTaskNodeModel } from './FunctionTaskNodeModel';
import { Tools, defaultActions } from '../../components/Tools';
import { CreateLinkState } from '../../state/Link/CreateLinkState';

import MODEL_DEFAULTS from '../BaseNode/constants'

export const DEFAULTS = {
  HEIGHT: 60,
  WIDTH: 100,
  PORT_RADIUS: 10,
  FONT_SIZE: 0.8,
}

namespace S {
	export const Task = styled.div<{ editing?: boolean; isSelected?: boolean; isLinking?: boolean;width?: number; height?: number; color?: string; background?: string }>`
    background-color: ${p => p.background || MODEL_DEFAULTS.backgoundColor};
    display: flex;
    flex-direction: column;
    width: ${props => props.width || DEFAULTS.WIDTH}px;
    height: ${props => props.height || DEFAULTS.HEIGHT}px;
    border-radius: 4px;
    border: solid 2px #aaa;
    border-color: ${props => props.isSelected ? MODEL_DEFAULTS.selectedColor : '#ccc #ccc #ccc #ccc'};
    outline: ${props => props.editing ? '5px solid rgba(224, 0, 255, 0.5)' : ''};
    justify-content: center;
    align-items: center;
    &:hover {
      outline: 5px solid rgba(255, 252, 0, 0.5);
		}
  `;
  export const TaskLabel = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    position: relative;
    font-size: ${DEFAULTS.FONT_SIZE}rem;
  `;

  export const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: start;
    justify-content: space-between;
  `
  export const Icon = styled.i`
    color: #333;
    font-family: "bpmn";
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1.5rem;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1.5rem;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `

}

export interface TaskWidgetProps {
	node: FunctionTaskNodeModel;
  engine: DiagramEngine;
  height?: number,
  width?: number,
}

export interface TaskWidgetState {
  over: boolean
}

const startMessage = '&#xe829;'
const message = '&#xe841;'

export class Task extends React.Component<TaskWidgetProps, TaskWidgetState> {
	constructor(props: TaskWidgetProps) {
		super(props);
		this.state = {
      over: false,
    };
	}

  handleEnter(th: any) {
    return (event: any) => {
      th.setState({
        over: true,
      })
    }
  }

  handleOut(th: any){ 
    return (event: any) => {
      th.setState({
        over: false,
      })
    }
  }


	render() {
    const width = (this.props.width || DEFAULTS.WIDTH);
    const height = (this.props.height || DEFAULTS.HEIGHT);
    
    const state = this.props.engine.getStateMachine().getCurrentState();
    const ports = this.props.node.getPorts()
    const isLinking = (state instanceof CreateLinkState) ? state.isLinking : false;
    const isSelected = this.props.node.isSelected()
    const editing = this.props.node.editing;
		return (
      <S.Task
        isLinking={isLinking}
        isSelected={isSelected}
        onMouseEnter={this.handleEnter(this)}
        onMouseLeave={this.handleOut(this)}
        editing={editing}
        >
        <S.IconContainer>
          <S.Icon dangerouslySetInnerHTML={{ __html: startMessage }} color="#ccc" />
          <S.Icon dangerouslySetInnerHTML={{ __html: message }} color="#ccc" />
        </S.IconContainer>
        {Object.keys(ports).map((id) =>{ 
          return (<BasePortWidget
          key={id}
          id={id}
          isLinking={isLinking}
          port={ports[id]}
          engine={this.props.engine}
          node={this.props.node}
          width={width}
          height={height}
          alignment={ports[id].getOptions().alignment}
        />)})
        }
        <S.TaskLabel>
          {this.props.node.label}
        </S.TaskLabel>
        <Tools
          visible={this.state.over && !isLinking}
          actions={defaultActions}
          backgroundColor="rgba(239,239,239,20)"
          node={this.props.node}
          top={10}
          left={10}
        />

    </S.Task>);
	}
}
