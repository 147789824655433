import {
	Action,
	ActionEvent,
	InputType,
	CanvasEngine,
} from '../../canvas-core';
import { forEach } from 'lodash';
import { PointModel, PortModel, LinkModel } from '../../diagrams-core';
import { MouseEvent } from 'react';
import { BaseNodeModel } from '../../models/BaseNode/BaseNodeModel'
import { MoveItemsState } from '../MoveItemsState'
import { RightAngleLinkModel } from '../../routing/link/RightAngleLinkModel';

export class DragDiagramItemsState extends MoveItemsState<CanvasEngine> {
	prevX: number;
	prevY: number;
	constructor() {
		super();
		this.registerAction(
			new Action({
				type: InputType.MOUSE_UP,
				fire: (event: ActionEvent<MouseEvent>) => {
					const item = this.engine.getMouseElement(event.event);
					if (item instanceof PortModel) {
						forEach(this.initialPositions, (position) => {
							if (position.item instanceof PointModel) {
								const link = position.item.getParent() as LinkModel;

								// only care about the last links
								if (link && link.getLastPoint() !== position.item) {
									return;
								}
								if (link.getSourcePort().canLinkToPort(item)) {
									link.setTargetPort(item);
									item.reportPosition();
									this.engine.repaintCanvas();
								}
							}
						});
					}
					return
				}
			})
		);
    this.registerAction(
			new Action({
				type: InputType.MOUSE_MOVE,
				fire: (event: ActionEvent<MouseEvent>) => {
					const item = this.engine.getMouseElement(event.event);
					if (item && item instanceof RightAngleLinkModel) {
						return
					}
          if (item && item instanceof BaseNodeModel) {
						
						// const links = item.links()
						// const pointsToUpdate = mapKeys(links, (link: LinkModel, id:string ):any => {
						// 	// const ports = link.get
						// 	// const portLocation = link.getSourcePort().
						// 	const modelId = item.getID();
						// 	const sourceModelId = link.getSourcePort().getParent().getOptions().id;
						// });
						return
          }
				}
			})
		);
	}
}
