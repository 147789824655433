import { LinkModel, PointModel, PortModelAlignment, PortModelGenerics, PortModelOptions } from '../../diagrams-core';
import { AbstractModelFactory, DeserializeEvent } from '../../canvas-core';
import { PortModel } from '../../diagrams-engine';
import { RightAngleLinkModel } from '../../routing/link/RightAngleLinkModel'
import { LinkPathOrientation } from '../../diagrams-core/entities/link/RightAngleLinkModel';

export const DEFAULTS = {
  PORT_RADIUS: 10,
}

export class BaseNodePortModel extends PortModel <PortModelGenerics> {
	portRadius: number
	alignment: PortModelAlignment
	defaultLocation: boolean
	shapeOffsetX: number
	shapeOffsetY: number
	constructor(alignment: PortModelAlignment, defaultLocation = true) {
		super({
			type: 'port',
			name: alignment,
			alignment: alignment
		});
		this.shapeOffsetX = 0
		this.shapeOffsetY = 0
		this.defaultLocation = defaultLocation
	}
	lineDirection(startPoint:PointModel, endPoint: PointModel):LinkPathOrientation {
		if (startPoint.getX() === endPoint.getX()) {
			return LinkPathOrientation.VERTICAL
		} else if (startPoint.getY() === endPoint.getY()) {
			return LinkPathOrientation.HORIZONTAL
		} else {
			return LinkPathOrientation.UNKNOWN
		}
	}
	linkDirection():LinkPathOrientation {
		const align = this.getOptions().alignment;
		if (align === PortModelAlignment.LEFT || align === PortModelAlignment.RIGHT) {
			return LinkPathOrientation.HORIZONTAL
		}
		return LinkPathOrientation.VERTICAL
	}
	createLinkModel(factory?): LinkModel {
		return new RightAngleLinkModel({ alignment: this.getOptions().alignment}); // <-- here we generate a DefaultLinkModel
	}
	link<T extends LinkModel>(port: PortModel, factory?: AbstractModelFactory<T>): T {
		let link = this.createLinkModel(factory);
		link.setSourcePort(this);
		link.setTargetPort(port);
		return link as T;
	}
	setOffset(coordinate: 'x'|'y', offset: number) {
		// console.log('set offset', this.getID(), coordinate, offset)
		// if (offset === 0) {
		// 	this.defaultLocation = true
		// } else {
		// 	this.defaultLocation = false
		// }
		if (coordinate === 'x') {
			this.shapeOffsetX = offset
		} else {
			this.shapeOffsetY = offset
		}
	}

	deserialize(event: DeserializeEvent<this>) {
		super.deserialize(event);
		this.defaultLocation = event.data.default
		this.shapeOffsetX = event.data.shapeOffsetX
		this.shapeOffsetY = event.data.shapeOffsetY
		this.portRadius = event.data.portRadius
	}

	serialize() {
		return {
			...super.serialize(),
			default: this.defaultLocation,
			portRadius: this.portRadius,
			shapeOffsetX: this.shapeOffsetX,
			shapeOffsetY: this.shapeOffsetY,
		};
	}

}
