import * as React from 'react';
import styled from '@emotion/styled';
import { DiagramEngine } from '../../diagrams-core';
import BasePortWidget from '../Port/BasePortWidget'
import { IntermediateEventCatchMessageModel } from './IntermediateEventCatchMessageNodeModel';
import { Tools, defaultActions } from '../../components/Tools';
import { CreateLinkState } from '../../state/Link/CreateLinkState';
import MODEL_DEFAULTS from '../BaseNode/constants'

export const DEFAULTS = {
  HEIGHT: 40,
  WIDTH: 40,
  PORT_RADIUS: 10,
  FONT_SIZE: 0.8,
}

namespace S {
  export const Circle = styled.div<{ editing?: boolean;isLinking?: boolean;width?: number; height?: number; color?: string; background?: string }>`
    position: relative;
    width: ${props => props.width || DEFAULTS.WIDTH}px;
    height: ${props => props.height || DEFAULTS.HEIGHT}px;
    outline: ${props => props.editing ? '5px solid rgba(224, 0, 255, 0.5)' : ''};
    &:hover {
      outline: 5px solid rgba(255, 252, 0, 0.5);
		}
  `

  export const EventLabel = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    font-size: ${DEFAULTS.FONT_SIZE}rem;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  `;

}

export interface EventWidgetProps {
	node: IntermediateEventCatchMessageModel;
  engine: DiagramEngine;
  height?: number,
  width?: number,
}

export interface TaskWidgetState {
  over: boolean
}

export class StartEventMessageNodeWidget extends React.Component<EventWidgetProps, TaskWidgetState> {
	constructor(props: EventWidgetProps) {
		super(props);
		this.state = {
      over: false,
    };
	}

  handleEnter(th: any) {
    return (event: any) => {
      th.setState({
        over: true,
      })
    }
  }

  handleOut(th: any){ 
    return (event: any) => {
      th.setState({
        over: false,
      })
    }
  }


	render() {
    const width = (this.props.width || DEFAULTS.WIDTH);
    const height = (this.props.height || DEFAULTS.HEIGHT);
    const selected = this.props.node.getOptions().selected;
    const state = this.props.engine.getStateMachine().getCurrentState();
    const ports = this.props.node.getPorts()
    const editing = this.props.node.editing;

    // if (state instanceof CreateLinkState) {
    //   console.log('CreateLinkState')
    // } else if (state instanceof DefaultState) {
    //   console.log('DefaultState')
    // } else if (state instanceof DragLinkState) {
    //   console.log('DragLinkState')
    // }
    const isLinking = (state instanceof CreateLinkState) ? state.isLinking : false;
    const isSelected = this.props.node.isSelected()
		return (

      <S.Circle
        isLinking={isLinking}
        onMouseEnter={this.handleEnter(this)}
        onMouseLeave={this.handleOut(this)}
        editing={editing}
        title="Intermediate event catch message"
      >
        <S.EventLabel>
          {this.props.node.label}
        </S.EventLabel>

      <svg
        width={width}
        height={height}
        dangerouslySetInnerHTML={{
          __html:
            `
        <g id="Layer_1">
        </g>
        <g id="Layer_2">
          <circle cx="${width / 2}" cy="${width / 2}" r="${(width / 2) - 2}" fill="${MODEL_DEFAULTS.backgoundColor}" stroke="${
            isSelected ? MODEL_DEFAULTS.selectedColor : '#aaa'
          }" stroke-width="2"/>
          <circle cx="${width / 2}" cy="${width / 2}" r="${(width / 2) - 6}" fill="${MODEL_DEFAULTS.backgoundColor}" stroke="${
            isSelected ? MODEL_DEFAULTS.selectedColor : '#aaa'
          }" stroke-width="2"/>
          <path d="m ${(width / 2) - 7},${(height / 2) - 5} l 0,10 l 14,0 l 0,-10 z l 7,5.2 l 7,-5.4" style="fill: white; stroke-width: 1px; stroke: black;"></path>
        </g>
      `
        }}
      />

      {Object.keys(ports).map((id) =>{ 
        return (<BasePortWidget
          key={id}
          id={id}
          isLinking={isLinking}
          port={ports[id]}
          engine={this.props.engine}
          node={this.props.node}
          width={width}
          height={height}
          alignment={ports[id].getOptions().alignment}
        />
      )})}
        <Tools
          visible={this.state.over && !isLinking}
          actions={defaultActions}
          backgroundColor="rgba(239,239,239,20)"
          node={this.props.node}
          top={10}
          left={10}
        />

    </S.Circle>
  );
	}
}

