import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as _ from 'lodash';
import { DiagramEngine } from '../..';
import { AppLayerModel } from './AppLayerModel';

export interface AppLayerWidgetProps {
	layer: AppLayerModel;
	engine: DiagramEngine;
}

export interface AppLayerWidgetState {
	open: boolean;
}

namespace S {
	const open = css`
	transform: scale(1.1);
	transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
`

	export const Container = styled.button<{open?: boolean}>`
	${(p) => p.open && open};
	z-index: 1000;
	cursor: pointer;
	background-color: #F44336;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #F44336;
  border: none;
  outline: none;
  color: #FFF;
  font-size: 36px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: .3s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	position: absolute;
	bottom: 48px;
	right: 48px;
	`;

}

/*
.kc_fab_main_btn:focus {
  transform: scale(1.1);
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
*/

export class AppLayerWidget extends React.Component<AppLayerWidgetProps, AppLayerWidgetState> {
	constructor(props: AppLayerWidgetProps) {
		super(props)
		this.state = {
			open: false,
		};

	}
	toggleOpen() {
		console.log('open')
		this.setState((prev) => ({ ...prev, open: !prev.open}))
	}

	render() {

		return (
			<div />
		)
	}
}
