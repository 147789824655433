import { BaseModelOptions } from '../../canvas-core';
import { BaseNodeModel } from '../BaseNode/BaseNodeModel'

export const TYPE = 'waiting-for-user-node';

export interface WaitingForUserNodeModelGenerics {
}
export interface WaitingForUserNodeModelOptions extends BaseModelOptions {
	color?: string;
	label?: string;
	width?: number;
	height?: number;
}

export class WaitingForUserNodeModel extends BaseNodeModel {
	color: string;
	label: string;

	constructor(options: WaitingForUserNodeModelOptions) {
		super({
			type: TYPE,
			width: 200,
			height: 60,
		});
		this.label = options.label;
	}
}
