
import { DiagramModel, DiagramEngine } from '../diagrams-engine';
import { DefaultState } from '../state/Default/DefaultState';
import { EventNodeModel as Event } from '../models/Event/EventNodeModel';
import { GRID_SIZE } from '../diagrams-core/models/DiagramModel'
const SaveDiagram = (engine: DiagramEngine, setMenuOption: Function, setModalOpen: Function, name: string, setName: Function) => {
  setModalOpen(true)
  setName(engine.getModel().serialize().name)
  return engine
}

export default SaveDiagram
