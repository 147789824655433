import { Action, ActionEvent, InputType } from '../core-actions/Action';
import { KeyboardEvent } from 'react';
import * as _ from 'lodash';
import { LaneNodeModel } from '../../diagrams-core/entities/lane/LaneNodeModel';
import { DiagramModel } from '../../diagrams-core';
import { BaseNodeModel } from '../../models/BaseNode/BaseNodeModel';
import { Console } from 'console';

export interface DeleteItemsActionOptions {
	keyCodes?: string[];
	modifiers?: {
		ctrlKey?: boolean;
		shiftKey?: boolean;
		altKey?: boolean;
		metaKey?: boolean;
	};
}

/**
 * Deletes all selected items
 */
export class DeleteItemsAction extends Action {
	constructor(options: DeleteItemsActionOptions = {}) {
		const keyCodes = options.keyCodes || ["Backspace"];
		const modifiers = {
			ctrlKey: false,
			shiftKey: false,
			altKey: false,
			metaKey: false,
			...options.modifiers
		};

		super({
			type: InputType.KEY_DOWN,
			fire: (event: ActionEvent<KeyboardEvent>) => {
				const stateHandlerName = this.engine.getStateMachine().getCurrentState().getOptions().name
				const { code, ctrlKey, shiftKey, altKey, metaKey } = event.event;
				console.log('event.event', event.event)
				if (stateHandlerName === 'editing-label') {
					// editing label
				} else if (keyCodes.indexOf(code) !== -1 && _.isEqual({ ctrlKey, shiftKey, altKey, metaKey }, modifiers)) {
					_.forEach(this.engine.getModel().getSelectedEntities(), (model) => {
						// only delete items which are not locked
						if (!model.isLocked()) {
							if (model && ((model as LaneNodeModel).containedIds)) {
								const container = model as LaneNodeModel
								const diagramModel = (this.engine.getModel() as DiagramModel)
								container.containedIds.forEach((id: string) => {
									(diagramModel.getNode(id) as BaseNodeModel).setContainer(null)
								})
							}
							model.remove();
						}
					});
				}
				this.engine.repaintCanvas();
			}
		});
	}
}
