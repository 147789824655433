import * as React from 'react';
import { GatewayNodeModel, TYPE } from './GatewayNodeModel';
import { Gateway } from './GatewayNodeWidget';
import { AbstractReactFactory } from '../../canvas-core';
import { DiagramEngine } from '../../diagrams-core';


export class GatewayNodeFactory extends AbstractReactFactory<GatewayNodeModel, DiagramEngine> {
	constructor() {
		super(TYPE);
	}

	generateModel(initialConfig) {
		return new GatewayNodeModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <Gateway engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
