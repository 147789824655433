import * as React from 'react';
import { FunctionTaskNodeModel, TYPE } from './FunctionTaskNodeModel';
import { Task } from './FunctionTaskWidget';
import { AbstractReactFactory } from '../../canvas-core';
import { DiagramEngine } from '../../diagrams-core';

export class FunctionTaskNodeFactory extends AbstractReactFactory<FunctionTaskNodeModel, DiagramEngine> {
	constructor() {
		super(TYPE);
	}

	generateModel(initialConfig) {
		return new FunctionTaskNodeModel(initialConfig);
	}

	generateReactWidget(event): JSX.Element {
		return <Task engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
